@charset "UTF-8";
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
body {
  background-color: #f3f3f3;
  font-size: 0.875em;
  overflow-x: hidden;
  color: #353c4e;
  font-family: "Open Sans", sans-serif;
}

*:focus {
  outline: none;
}
* a {
  font-size: 14px;
}
* a:focus, * a:hover {
  text-decoration: none;
}

p {
  font-size: 13px;
}

ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.media-left {
  padding-right: 20px;
}

@media only screen and (min-width: 1400px) {
  p {
    font-size: 14px;
  }
}
.main-body .page-wrapper {
  padding: 1.8rem;
  transition: all ease-in 0.3s;
}
.main-body .page-wrapper .menu-rtl .page-header-title i {
  margin-left: 20px;
}
.main-body .page-wrapper .menu-rtl .breadcrumb-title .breadcrumb-item + .breadcrumb-item:after {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #868e96;
  content: "/";
}
.main-body .page-wrapper .menu-rtl .breadcrumb-title .breadcrumb-item + .breadcrumb-item::before {
  content: none;
}
.main-body .page-wrapper .page-header {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 30px;
}
.main-body .page-wrapper .page-header-title {
  display: inline-flex;
}
.main-body .page-wrapper .page-header-title h4 {
  display: block;
  margin-bottom: 0;
  font-weight: 600;
  color: #303548;
  font-size: 20px;
  text-transform: capitalize;
}
.main-body .page-wrapper .page-header-title span {
  font-size: 13px;
  color: #919aa3;
  display: inline-block;
  margin-top: 10px;
  text-transform: capitalize;
}
.main-body .page-wrapper .page-header-title i {
  display: inline-flex;
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  justify-content: center;
  vertical-align: middle;
  font-size: 25px;
  color: #fff;
  box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.5);
  margin-right: 20px;
  align-items: center;
}
.main-body .page-wrapper .page-header-breadcrumb {
  display: inline-flex;
  float: right;
}

.author-details {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  padding: 10px 0;
  margin-bottom: 10px;
}
.author-details img ~ .dot1, .author-details img ~ .dot2 {
  height: 14px;
  width: 14px;
  border-radius: 100%;
  top: 60%;
  position: absolute;
  padding: 5px;
}
.author-details img ~ .dot1 {
  left: 40px;
}
.author-details img ~ .dot2 {
  left: 50px;
}
.author-details .recent-contain h6 {
  font-size: 1rem;
  margin-bottom: 5px;
}
.author-details .header-right {
  text-align: right;
  margin-top: 6px;
}
.author-details .header-right ul {
  position: relative;
}
.author-details .header-right h4 {
  color: #919aa3;
  font-size: 0.875rem;
  margin-top: 5px;
}
.author-details .header-right span {
  font-size: 1rem;
}
.author-details .header-right li {
  display: inline-block;
  margin-right: 60px;
  text-align: left;
}
.author-details .header-right li:last-child {
  margin-right: 0;
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 20px;
}

.progress-bar {
  height: 100%;
}

.filter-bar .navbar .navbar-nav .dropdown-menu {
  position: absolute;
}

.wrapper {
  padding: 0;
}

.card-block {
  padding: 1.25rem;
}

.card {
  border-radius: 5px;
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.12);
  border: none;
  margin-bottom: 30px;
}
.card .card-footer {
  background-color: #fff;
  border-top: none;
}
.card .card-header {
  background-color: transparent;
  border-bottom: none;
  padding: 25px 20px;
}
.card .card-header .card-header-left {
  display: inline-block;
}
.card .card-header .card-header-right {
  border-radius: 0 0 0 7px;
  right: 10px;
  top: 18px;
  display: inline-block;
  float: right;
  padding: 7px 0;
  position: absolute;
}
.card .card-header .card-header-right i {
  margin: 0 8px;
  cursor: pointer;
  font-size: 14px;
  color: #505458;
  line-height: 20px;
}
.card .card-header .card-header-right i.icofont.icofont-spinner-alt-5 {
  display: none;
}
.card .card-header .card-header-right .card-option {
  height: 20px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
.card .card-header .card-header-right .card-option li {
  display: inline-block;
}
.card .card-header span {
  color: #919aa3;
  display: block;
  font-size: 13px;
  margin-top: 5px;
}
.card .card-header + .card-block, .card .card-header + .card-block-big {
  padding-top: 0;
}
.card h5 {
  margin-bottom: 0;
  color: #505458;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.4;
}
.card .card-block table tr {
  padding-bottom: 20px;
}
.card .card-block .sub-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}
.card .card-block code {
  background-color: #eee;
  margin: 5px;
  display: inline-block;
}
.card .card-block .dropdown-menu {
  top: 38px;
}
.card .card-block p {
  line-height: 25px;
}
.card .card-block a.dropdown-item {
  margin-bottom: 0;
  font-size: 14px;
  transition: 0.25s;
}
.card .card-block a.dropdown-item:active, .card .card-block a.dropdown-item .active {
  background-color: #4680ff;
}
.card .card-block.remove-label i {
  margin: 0;
  padding: 0;
}
.card .card-block.button-list span.badge {
  margin-left: 5px;
}
.card .card-block .dropdown-menu {
  background-color: #fff;
  padding: 0;
}
.card .card-block .dropdown-menu .dropdown-divider {
  background-color: #ddd;
  margin: 3px 0;
}
.card .card-block .dropdown-menu > a {
  padding: 10px 16px;
  line-height: 1.429;
}
.card .card-block .dropdown-menu > li > a:focus, .card .card-block .dropdown-menu > li > a:hover {
  background-color: rgba(202, 206, 209, 0.5);
}
.card .card-block .dropdown-menu > li:first-child > a:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.card .card-block .badge-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 12px 0;
}
.card .card-block-big {
  padding: 30px 35px;
}
.card .card-block-small {
  padding: 10px 20px;
}
.card .inner-card-block {
  border: 1px solid #ddd;
}

.pcoded .card.full-card {
  position: fixed;
  top: 80px;
  z-index: 99999;
  box-shadow: none;
  right: 0;
  border-radius: 0;
  border: 1px solid #ddd;
  width: calc(100vw - 287px);
  height: calc(100vh - 80px);
}
.pcoded .card.full-card.card-load {
  position: fixed;
}
.pcoded .card.card-load {
  position: relative;
  overflow: hidden;
}
.pcoded .card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
}
.pcoded .card.card-load .card-loader i {
  margin: 0 auto;
  color: #ab7967;
  font-size: 20px;
}
.pcoded[vertical-nav-type=expanded] .card.full-card {
  width: calc(100vw - 287px);
}
.pcoded[vertical-nav-type=collapsed] .card.full-card {
  width: calc(100vw - 97px);
}
.pcoded[vertical-nav-type=offcanvas] .card.full-card {
  width: 100vw;
}

.card-header-text {
  margin-bottom: 0;
  font-size: 1rem;
  color: rgba(51, 51, 51, 0.85);
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
}

.icofont-rounded-down {
  -webkit-transition: all ease-in 0.3s;
  display: inline-block;
  transition: all ease-in 0.3s;
}

.icon-up {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block;
}

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.breadcrumb-title a {
  font-size: 14px;
  color: #4a6076;
}
.breadcrumb-title li:last-child a {
  color: #7e7e7e;
}

img {
  border-radius: 5px;
}

.sub-title {
  border-bottom: 1px solid rgba(204, 204, 204, 0.35);
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: #2c3e50;
}

.blockquote {
  border-left: 0.25rem solid #eceeef;
  padding: 0.5rem 1rem;
}
.blockquote.blockquote-reverse {
  text-align: right;
  padding-right: 1rem;
  border-right: 0.25rem solid #eceeef;
  border-left: none;
}

.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
  margin: 0 0 20px;
}
.typography small {
  margin-left: 10px;
  font-weight: 600;
  color: #777;
}

.card-block.list-tag ul li {
  display: block;
  float: none;
  margin-bottom: 5px;
}
.card-block.list-tag ol li {
  margin-bottom: 5px;
}

.inline-order-list {
  margin-top: 50px;
}
.inline-order-list h4, .inline-order-list p {
  margin-bottom: 0;
}

.card-block ul li.list-inline-item {
  display: inline-block;
  float: left;
}

.modal {
  z-index: 99999999999;
}

.bd-example .modal {
  display: block;
  position: inherit;
  background-color: #2C3E50;
  margin-bottom: 20px;
}

.card .overflow-container h5 {
  margin-bottom: 5px;
}

.button-page .card-block a.nav-link {
  margin-bottom: 0;
}

.sweet-alert button.confirm {
  background-color: #4680ff !important;
}
.sweet-alert .sa-input-error {
  top: 23px;
}

.location-selector {
  width: 100%;
  height: 250px;
  background-color: #fff;
  border: 2px dashed #e5e9ec;
  position: relative;
  margin-bottom: 20px;
}
.location-selector .bit {
  background-color: #e5e9ec;
  cursor: pointer;
  position: absolute;
}
.location-selector .bit:hover {
  background-color: #ddd;
}
.location-selector .bit.bottom {
  height: 25%;
  width: 40%;
  margin: 0 30%;
}
.location-selector .bit.top {
  height: 25%;
  width: 40%;
  margin: 0 30%;
  top: 0;
}
.location-selector .bit.bottom {
  bottom: 0;
}
.location-selector .bit.left {
  height: 20%;
  width: 20%;
  margin-left: 0;
  margin-right: 0;
}
.location-selector .bit.right {
  height: 20%;
  width: 20%;
  margin-left: 0;
  margin-right: 0;
  right: 0;
}
.location-selector .bit.left {
  left: 0;
}

button.close {
  margin-top: 7px;
  margin-bottom: 0;
}

.mytooltip {
  display: inline;
  position: relative;
  z-index: 9999;
}
.mytooltip .tooltip-item {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  padding: 0 10px;
}
.mytooltip .tooltip-content {
  position: absolute;
  z-index: 9999;
  width: 360px;
  left: 50%;
  margin: 0 0 20px -180px;
  bottom: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 30px;
  box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
  background: #2b2b2b;
  opacity: 0;
  cursor: default;
  pointer-events: none;
}
.mytooltip .tooltip-content::after {
  content: "";
  top: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: #2a3035 transparent transparent;
  border-width: 10px;
  margin-left: -10px;
}
.mytooltip .tooltip-content img {
  position: relative;
  height: 140px;
  display: block;
  float: left;
  margin-right: 1em;
}
.mytooltip .tooltip-item::after {
  content: "";
  position: absolute;
  width: 360px;
  height: 20px;
  bottom: 100%;
  left: 50%;
  pointer-events: none;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.mytooltip:hover .tooltip-item::after {
  pointer-events: auto;
}
.mytooltip:hover .tooltip-content {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
}
.mytooltip:hover .tooltip-content2 {
  opacity: 1;
  font-size: 18px;
  opacity: 1;
  font-size: 18px;
  pointer-events: auto;
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}
.mytooltip:hover .tooltip-content2 i {
  opacity: 1;
  font-size: 18px;
  opacity: 1;
  font-size: 18px;
  pointer-events: auto;
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}
.mytooltip:hover .tooltip-item2 {
  color: #fff;
  -webkit-transform: translate3d(0, -0.9em, 0);
  transform: translate3d(0, -0.9em, 0);
}
.mytooltip:hover .tooltip-text3 {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
.mytooltip:hover .tooltip-content3 {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
.mytooltip:hover .tooltip-content4, .mytooltip:hover .tooltip-text2 {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.mytooltip:hover .tooltip-inner2 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.mytooltip:hover .tooltip-content5 {
  opacity: 1;
  pointer-events: auto;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.mytooltip .tooltip-text {
  font-size: 14px;
  line-height: 24px;
  display: block;
  padding: 1.31em 1.21em 1.21em 0;
  color: #fff;
}
.mytooltip .tooltip-item2 {
  color: #4680ff;
  cursor: pointer;
  z-index: 100;
  position: relative;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  -webkit-transition: background-color 0.3s, color 0.3s, -webkit-transform 0.3s;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.tooltip.tooltip-effect-2:hover .tooltip-content {
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
  transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
}

.tooltip-effect-5 .tooltip-text {
  padding: 1.4em;
}

.tooltip-effect-1 .tooltip-content {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  color: #fff;
}

.tooltip-effect-2 .tooltip-content {
  -webkit-transform-origin: 50% calc(110%);
  transform-origin: 50% calc(110%);
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
  transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-3 .tooltip-content {
  -webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-4 .tooltip-content {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: scale3d(0.7, 0.3, 1);
  transform: scale3d(0.7, 0.3, 1);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
}

.tooltip-effect-5 .tooltip-content {
  width: 180px;
  margin-left: -90px;
  -webkit-transform-origin: 50% calc(106%);
  transform-origin: 50% calc(106%);
  -webkit-transform: rotate3d(0, 0, 1, 15deg);
  transform: rotate3d(0, 0, 1, 15deg);
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
  -webkit-transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
  transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
}

.tooltip-effect-6 .tooltip-content2 {
  -webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
  transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-6 .tooltip-content2 i {
  -webkit-transform: scale3d(0, 0, 1);
  transform: scale3d(0, 0, 1);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-7 .tooltip-content2 {
  -webkit-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-7 .tooltip-content2 i {
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-8 .tooltip-content2 {
  -webkit-transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
  transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-8 .tooltip-content2 i {
  -webkit-transform: scale3d(0, 0, 1);
  transform: scale3d(0, 0, 1);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-9 .tooltip-content2 {
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-effect-9 .tooltip-content2 i {
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-effect-6:hover .tooltip-content2 i {
  -webkit-transform: rotate3d(1, 1, 1, 0);
  transform: rotate3d(1, 1, 1, 0);
}

.tooltip-content2 {
  position: absolute;
  z-index: 9999;
  width: 80px;
  height: 80px;
  padding-top: 25px;
  left: 50%;
  margin-left: -40px;
  bottom: 100%;
  border-radius: 50%;
  text-align: center;
  background: #4680ff;
  color: #fff;
  opacity: 0;
  margin-bottom: 20px;
  cursor: default;
  pointer-events: none;
}
.tooltip-content2 i {
  opacity: 0;
}
.tooltip-content2::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin: -7px 0 0 -15px;
  width: 30px;
  height: 20px;
  background: url("assets/images/tooltip/tooltip1.svg") center center no-repeat;
  background-size: 100%;
}

.tooltip-content3 {
  position: absolute;
  background: url("assets/images/tooltip/shape1.svg") center bottom no-repeat;
  background-size: 100% 100%;
  z-index: 9999;
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  padding: 50px 30px;
  text-align: center;
  color: #fff;
  opacity: 0;
  cursor: default;
  font-size: 14px;
  line-height: 27px;
  pointer-events: none;
  -webkit-transform: scale3d(0.1, 0.2, 1);
  transform: scale3d(0.1, 0.2, 1);
  -webkit-transform-origin: 50% 120%;
  transform-origin: 50% 120%;
  -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
  transition: opacity 0.4s, transform 0.4s;
  -webkit-transition-timing-function: ease, cubic-bezier(0.6, 0, 0.4, 1);
  transition-timing-function: ease, cubic-bezier(0.6, 0, 0.4, 1);
}
.tooltip-content3::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  left: 50%;
  margin-left: -8px;
  top: 100%;
  background: #00AEEF;
  -webkit-transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg);
  transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg);
}

.tooltip-content4 {
  position: absolute;
  z-index: 99;
  width: 360px;
  left: 50%;
  margin-left: -180px;
  bottom: -5px;
  text-align: left;
  background: #4680ff;
  opacity: 0;
  font-size: 14px;
  line-height: 27px;
  padding: 1.5em;
  color: #fff;
  border-bottom: 55px solid #303548;
  cursor: default;
  pointer-events: none;
  border-radius: 5px;
  -webkit-transform: translate3d(0, -0.5em, 0);
  transform: translate3d(0, -0.5em, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}
.tooltip-content4 a {
  color: #2b2b2b;
}
.tooltip-content4 .tooltip-text2 {
  opacity: 0;
  -webkit-transform: translate3d(0, 1.5em, 0);
  transform: translate3d(0, 1.5em, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.tooltip-content5 {
  position: absolute;
  z-index: 9999;
  width: 300px;
  left: 50%;
  bottom: 100%;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  font-weight: 400;
  color: #fff;
  background: 0 0;
  opacity: 0;
  margin: 0 0 20px -150px;
  cursor: default;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: opacity 0.3s 0.3s;
  transition: opacity 0.3s 0.3s;
}
.tooltip-content5 span {
  display: block;
}
.tooltip-content5::after {
  content: "";
  bottom: -20px;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: #4680ff transparent transparent;
  border-width: 10px;
  margin-left: -10px;
}
.tooltip-content5 .tooltip-text3 {
  border-bottom: 10px solid #4680ff;
  overflow: hidden;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
}
.tooltip-content5 .tooltip-inner2 {
  background: #2b2b2b;
  padding: 40px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

a.mytooltip {
  font-weight: 700;
  color: #4680ff;
  z-index: 9;
}

.tooltip-link a {
  margin-left: 10px;
  color: #4680ff;
}

.tooltip-icon button i {
  margin-right: 0;
}

/**====== Tooltip css end ======**/
/**  =====================
      Slider css start
==========================  **/
/*========= Owl-carousel css start ============*/
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #4680ff;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #4680ff;
}

/*========= Owl-carousel css end ============*/
/**  =====================
      Animation css start
==========================  **/
.animation-image img {
  margin: 10px auto 20px;
  cursor: pointer;
}

/**====== Animation css end ======**/
/**  =====================
      Tree-view css start
==========================  **/
.card-block.tree-view ul {
  display: inherit;
}
.card-block.tree-view ul li {
  display: inherit;
  float: none;
}

/**====== Tree-view css end ======**/
/**  =====================
      File Upload css start
==========================  **/
.jFiler-theme-default .jFiler-input-button {
  background-color: #4680ff;
  background-image: none;
  color: #fff;
}
.jFiler-theme-default .jFiler-input {
  width: 100%;
}

.jFiler-input-dragDrop {
  width: 100%;
  background-color: #fafafa;
}

.jFiler-items-grid .jFiler-item .jFiler-item-container .jFiler-item-info {
  position: absolute;
  bottom: -10%;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 6px 10px;
  z-index: 9;
  opacity: 0;
  -webkit-transition: all 0.12s;
  -moz-transition: all 0.12s;
  transition: all 0.12s;
}

.jFiler-input-choose-btn {
  border: 1px solid #4680ff;
  padding: 10px 25px;
  background: #4680ff;
  color: #fff !important;
}
.jFiler-input-choose-btn:hover {
  background: #fff;
  border: 1px solid #4680ff;
  color: #fff !important;
}

.card .jFiler-item-assets ul li {
  margin-right: 0;
}

/**====== File Upload css end ======**/
/**  =====================
      Cropper css start
==========================  **/
.img-container, .img-preview {
  background-color: #f7f7f7;
  width: 100%;
  text-align: center;
}

.img-container {
  min-height: 200px;
  max-height: 516px;
  margin-bottom: 20px;
}
.img-container > img {
  max-width: 100%;
}

@media (min-width: 768px) {
  .img-container {
    min-height: 516px;
  }
}
.docs-preview {
  margin-right: -15px;
}

.img-preview {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}
.img-preview > img {
  max-width: 100%;
}

.preview-lg {
  width: 100% !important;
}

.preview-md {
  width: 139px;
  height: 78px;
}

.preview-sm {
  width: 69px;
  height: 39px;
}

.preview-xs {
  width: 35px;
  height: 20px;
  margin-right: 0;
}

.docs-data > .input-group {
  margin-bottom: 10px;
}
.docs-data > .input-group > label {
  min-width: 80px;
}
.docs-data > .input-group > span {
  min-width: 50px;
}

.docs-buttons > .btn, .docs-buttons > .form-control, .docs-buttons > .btn-group {
  margin-right: 5px;
}

.docs-toggles > .btn, .docs-toggles > .dropdown, .docs-toggles > .btn-group {
  margin-bottom: 10px;
}

.docs-tooltip {
  display: block;
  margin: -6px -12px;
  padding: 6px 12px;
}
.docs-tooltip > .icon {
  margin: 0 -3px;
  vertical-align: top;
}

.tooltip-inner {
  white-space: normal;
}

.btn-upload .tooltip-inner {
  white-space: nowrap;
}

@media (max-width: 400px) {
  .btn-group-crop {
    margin-right: -15px !important;
  }
  .btn-group-crop > .btn {
    padding-left: 5px;
    padding-right: 5px;
  }
  .btn-group-crop .docs-tooltip {
    margin-left: -5px;
    margin-right: -5px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.docs-options .dropdown-menu {
  width: 100%;
}
.docs-options .dropdown-menu > li {
  padding: 3px 20px;
  font-size: 14px;
}
.docs-options .dropdown-menu > li:hover {
  background-color: #f7f7f7;
}
.docs-options .dropdown-menu > li > label {
  display: block;
}

.docs-cropped .modal-body {
  text-align: center;
}
.docs-cropped .modal-body > img, .docs-cropped .modal-body > canvas {
  max-width: 100%;
}

.card-block .docs-options .dropdown-menu {
  top: inherit;
}

label.btn-upload {
  height: 40px;
}

/**====== Cropper css end ======**/
/**  =====================
      Accordion css start
==========================  **/
.accordion-msg {
  display: block;
  color: #222222;
  padding: 14px 20px;
  border-top: 1px solid #ddd;
  font-weight: 600;
  cursor: pointer;
}
.accordion-msg:focus, .accordion-msg:hover {
  text-decoration: none;
  outline: none;
}

.faq-accordion .accordion-desc {
  padding: 20px;
}

.accordion-desc {
  color: #222222;
  padding: 0 20px 20px;
}

#color-accordion .accordion-desc {
  margin-top: 14px;
}

.ui-accordion-header-icon {
  float: right;
  font-size: 20px;
}

.accordion-title {
  margin-bottom: 0;
}

.accordion-block {
  padding: 0;
}
.accordion-block p {
  margin-bottom: 0;
}

.color-accordion-block a:focus, .color-accordion-block a:hover, .color-accordion-block a.ui-state-active {
  color: #fff;
  background: #4680ff;
}

a.bg-default:focus, a.bg-default:hover {
  background-color: #fafafa !important;
  color: #fff;
}
a.bg-primary:focus, a.bg-primary:hover {
  background-color: #79a3ff !important;
  color: #fff;
}
a.bg-success:focus, a.bg-success:hover {
  background-color: #aacc77 !important;
  color: #fff;
}
a.bg-info:focus, a.bg-info:hover {
  background-color: #91dff7 !important;
  color: #fff;
}
a.bg-warning:focus, a.bg-warning:hover {
  background-color: #ffcb80 !important;
  color: #fff;
}
a.bg-danger:focus, a.bg-danger:hover {
  background-color: #fd93a8 !important;
  color: #fff;
}

/**====== Accordion css end ======**/
/**  =====================
      Tabs css start
==========================  **/
.tab-list p {
  padding: 10px;
}

.tab-with-img i {
  position: absolute;
  padding: 5px;
}

.tab-icon {
  margin-bottom: 30px;
}
.tab-icon i {
  padding-right: 10px;
}

.tab-below {
  border-top: 1px solid #ddd;
  border-bottom: none;
}
.tab-below.nav-tabs .nav-link.active {
  border-color: transparent #ddd #ddd #ddd;
}
.tab-below .nav-item {
  margin-top: -2px;
}
.tab-below.nav-tabs .nav-link {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-header ~ .tab-icon .tab-with-img .sub-title i {
  right: 10px;
}

.tab-with-img .nav-link {
  position: relative;
}

.tabs-left, .tabs-right {
  min-width: 120px;
  vertical-align: top;
  width: 150px;
}

.tabs-left, .tabs-left-content, .tabs-right, .tabs-right-content {
  display: table-cell;
}

.nav-tabs.tabs-left .slide {
  height: 35px;
  width: 4px;
  bottom: 15px;
}
.nav-tabs.tabs-right .slide {
  height: 35px;
  width: 4px;
  bottom: 15px;
  right: 0;
}

.product-edit .md-tabs .nav-item a {
  padding: 0 0 20px !important;
}

.md-tabs.tabs-left .nav-item, .md-tabs.tabs-right .nav-item {
  width: 100%;
  position: relative;
}

.tabs-left .nav-item, .tabs-right .nav-item {
  width: 100%;
  position: relative;
}

.md-tabs {
  position: relative;
}
.md-tabs .nav-item + .nav-item {
  margin: 0;
}
.md-tabs .nav-link {
  border: none;
  color: #1b8bf9;
}
.md-tabs .nav-item {
  background-color: #fff;
  width: calc(100% / 4);
  text-align: center;
}
.md-tabs .nav-item .nav-link.active ~ .slide {
  opacity: 1;
  transition: all 0.3s ease-out;
}
.md-tabs .nav-item .nav-link ~ .slide {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.md-tabs .nav-item.open .nav-link {
  color: #4680ff;
  border: none;
  background-color: transparent;
  border-radius: 0;
}
.md-tabs .nav-item.open .nav-link:focus, .md-tabs .nav-item.open .nav-link:hover {
  color: #4680ff;
  border: none;
  background-color: transparent;
  border-radius: 0;
}
.md-tabs .nav-link.active {
  color: #4680ff;
  border: none;
  background-color: transparent;
  border-radius: 0;
}
.md-tabs .nav-link.active:focus, .md-tabs .nav-link.active:hover {
  color: #4680ff;
  border: none;
  background-color: transparent;
  border-radius: 0;
}
.md-tabs .nav-item:first-child {
  border-bottom: 1px solid #ddd;
}
.md-tabs .nav-item a {
  padding: 20px 0 !important;
  color: #4680ff;
}

/*.md-tabs .nav-link:focus,*/
/*.md-tabs .nav-link:hover {*/
/*border: none;*/
/*}*/
.nav-tabs .slide {
  background: #4680ff;
  width: calc(100% / 4);
  height: 4px;
  position: absolute;
  -webkit-transition: left 0.3s ease-out;
  transition: left 0.3s ease-out;
  bottom: 0;
}
.nav-tabs .slide .nav-item.show .nav-link, .nav-tabs .slide .nav-link {
  color: #4680ff;
}

.img-tabs img {
  width: 100px;
  margin: 0 auto;
}
.img-tabs a {
  opacity: 0.5;
  transition: all ease-in-out 0.3s;
}
.img-tabs a span i {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  bottom: 10px;
  right: 70px;
}
.img-tabs a img {
  border: 3px solid;
}
.img-tabs a.active {
  opacity: 1;
  transition: all ease-in-out 0.3s;
}
.img-tabs .nav-item:first-child {
  border-bottom: none;
}

#pc-left-panel-menu {
  margin-bottom: 20px;
}

.h-active a {
  color: #1b8bf9 !important;
  font-weight: 600;
}

.img-circle {
  border-radius: 50%;
}

.b-none {
  border: none !important;
}

/**====== Tabs css end ======**/
/**  =====================
      Table css start
==========================  **/
.table-primary {
  background-color: #4680FE;
}
.table-primary > td, .table-primary > th {
  background-color: #4680FE;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table.table-xl td, .table.table-xl th {
  padding: 1.25rem 2rem;
}
.table.table-lg td, .table.table-lg th {
  padding: 0.9rem 2rem;
}
.table.table-de td, .table.table-de th {
  padding: 0.75rem 2rem;
}
.table.table-sm td, .table.table-sm th {
  padding: 0.6rem 2rem;
}
.table.table-xs td, .table.table-xs th {
  padding: 0.4rem 2rem;
}

.table-columned > tbody > tr > td:first-child, .table-columned > tbody > tr > th:first-child {
  border-left: 0;
}
.table-columned > tfoot > tr > td:first-child, .table-columned > tfoot > tr > th:first-child {
  border-left: 0;
}
.table-columned > tbody > tr > td, .table-columned > tbody > tr > th {
  border: 0;
  border-left: 1px solid #ddd;
}
.table-columned > tfoot > tr > td, .table-columned > tfoot > tr > th {
  border: 0;
  border-left: 1px solid #ddd;
}

.table-border-style {
  padding: 0;
}
.table-border-style .table {
  margin-bottom: 0;
}

.table > thead > tr > th {
  border-bottom-color: #ccc;
}

.table-borderless tbody tr td, .table-borderless tbody tr th {
  border: 0;
}

.table-bordered > thead > tr.border-solid > td, .table-bordered > thead > tr.border-solid > th {
  border-bottom-width: 2px;
}
.table-bordered > thead > tr.border-solid:first-child > td, .table-bordered > thead > tr.border-solid:first-child th {
  border-bottom-width: 2px;
}
.table-bordered > thead > tr.border-double > td, .table-bordered > thead > tr.border-double > th {
  border-bottom-width: 3px;
  border-bottom-style: double;
}
.table-bordered > thead > tr.border-double:first-child > td, .table-bordered > thead > tr.border-double:first-child th {
  border-bottom-width: 3px;
  border-bottom-style: double;
}

/**====== Table css end ======**/
/**  =====================
      User-Profile css start
==========================  **/
.rounded-card img {
  margin: 0 auto;
  display: block;
  width: 100%;
}

.user-img img {
  margin: 0 auto;
  display: block;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.user-about ul li {
  border-top: 1px solid rgba(204, 204, 204, 0.28);
  padding: 10px 0 10px 10px;
  text-align: left;
}
.user-about ul li a {
  font-size: 16px;
  color: #666;
}
.user-about ul li.active {
  font-weight: 600;
  background: #1b8bf9;
}
.user-about ul li.active a {
  color: #fff;
}

.card-title, h5 {
  margin: 0;
}

.card-header.followers a {
  font-weight: 500;
  color: rgba(51, 51, 51, 0.85);
}

.user-profile {
  padding: 20px 0;
}

.follow-btn button:first-child, .user-about i {
  margin-right: 10px;
}

.btn-inline i {
  color: #fff;
}
.btn-inline .fb-btn {
  background-color: #3b5998;
}
.btn-inline .twitter-btn {
  background-color: #55acee;
}
.btn-inline .pinterest-btn {
  background-color: #cb2027;
}
.btn-inline .linkedin-btn {
  background-color: #007bb5;
}
.btn-inline .dribbble-btn {
  background-color: #ea4c89;
}

.user-post {
  font-style: italic;
}

.connection-list img {
  display: inline-block;
}

.contact-user h4, .contact-user img {
  display: inline-block;
}

.earn-heading {
  display: inline-block;
}

.list-inline {
  display: inline-block;
}
.list-inline li {
  display: inline-block;
}

.service-header {
  display: inline-block;
}

.connection-list img {
  width: 55px;
  height: 55px;
  margin: 5px;
}

.border-post {
  border: 1px solid #ccc;
}

.earn-sub-header {
  font-size: 15px;
  color: #ccc;
}

.btn-inline {
  margin-top: 20px;
}

.order-summary .progress {
  margin-bottom: 32px;
}

.services .service-btn::after {
  top: 20px;
  right: 15px;
}

.services-list {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
  top: 46px;
}

.contact-menu i, .fa-cog, .services-list i {
  margin-right: 10px;
}

.contact-details table .fa-star, .contact-details table .fa-star-o {
  color: #4680ff;
}

.review-star i {
  color: #4680ff;
}
.review-star i:last-child {
  color: #ccc;
}

.card-body.user-info {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.profile-bg-img {
  width: 100%;
}

.user-title {
  position: relative;
  bottom: 20px;
}
.user-title h2 {
  color: #fff;
  text-shadow: 1px 1px 4px #373a3c;
  font-size: 20px;
}

.profile-image img {
  border: 4px solid #fff;
}

.user-info .media-body, .user-info .media-left {
  display: table-cell;
  vertical-align: middle;
}

.cover-btn {
  bottom: 38px;
  right: 35px;
  position: absolute;
}

.cover-profile .profile-bg-img {
  margin-bottom: 25px;
}

.contact-user h4 {
  font-size: 20px;
  padding-left: 10px;
}

.groups-contact span {
  float: right;
}
.groups-contact span h4 {
  font-size: 18px;
  margin-bottom: 20px;
}

.contact-menu {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.11);
  top: 15%;
  right: 10%;
}
.contact-menu .dropdown-item {
  padding: 8px 20px;
}

.tab-header {
  margin-bottom: 20px;
}

/*====== User-Profile End ======*/
/**  =====================
      User-card css start
==========================  **/
.card.user-card {
  border-top: none;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
  transition: all 150ms linear;
}
.card.user-card:hover {
  box-shadow: 0 0 25px -5px #9e9c9e;
}

.card-header-img ~ .btn-group i {
  margin-right: 0;
}

.card.business-info {
  border-top: none;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
  transition: all 150ms linear;
}
.card.business-info:hover {
  box-shadow: 0 0 25px -5px #9e9c9e;
}

.top-cap-text {
  padding: 20px;
}
.top-cap-text p {
  padding: 10px 0;
}

.user-content {
  text-align: center;
  margin-top: 20px;
}
.user-content h4 {
  font-size: 16px;
  font-weight: 600;
}
.user-content h5 {
  font-size: 14px;
}

.img-overlay {
  bottom: 0;
  color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  transform: scale(0);
  margin: 0 auto;
}
.img-overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.img-overlay i {
  margin-right: 0;
}

.img-hover-main {
  padding: 0 40px;
}

.img-hover {
  position: relative;
  margin: 0 auto;
}

.img-overlay span .btn i {
  margin-right: 0;
}

.simple-cards .user-card .label-icon {
  margin-top: 15px;
}

.img-hover:hover .img-overlay {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: scale(1);
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.card-header-img img {
  margin: 0 auto;
  display: block;
}
.card-header-img h4 {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.card-header-img h5, .card-header-img h6 {
  margin-top: 15px;
  font-size: 15px;
  color: #222;
  font-weight: 500;
  text-align: center;
}

.simple-cards .btn-group {
  margin: 20px auto 0;
}
.simple-cards .btn-group button {
  margin: 0;
}
.simple-cards .user-card {
  padding: 20px 0;
  text-align: center;
}
.simple-cards .user-card .label-icon .badge-top-right {
  margin-left: 3px;
  top: -8px;
}

.card-icon {
  display: block;
  margin-bottom: 5px;
}

.btn-outline-primary {
  border-color: #4680ff;
  font-weight: 500;
  padding: 10px 16px;
  font-size: 15px;
}
.btn-outline-primary:hover {
  background-color: #4680ff;
  border-color: #4680ff;
}

.simple-cards p {
  margin: 20px;
  font-size: 15px;
}

.user-profile #edit-btn, .user-profile #edit-info-btn {
  margin-bottom: 0;
}

.card-block ul.list-contacts, .card-block.groups-contact ul, .card-block ul.list-contacts li, .card-block.groups-contact ul li {
  display: block;
  float: none;
}

ul.list-contacts .list-group-item a {
  color: #292b2c;
}
ul.list-contacts .list-group-item.active a {
  color: #fff;
}

.pagination li {
  display: inline-block;
}

.card-block.groups-contact {
  margin-bottom: 0;
}
.card-block .connection-list {
  margin-bottom: 20px;
}

.table button {
  margin-bottom: 0;
}

#crm-contact .img-circle, img.comment-img {
  width: 40px;
  height: 40px;
}

.page-link {
  color: #4680ff;
}

.page-item.active .page-link {
  background-color: #4680ff;
  border-color: #4680ff;
}

#main {
  margin-bottom: 20px;
}

/*====== User-card End ======*/
/**  =====================
      Offline css start
==========================  **/
.offline-box iframe {
  width: 100%;
  border: 1px solid #ddd;
}

.login.offline-404 {
  background-color: #f3f3f3;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  height: calc(100vh - 80px);
}

/*==== Offline page css end ====*/
/**  =====================
      Blog css start
==========================  **/
.blog-page {
  border-top: none;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.05), 0 -2px 1px -2px rgba(0, 0, 0, 0.04), 0 0 0 -1px rgba(0, 0, 0, 0.05);
}
.blog-page .blog-box {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.blog-page .blog-box h5 {
  border-bottom: 1px solid #ccc;
  color: #4680ff;
  margin-top: 0;
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-size: 18px;
  display: block;
}
.blog-page .blog-box .option-font {
  background-color: #4680ff;
  border-radius: 50%;
  bottom: 320px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  line-height: 35px;
  text-align: center;
  width: 35px;
}
.blog-page .blog-box .blog-detail {
  padding: 10px;
}
.blog-page .blog-img {
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  margin-bottom: -20px;
}
.blog-page .blog-img:hover {
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}
.blog-page .blog-img .sharing {
  position: relative;
  bottom: 50px;
  left: 15px;
}
.blog-page .blog-img .share {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  bottom: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  left: 50px;
  padding: 3px 5px;
  position: absolute;
}
.blog-page .blog-img .share::before {
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
  border-right: 6px solid rgba(0, 0, 0, 0.5);
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  bottom: 8px;
  content: "";
  height: 42%;
  left: -6px;
  position: absolute;
  transform: rotate(-135deg);
  width: 12px;
}
.blog-page .blog-img .share i {
  color: #fff;
  font-size: 15px;
  border-right: 1px solid #fff;
  padding: 0 5px;
}
.blog-page .blog-img .share i:last-child {
  border: none;
}
.blog-page .blog-img .share i a {
  padding: 0 5px;
  color: #fff;
}

.author {
  display: inline-block;
  float: right;
}
.author a {
  font-size: 13px;
}

.blog_post_img h5 {
  display: block;
}

.blog-date {
  font-size: 18px;
  padding-bottom: 10px;
  display: block;
}
.blog-date i {
  margin-left: 5px;
  font-size: 14px;
  color: #4680ff;
}
.blog-date .icon-calendar {
  font-size: 16px;
  margin-top: 5px;
}

.day {
  color: #bbb;
  padding-left: 10px;
  font-size: 14px;
}

.btn-blog a {
  color: #fff !important;
}

.blog-single h4 {
  margin-top: 20px;
  color: #4680ff;
  font-weight: 400;
}
.blog-single p {
  margin: 20px 0;
}
.blog-single p:last-child {
  color: #777;
}
.blog-single .qutoe-text {
  font-size: 15px;
  color: #4680ff;
  border-left: 3px solid #4680ff;
  padding-left: 25px;
  font-weight: 400;
}
.blog-single img:first-child {
  width: 100%;
}

.blog-article .articles img {
  width: 100%;
}

.blog-big-user h6, .blog-big-user p {
  color: #757575;
}

.blog-s-reply h6 {
  color: #4680ff;
}

.shares-like li:first-child, .shares-like li i {
  color: #777;
}

.blog-tag li {
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid #ccc;
  margin: 5px 0;
  margin-right: 15px;
}
.blog-tag li a, .blog-tag li i {
  color: #777;
}
.blog-tag li:first-child {
  border: none;
  padding: 5px 0;
}
.blog-tag li i {
  font-size: 20px;
  vertical-align: middle;
}

.shares-like li {
  display: inline-block;
  margin: 5px 0;
  margin-right: 15px;
  font-size: 20px;
}
.shares-like li:first-child {
  font-size: 17px;
}
.shares-like li i {
  color: #fff;
}
.shares-like .btn-facebook, .shares-like .btn-google-plus, .shares-like .btn-linkedin, .shares-like .btn-pinterest, .shares-like .btn-twitter {
  color: #fff;
  padding: 10px 15px;
  display: inline-block;
}

.btn-dribbble i, .btn-dropbox i, .btn-facebook i, .btn-flickr i, .btn-github i, .btn-google-plus i, .btn-instagram i, .btn-linkedin i, .btn-pinterest i, .btn-skype i, .btn-tumblr i, .btn-twitter i, .btn-youtube i {
  display: inline-block;
  border-radius: 3px 0 0 3px;
}

.blog-article .articles h6 {
  padding-top: 20px;
  font-weight: 400;
}
.blog-article .articles a {
  font-weight: 400;
  font-size: 15px;
  color: #4680ff;
  margin: 20px 0;
  display: block;
}

.blog-s-reply h6 span {
  font-size: 12px;
  color: #777;
  margin-left: 5px;
}

.blog-u-comment span {
  font-size: 14px;
}
.blog-u-comment .blog-edit a, .blog-u-comment .blog-reply a {
  margin-right: 10px;
  font-size: 12px;
}

.system_font_color {
  font-size: 18px;
  color: #4680ff;
}
.system_font_color a {
  color: #4680ff;
  margin-left: 5px;
}

.port_detail_next_search h5 {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #cccccc;
  display: block;
  font-weight: lighter;
}
.port_detail_next_search a {
  color: #333;
  transition: all 0.3s 0s;
}
.port_detail_next_search a i {
  float: right;
  margin-top: 6px;
}

.blog_detail_social_icon span {
  font-size: 18px;
  padding: 10px;
  border: 1px solid #999;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 10px;
  transition: all 0.5s ease;
  display: inline-block;
  margin-bottom: 10px;
}
.blog_detail_social_icon span:hover {
  background-color: #4680ff;
  color: #fff;
}

.latest_blog h5 a {
  color: #333;
  font-weight: 600;
}

/*====== Blog css ends =======*/
/**  =====================
      Gallery css start
==========================  **/
.gallery-page .card-block {
  margin-bottom: -20px;
}

.thumbnail {
  margin-bottom: 20px;
}
.thumbnail .thumb {
  position: relative;
  display: block;
}

.card.gallery-desc {
  box-shadow: 0 0 1px 2px rgba(128, 128, 128, 0.16);
}

.masonry-media {
  overflow: hidden;
  transition: all 0.5s;
}
.masonry-media img:hover {
  transform: scale(1.1);
  overflow: hidden;
  transition: all 0.5s;
}

.masonry-image .masonry-media {
  margin-bottom: 20px;
}

figure.effect-goliath {
  background: #4680ff;
}

/**====== Gallery css end ======**/
/**  =====================
      List-chart css start
==========================  **/
.ct-series-a .ct-line {
  stroke: #1ce3bb;
}

.ct-series-b .ct-line {
  stroke: rgba(255, 157, 136, 0.62);
}

.ct-series-c .ct-line {
  stroke: rgba(79, 84, 103, 0.45);
}

.ct-series-d .ct-line {
  stroke: rgba(129, 142, 219, 0.61);
}

.ct-series-a .ct-point, .ct-series-b .ct-point, .ct-series-c .ct-point, .ct-series-d .ct-point {
  stroke: rgba(52, 54, 70, 0.47);
  stroke-width: 8px;
  stroke-linecap: round;
}

.ct-series-a .ct-slice-donut {
  stroke: #01C0C8;
}

.ct-series-b .ct-slice-donut {
  stroke: #83D6DE;
}

.ct-series-c .ct-slice-donut {
  stroke: #1ABC9C;
}

.ct-series-d .ct-slice-donut {
  stroke: #4F5467;
}

/*===== List-chart Css End ====*/
/**  =====================
      Task-list css start
==========================  **/
.task-list select {
  width: 92%;
}
.task-list select .task-list img {
  margin-right: 5px;
  display: inline-block;
}
.task-list input {
  width: 92%;
}

.task-list-table img {
  width: 40px;
}
.task-list-table img i {
  color: #333;
  margin-right: 5px;
}

.task-page td:last-child {
  position: relative;
}
.task-page a {
  cursor: pointer;
}
.task-page tr td:last-child i {
  margin-right: 10px;
}

/*===== Task-list css end ====*/
/**  =====================
      Task-detiails css start
==========================  **/
.thumb-img {
  position: relative;
  display: block;
}
.thumb-img:hover .caption-hover {
  background-color: rgba(0, 0, 0, 0.7);
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100);
  position: absolute;
  width: 100%;
  height: 100%;
}

.caption-hover {
  top: 0;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.caption-hover > span {
  top: 38%;
  width: 100%;
  position: absolute;
  text-align: center;
}

.media .b-2-primary {
  border: 2px solid #4680ff;
}

.thumb-block {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 3px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}
.thumb-block a {
  font-size: 12px;
}
.thumb-block i {
  margin-right: 0;
}

.comment-block i {
  margin-right: 0;
}

.dropdown-item {
  color: #666;
  padding: 6px 20px;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: #4680ff;
}

.task-detail-right .counter {
  text-align: center;
  color: #777;
}

.task-details .table.table-xs td, .task-details .table.table-xs th {
  padding: 1rem 0.3rem;
}

.assign-user .media-left img {
  margin-bottom: 0;
}

.photo-table img {
  display: inline-block;
  width: 40px;
  margin-bottom: 5px;
}

.v-middle {
  vertical-align: middle;
}

.revision-block .form-group {
  margin-bottom: 0;
}
.revision-block i {
  margin-right: 0;
}

.task-setting .switchery {
  display: block !important;
  float: right;
}
.task-setting .form-group {
  margin-bottom: 0;
}

.task-attachment i {
  cursor: pointer;
}

/**====== Task-details css end ======**/
/**  =====================
      Task-board css start
==========================  **/
.filter-bar .nav, .filter-bar .nav-item {
  display: inline-block;
}
.filter-bar > .navbar {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08);
  padding: 0.5rem 1rem;
}

.navbar-nav .nav-item {
  float: left;
  line-height: 26px;
}

.nav-item button i {
  margin-right: 0;
}

.filter-bar .navbar-light .navbar-nav .nav-link {
  margin-right: 10px;
}

.card-footer .task-list-table {
  display: inline-block;
}
.card-footer .task-list-table a img {
  display: inline-block;
}

.task-board {
  margin-top: 10px;
  float: right;
}
.task-board .dropdown {
  display: inline-block;
}

p.task-detail {
  margin-bottom: 5px;
}
p.task-due {
  margin-bottom: 0;
}

.task-right-header-revision, .task-right-header-status, .task-right-header-users {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
}

.taskboard-right-progress, .taskboard-right-revision, .taskboard-right-users {
  margin-top: 10px;
}

.task-right h6 {
  font-size: 12px;
}
.task-right .icofont {
  margin-top: 5px;
}

.taskboard-right-revision .media .media-body .chat-header {
  font-size: 13px;
}

.media-left i {
  margin-right: 0;
}

.nav-item.nav-grid {
  float: right;
}

.faq-progress .progress {
  position: relative;
  background-color: #eeeded;
  height: 10px;
}
.faq-progress .progress .faq-text1, .faq-progress .progress .faq-text2, .faq-progress .progress .faq-text3, .faq-progress .progress .faq-text4, .faq-progress .progress .faq-text5 {
  font-weight: 600;
  margin-right: -37px;
}
.faq-progress .progress .faq-bar1, .faq-progress .progress .faq-bar2, .faq-progress .progress .faq-bar3, .faq-progress .progress .faq-bar4, .faq-progress .progress .faq-bar5 {
  background: #29aecc;
  height: 10px;
  border-radius: 0;
  position: absolute;
  top: 0;
}
.faq-progress .progress .faq-bar1 {
  background-color: #FFB64D;
}
.faq-progress .progress .faq-text1 {
  color: #2196F3;
}
.faq-progress .progress .faq-bar2, .faq-progress .progress .faq-bar5 {
  background-color: #93BE52;
}
.faq-progress .progress .faq-text2, .faq-progress .progress .faq-text5 {
  color: #4CAF50;
}
.faq-progress .progress .faq-bar3 {
  background-color: #FC6180;
}
.faq-progress .progress .faq-text3 {
  color: #ff5252;
}
.faq-progress .progress .faq-bar4 {
  background-color: #4680ff;
}
.faq-progress .progress .faq-text4 {
  color: #f57c00;
}

.card-faq h4 {
  color: #2196F3;
}

.faq-progress .progress {
  margin-bottom: 10px;
}

/**====== Tsak-board css end ======**/
/**  =====================
      Issue-list css start
==========================  **/
.issue-list-progress {
  border-bottom: 1px solid #ccc;
}
.issue-list-progress .progress {
  position: relative;
  background-color: #eeeded;
  height: 9px;
  width: 100%;
  margin: 20px 0;
  overflow: visible;
}

.issue-progress .progress .issue-text1 {
  font-weight: 600;
  position: absolute;
}
.issue-progress .progress .issue-bar1 {
  background: #4680ff;
  height: 10px;
  border-radius: 0;
  position: absolute;
  top: 0;
}

.matrics-issue .sub-title {
  padding-top: 20px;
  padding-bottom: 10px;
  display: block;
}

.bg-white {
  background-color: #fff !important;
}

.matrics-issue div h6 {
  padding-top: 10px;
  color: #777;
}

table.matrics-table tr:first-child td {
  border-top: none !important;
}

#issue-list-table > thead > tr > th {
  border-bottom: none;
}

.note-card .notes-list {
  margin-bottom: 20px;
}

/**====== Issue-list css end ======**/
/**  =====================
      Product css start
==========================  **/
.prod-img {
  position: relative;
}

.prod-item .prod-img .option-hover {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  left: 0;
}

.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.prod-img .p-new a {
  position: absolute;
  top: 15px;
  right: 0;
  padding: 8px 13px;
  line-height: 1;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 2px 0 0 2px;
  background: #62d1f3;
  color: #fff;
  letter-spacing: 1px;
  font-weight: 600;
}

.prod-info a {
  font-size: 18px;
}
.prod-info .br-wrapper {
  margin: 0 auto 20px;
}
.prod-info .br-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}
.prod-info h6 {
  font-size: 18px;
  font-weight: 600;
}

.prod-view:hover .option-hover {
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 1;
}

.prod-item .prod-info {
  background: #fff;
  padding: 30px 0 20px;
}
.prod-item .br-widget {
  min-height: inherit;
}

.prod-img .p-sale {
  position: absolute;
  top: 15px;
  right: 20px;
  width: 40px;
  height: 40px;
  font-size: 11px;
  text-transform: uppercase;
  border-radius: 50%;
  background-color: #ff5252;
  color: #fff;
  font-weight: 800;
  letter-spacing: 1px;
  padding: 11px 4px;
}

.prod-info .br-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.option-icon i {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-size: 20px;
  line-height: 36px;
  margin: 30px 0;
  padding: 17px 5px;
}

/**====== Product css end ======**/
/**  =====================
      Product-details css start
==========================  **/
.product-detail-page {
  border-top: none;
}

.product-detail .br-wrapper {
  margin: 10px 0;
}
.product-detail .product-detail .btn i {
  margin-right: 0;
}
.product-detail .br-widget {
  min-height: 35px;
  margin-top: 0;
  display: block;
}
.product-detail .btn-number {
  background-color: #e0e0e0;
  border-color: #d4d4d4;
  border-radius: 0;
  color: #000;
}
.product-detail .product-price {
  display: inline-block;
  margin-right: 50px;
  font-size: 24px;
}
.product-detail .pro-desc, .product-detail hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

#small_banner .slick-slide {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
}
#small_banner img {
  cursor: pointer;
}
#small_banner .slick-center {
  filter: grayscale(0);
  opacity: 1;
}
#small_banner:hover .slick-prev {
  left: 0;
  transition: all ease-in 0.3s;
}
#small_banner:hover .slick-next {
  right: 0;
  transition: all ease-in 0.3s;
}

.port_details_all_img {
  overflow: hidden;
}

.slick-prev {
  left: -45px;
  z-index: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.62);
  width: 4%;
  transition: all ease-in 0.3s;
}
.slick-prev:focus, .slick-prev:hover {
  color: transparent;
  outline: none;
  background: rgba(0, 0, 0, 0.8);
}

.slick-next {
  right: -45px;
  z-index: 1;
  height: 100%;
  background: rgba(0, 0, 0, 0.62);
  width: 4%;
  transition: all ease-in 0.3s;
}
.slick-next:focus, .slick-next:hover {
  color: transparent;
  outline: none;
  background: rgba(0, 0, 0, 0.8);
}

.counter-input .input-group {
  width: 20%;
}

.pro-det-tab .tab-content {
  border: 1px solid #ccc;
  border-top: 0;
}

.big_banner .port_big_img {
  margin-bottom: 15px;
}

.cd-price .cd-price-month {
  font-size: 64px;
}

.product-edit .br-wrapper {
  margin: 10px 0 30px;
}

.addcontact .md-content > div ul {
  padding-left: 0;
}

/**====== Product-details css end ======**/
/* ======================
    Panels and wells css start
========================= */
.panel {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body p {
  overflow: hidden;
  margin-bottom: 0;
}

.panels-wells .panel {
  margin-bottom: 20px;
}
.panels-wells .panel .panel-body {
  padding: 15px;
}

.panel-heading {
  padding: 10px 15px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #fafafa;
  border-top: 1px solid #eeeeee;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-sm {
  padding: 9px;
  border-radius: 3px;
}

.well-lg {
  padding: 24px;
  border-radius: 6px;
}

/**====== Panels and wells css end ======**/
/**  =====================
      Search css start
==========================  **/
.search-result .card {
  border-top: none;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
}

.seacrh-header {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.search-content {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.search-content img {
  width: 120px;
}
.search-content:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.search-result i {
  float: right;
}

.search2 .card-block:first-child {
  padding-top: 0;
}

/**====== Search css end ======**/
/**  =====================
      Wizard css start
==========================  **/
.cart-page .wizard > .content > .body {
  width: 100%;
  padding: 0;
}

.wizard > .content > .body {
  width: 100%;
}

.payment-card {
  border: 1px solid #ccc;
  border-radius: 0;
  margin-bottom: 15px;
  padding: 20px;
}
.payment-card table {
  margin-bottom: 0;
}

.confirmation {
  text-align: center;
  font-size: 80px;
}

.confirmation-icon {
  color: #FC6180;
}

.width-100 {
  width: 100%;
}

.post-input {
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  resize: none;
}

.file-upload-lbl {
  max-width: 15px;
  padding: 5px 0 0;
}

/**====== Wizard css end ======**/
/**====== Timeline css start ======**/
.post-timelines .media {
  align-items: center;
}

a[data-toggle=lightbox] .img-fluid, a[data-lightbox=roadtrip] .img-fluid, a[data-lightbox=example-set] .img-fluid {
  margin: 10px 0;
}

/**====== Timeline css end ======**/
/**  =====================
      Wall css start
==========================  **/
.post-new-footer {
  border-top: 1px solid #ccc;
}
.post-new-footer i {
  font-size: 18px;
  margin-right: 15px;
  cursor: pointer;
}

.inline-editable .btn {
  padding: 10px;
}
.inline-editable .input-group .form-control {
  width: 100%;
}

.input-group span {
  cursor: pointer;
}
.input-group a {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
}

.msg-send {
  background-color: #f3f3f3;
}
.msg-send:focus {
  background-color: #f3f3f3;
}

.wall-dropdown:after {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}

.wall-img-preview {
  display: inline-block;
  text-align: center;
}
.wall-img-preview .wall-item {
  display: block;
  float: left;
  position: relative;
  overflow: hidden;
  border: 2px solid #fff;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}
.wall-img-preview .wall-item:first-child:nth-last-child(2) {
  width: 50%;
  width: 50%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(2) ~ div, .wall-img-preview .wall-item:first-child:nth-last-child(2) ~ div {
  width: 50%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(10) {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(10) ~ div:not(:last-child) {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(11) ~ div:nth-last-of-type(-n+3) {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(3) {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(3) ~ div {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(4) {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(4) ~ div:not(:last-child) {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(5) {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(5) ~ div:not(:nth-last-of-type(-n+2)) {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(6) {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(6) ~ div {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(7) ~ div:nth-last-of-type(-n+3) {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(9) {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(9) ~ div {
  width: 33.333333%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(5) ~ div:nth-last-of-type(-n+2) {
  width: 50%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(11) {
  width: 25%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(11) ~ div:not(:nth-last-of-type(-n+3)) {
  width: 25%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(12) {
  width: 25%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(12) ~ div {
  width: 25%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(7) {
  width: 25%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(7) ~ div:not(:nth-last-of-type(-n+3)) {
  width: 25%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(8) {
  width: 25%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(8) ~ div {
  width: 25%;
}
.wall-img-preview .wall-item:first-child:nth-last-child(10) ~ div:nth-child(10), .wall-img-preview .wall-item:first-child:nth-last-child(4) ~ div:nth-child(4) {
  width: 100%;
}
.wall-img-preview .wall-item > img {
  display: none;
}
.wall-img-preview .wall-item:only-child {
  width: 100%;
}

.fb-timeliner h2 {
  background: #4680ff;
  color: #fff;
  margin-top: 0;
  padding: 15px;
  font-size: 16px;
  border-radius: 2px;
  -webkit-border-radius: 4px;
  font-weight: 300;
}
.fb-timeliner ul {
  margin-left: 15px;
  margin-bottom: 20px;
}
.fb-timeliner ul li {
  margin-bottom: 3px;
}
.fb-timeliner ul li a {
  color: #999797;
  border-left: 4px solid #d3d7dd;
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  display: block;
}
.fb-timeliner ul li a:hover {
  color: #999797;
  border-left: 4px solid #b1b1b1;
  padding-left: 10px;
}
.fb-timeliner ul li.active a {
  color: #7a7a7a;
  border-left: 4px solid #7a7a7a;
  padding-left: 10px;
}

/*====== Wall css End ======*/
/**  =====================
      X-editable css start
==========================  **/
.dotted-line-theme .no_edit {
  width: 100% !important;
  padding: 15px 2px;
}
.dotted-line-theme .no_edit .i_text {
  font-size: 13px;
}
.dotted-line-theme .just_edit input[type=radio] {
  opacity: 0;
}
.dotted-line-theme .ibtn_container {
  padding-left: 0;
  margin-top: 2px;
  position: absolute;
  top: 16px;
  z-index: 999;
  width: 120px;
}
.dotted-line-theme .ibtn_container i {
  color: #fff;
}

#msform #progressbar li.active {
  color: #4680ff;
}
#msform #progressbar li.active:before {
  background: #4680ff;
}

#progressbar li.active:after {
  background: #4680ff;
}

#msform a {
  color: #4680ff;
  font-weight: 600;
}

/*====== X-editable css End ======*/
/**  =====================
      Invoice css start
==========================  **/
.invoice-contact {
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  align-items: center;
}

.invoice-table {
  border-left: 1px solid #fff;
  padding-left: 20px;
}
.invoice-table td, .invoice-table th {
  border-top: none;
  padding: 3px 0;
}
.invoice-table > tbody > tr:last-child > td, .invoice-table > tfoot > tr:last-child > td {
  padding-bottom: 0;
}

.invoice-box h1 {
  font-size: 7rem;
}

.invoice-order {
  padding-left: 0;
}
.invoice-order th:first-child {
  padding-left: 0;
  width: 80px;
}

.invoice-detail-table th:first-child {
  width: 450px;
  text-align: left;
}
.invoice-detail-table thead th {
  text-align: center;
}
.invoice-detail-table td {
  vertical-align: middle;
  text-align: center;
}
.invoice-detail-table td:first-child {
  text-align: left;
}

.invoice-total {
  background: #f3f3f3;
  padding: 30px 0;
}
.invoice-total td, .invoice-total th {
  text-align: right;
}
.invoice-total td {
  padding-left: 30px;
}

.invoive-info {
  display: flex;
  margin-bottom: 30px;
}
.invoive-info h6 {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  color: #757575;
}

.invoice-total tbody {
  padding-right: 20px;
  float: right;
}

.dropdown-menu i {
  margin-right: 5px;
}

/**====== Invoice css end ======**/
.grid-layout span {
  display: block;
  border: 1px solid #ddd;
  padding: 10px;
}

.vertical-alignment .row {
  padding: 15px 0;
  min-height: 10rem;
  border: 1px solid #ddd;
}

.inte-benifits {
  border: 1px solid #ddd;
}
.inte-benifits ul {
  list-style: square;
  padding-left: 20px;
}

/**  =====================
      Version css start
==========================  **/
.version .card-block {
  padding: 0;
}

.card.version .card-header .card-header-right {
  background-color: #fff;
  top: 8px;
}

.version .nav {
  display: block;
}
.version .nav li {
  display: block;
  padding: 10px 20px;
  cursor: pointer;
}
.version .nav li:hover {
  background-color: #f6f7f7;
}
.version .nav li a {
  color: #666;
}
.version .nav li span {
  position: absolute;
  right: 20px;
}
.version .navigation-header a {
  color: #999;
}

.support-btn {
  padding: 20px;
}

.version .nav li:first-child {
  border-top: 1px solid #ddd;
  color: #666;
}

/**====== Version css end ======**/
/**====== Date-dropper css ======**/
.dd-w, .sp-container {
  z-index: 99;
}

/*====== Color css ends ======= */
/**  =====================
      Session Timeout css start
==========================  **/
#sessionTimeout-dialog .close {
  display: none;
}

/*====== Session Timeout css ends ======= */
.pull-0 {
  right: auto;
}

.pull-1 {
  right: 8.333333%;
}

.pull-2 {
  right: 16.666667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.333333%;
}

.pull-5 {
  right: 41.666667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.333333%;
}

.pull-8 {
  right: 66.666667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.333333%;
}

.pull-11 {
  right: 91.666667%;
}

.pull-12 {
  right: 100%;
}

.push-0 {
  left: auto;
}

.push-1 {
  left: 8.333333%;
}

.push-2 {
  left: 16.666667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.333333%;
}

.push-5 {
  left: 41.666667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.333333%;
}

.push-8 {
  left: 66.666667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.333333%;
}

.push-11 {
  left: 91.666667%;
}

.push-12 {
  left: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-sm-0 {
    right: auto;
  }

  .pull-sm-1 {
    right: 8.333333%;
  }

  .pull-sm-2 {
    right: 16.666667%;
  }

  .pull-sm-3 {
    right: 25%;
  }

  .pull-sm-4 {
    right: 33.333333%;
  }

  .pull-sm-5 {
    right: 41.666667%;
  }

  .pull-sm-6 {
    right: 50%;
  }

  .pull-sm-7 {
    right: 58.333333%;
  }

  .pull-sm-8 {
    right: 66.666667%;
  }

  .pull-sm-9 {
    right: 75%;
  }

  .pull-sm-10 {
    right: 83.333333%;
  }

  .pull-sm-11 {
    right: 91.666667%;
  }

  .pull-sm-12 {
    right: 100%;
  }

  .push-sm-0 {
    left: auto;
  }

  .push-sm-1 {
    left: 8.333333%;
  }

  .push-sm-2 {
    left: 16.666667%;
  }

  .push-sm-3 {
    left: 25%;
  }

  .push-sm-4 {
    left: 33.333333%;
  }

  .push-sm-5 {
    left: 41.666667%;
  }

  .push-sm-6 {
    left: 50%;
  }

  .push-sm-7 {
    left: 58.333333%;
  }

  .push-sm-8 {
    left: 66.666667%;
  }

  .push-sm-9 {
    left: 75%;
  }

  .push-sm-10 {
    left: 83.333333%;
  }

  .push-sm-11 {
    left: 91.666667%;
  }

  .push-sm-12 {
    left: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-md-0 {
    right: auto;
  }

  .pull-md-1 {
    right: 8.333333%;
  }

  .pull-md-2 {
    right: 16.666667%;
  }

  .pull-md-3 {
    right: 25%;
  }

  .pull-md-4 {
    right: 33.333333%;
  }

  .pull-md-5 {
    right: 41.666667%;
  }

  .pull-md-6 {
    right: 50%;
  }

  .pull-md-7 {
    right: 58.333333%;
  }

  .pull-md-8 {
    right: 66.666667%;
  }

  .pull-md-9 {
    right: 75%;
  }

  .pull-md-10 {
    right: 83.333333%;
  }

  .pull-md-11 {
    right: 91.666667%;
  }

  .pull-md-12 {
    right: 100%;
  }

  .push-md-0 {
    left: auto;
  }

  .push-md-1 {
    left: 8.333333%;
  }

  .push-md-2 {
    left: 16.666667%;
  }

  .push-md-3 {
    left: 25%;
  }

  .push-md-4 {
    left: 33.333333%;
  }

  .push-md-5 {
    left: 41.666667%;
  }

  .push-md-6 {
    left: 50%;
  }

  .push-md-7 {
    left: 58.333333%;
  }

  .push-md-8 {
    left: 66.666667%;
  }

  .push-md-9 {
    left: 75%;
  }

  .push-md-10 {
    left: 83.333333%;
  }

  .push-md-11 {
    left: 91.666667%;
  }

  .push-md-12 {
    left: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-lg-0 {
    right: auto;
  }

  .pull-lg-1 {
    right: 8.333333%;
  }

  .pull-lg-2 {
    right: 16.666667%;
  }

  .pull-lg-3 {
    right: 25%;
  }

  .pull-lg-4 {
    right: 33.333333%;
  }

  .pull-lg-5 {
    right: 41.666667%;
  }

  .pull-lg-6 {
    right: 50%;
  }

  .pull-lg-7 {
    right: 58.333333%;
  }

  .pull-lg-8 {
    right: 66.666667%;
  }

  .pull-lg-9 {
    right: 75%;
  }

  .pull-lg-10 {
    right: 83.333333%;
  }

  .pull-lg-11 {
    right: 91.666667%;
  }

  .pull-lg-12 {
    right: 100%;
  }

  .push-lg-0 {
    left: auto;
  }

  .push-lg-1 {
    left: 8.333333%;
  }

  .push-lg-2 {
    left: 16.666667%;
  }

  .push-lg-3 {
    left: 25%;
  }

  .push-lg-4 {
    left: 33.333333%;
  }

  .push-lg-5 {
    left: 41.666667%;
  }

  .push-lg-6 {
    left: 50%;
  }

  .push-lg-7 {
    left: 58.333333%;
  }

  .push-lg-8 {
    left: 66.666667%;
  }

  .push-lg-9 {
    left: 75%;
  }

  .push-lg-10 {
    left: 83.333333%;
  }

  .push-lg-11 {
    left: 91.666667%;
  }

  .push-lg-12 {
    left: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-xl-0 {
    right: auto;
  }

  .pull-xl-1 {
    right: 8.333333%;
  }

  .pull-xl-2 {
    right: 16.666667%;
  }

  .pull-xl-3 {
    right: 25%;
  }

  .pull-xl-4 {
    right: 33.333333%;
  }

  .pull-xl-5 {
    right: 41.666667%;
  }

  .pull-xl-6 {
    right: 50%;
  }

  .pull-xl-7 {
    right: 58.333333%;
  }

  .pull-xl-8 {
    right: 66.666667%;
  }

  .pull-xl-9 {
    right: 75%;
  }

  .pull-xl-10 {
    right: 83.333333%;
  }

  .pull-xl-11 {
    right: 91.666667%;
  }

  .pull-xl-12 {
    right: 100%;
  }

  .push-xl-0 {
    left: auto;
  }

  .push-xl-1 {
    left: 8.333333%;
  }

  .push-xl-2 {
    left: 16.666667%;
  }

  .push-xl-3 {
    left: 25%;
  }

  .push-xl-4 {
    left: 33.333333%;
  }

  .push-xl-5 {
    left: 41.666667%;
  }

  .push-xl-6 {
    left: 50%;
  }

  .push-xl-7 {
    left: 58.333333%;
  }

  .push-xl-8 {
    left: 66.666667%;
  }

  .push-xl-9 {
    left: 75%;
  }

  .push-xl-10 {
    left: 83.333333%;
  }

  .push-xl-11 {
    left: 91.666667%;
  }

  .push-xl-12 {
    left: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
/*====== Warning css starts ======= */
.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;
}
.ie-warning p {
  font-size: 17px;
}
.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0;
}
.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px;
}
.ie-warning .iew-download > li {
  float: left;
  vertical-align: top;
}
.ie-warning .iew-download > li > a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0;
}
.ie-warning .iew-download > li > a > div {
  margin-top: 10px;
}
.ie-warning .iew-download > li > a:hover {
  background-color: #eee;
}

/*====== Warning css ends ======= */
.login .container-fluid, .login .container-xl, .login .container-lg, .login .container-md, .login .container-sm {
  width: auto;
}

/**  =====================
      Generic-class css start
========================== **/
.generic-card-block code {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.generic-image-body li {
  padding: 0 20px;
  display: inline-block;
}
.generic-image-body code {
  display: block;
}
.generic-image-body img {
  display: block;
  margin: 10px auto 0;
}

/*====== Padding css starts ======*/
.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

/*====== Padding css ends ======*/
/*====== Padding-top css starts ======*/
.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

/*====== Padding-top css ends ======*/
/*====== Padding-bottom css starts ======*/
.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

/*====== Padding-bottom css ends ======*/
/*====== Padding-left css starts ======*/
.p-l-0 {
  padding-left: 0px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-50 {
  padding-left: 50px;
}

/*====== Padding-left css ends ======*/
/*====== Padding-right css starts ======*/
.p-r-0 {
  padding-right: 0px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-r-50 {
  padding-right: 50px;
}

/*====== Padding-right css ends ======*/
/*====== Margin css starts ======*/
.m-0 {
  margin: 0px;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-30 {
  margin: 30px;
}

.m-35 {
  margin: 35px;
}

.m-40 {
  margin: 40px;
}

.m-45 {
  margin: 45px;
}

.m-50 {
  margin: 50px;
}

/*====== Margin css ends ======*/
/*====== Margin-top css starts ======*/
.m-t-0 {
  margin-top: 0px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-t-50 {
  margin-top: 50px;
}

/*====== Margin-top css ends ======*/
/*====== Margin-Bottom css starts ======*/
.m-b-0 {
  margin-bottom: 0px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-b-50 {
  margin-bottom: 50px;
}

/*====== Margin-Bottom css ends ======*/
/*====== Margin-left css starts ======*/
.m-l-0 {
  margin-left: 0px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-l-35 {
  margin-left: 35px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-l-50 {
  margin-left: 50px;
}

/*====== Margin-left css ends ======*/
/*====== Margin-right css starts ======*/
.m-r-0 {
  margin-right: 0px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-r-50 {
  margin-right: 50px;
}

/*====== Margin-right css ends ======*/
/*====== Display css starts ======*/
.d-none {
  display: none;
}

.d-inline-block {
  display: inline-block;
}

.d-inline-flex {
  display: inline-flex;
}

/*====== display css ends ======*/
/*====== Border-radius css starts ======*/
.b-radius-0 {
  border-radius: 0px !important;
}

.b-radius-5 {
  border-radius: 5px !important;
}

.b-radius-10 {
  border-radius: 10px !important;
}

/*====== Border-radius css ends ======*/
/*====== Font-size css starts ======*/
.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-900 {
  font-weight: 600;
}

/*====== Font-weight css ends ======*/
/*====== Font-style css starts ======*/
.f-s-normal {
  font-style: normal;
}

.f-s-italic {
  font-style: italic;
}

.f-s-oblique {
  font-style: oblique;
}

.f-s-initial {
  font-style: initial;
}

.f-s-inherit {
  font-style: inherit;
}

/*====== Font-style css ends ======*/
/*====== Text-align css starts ======*/
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/*====== Text-align css ends ======*/
/*====== Text-Transform css starts ======*/
.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

/*====== Text-Transform css ends ======*/
/*====== Text-Decoration css starts ======*/
.text-overline {
  text-decoration: overline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

/*====== Text-Decoration css ends ======*/
/*====== Vertical-Align css starts ======*/
.baseline {
  vertical-align: baseline;
}

.sub {
  vertical-align: sub;
}

.super {
  vertical-align: super;
}

.top {
  vertical-align: top;
}

.text-top {
  vertical-align: text-top;
}

.middle {
  vertical-align: middle;
}

.bottom {
  vertical-align: bottom;
}

.text-bottom {
  vertical-align: text-bottom;
}

.initial {
  vertical-align: initial;
}

.inherit {
  vertical-align: inherit;
}

/*====== Vertical-Align css ends ======*/
/*====== Position css starts ======*/
.pos-static {
  position: static;
}

.pos-absolute {
  position: absolute;
}

.pos-fixed {
  position: fixed;
}

.pos-relative {
  position: relative;
}

.pos-initial {
  position: initial;
}

.pos-inherit {
  position: inherit;
}

/*====== Position css ends ======*/
/*====== Float css starts ======*/
.f-left {
  float: left;
}

.f-right {
  float: right;
}

.f-none {
  float: none;
}

/*====== Float css ends ======*/
/*====== Overflow css starts ======*/
.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

.o-auto {
  overflow: auto;
}

/*====== Overflow css ends ======*/
/*====== Image-sizes css starts ======*/
.img-20 {
  width: 20px;
}

.img-30 {
  width: 30px;
}

.img-40 {
  width: 40px;
}

.img-50 {
  width: 50px;
}

.img-60 {
  width: 60px;
}

.img-70 {
  width: 70px;
}

.img-80 {
  width: 80px;
}

.img-90 {
  width: 90px;
}

.img-100 {
  width: 100px;
}

/*====== Image-sizes css ends ======*/
/*======= Text css starts ===========*/
.text-primary {
  color: #4680ff !important;
}

.text-warning {
  color: #FFB64D !important;
}

.text-default {
  color: #e0e0e0 !important;
}

.text-danger {
  color: #FC6180 !important;
}

.text-success {
  color: #93BE52 !important;
}

.text-inverse {
  color: #303548 !important;
}

.text-info {
  color: #62d1f3 !important;
}

.text-custom {
  color: #64b0f2 !important;
}

.text-pink {
  color: #ff7aa3 !important;
}

.text-muted-login {
  color: #d0d6e2 !important;
}

.text-dark {
  color: #2b3d51 !important;
}

.text-purple {
  color: #9261c6 !important;
}

.text-muted {
  color: #919aa3 !important;
}

/*======= Text css ends ===========*/
/*======= Label-color css starts  ======= */
.label {
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

.label-primary {
  background-color: #4680ff;
}

.label-warning {
  background-color: #FFB64D;
}

.label-default {
  background-color: #e0e0e0;
}

.label-danger {
  background-color: #FC6180;
}

.label-success {
  background-color: #93BE52;
}

.label-inverse {
  background-color: #303548;
}

.label-info {
  background-color: #62d1f3;
}

/*======= Label-color css starts  ======= */
/*======= Badge-color css starts  ======= */
.badge-primary {
  background-color: #4680ff;
}

.badge-warning {
  background-color: #FFB64D;
}

.badge-default {
  background-color: #e0e0e0;
}

.badge-danger {
  background-color: #FC6180;
}

.badge-success {
  background-color: #93BE52;
}

.badge-inverse {
  background-color: #303548;
}

.badge-info {
  background-color: #62d1f3;
}

/*======= Badge-color css end  ======= */
/*======= Background-color css starts  ======= */
.bg-color-box {
  text-align: center;
  display: inline-block;
  padding: 10px 20px;
  box-shadow: 0 0 5px 0 rgba(128, 128, 128, 0.49);
  font-weight: 600;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.bg-color-box span {
  color: #fff;
}

.bg-primary {
  background-color: #4680ff !important;
  color: #fff;
}

.bg-warning {
  background-color: #FFB64D !important;
  color: #fff;
}

.bg-default {
  background-color: #e0e0e0 !important;
  color: #fff;
}

.bg-danger {
  background-color: #FC6180 !important;
  color: #fff;
}

.bg-success {
  background-color: #93BE52 !important;
  color: #fff;
}

.bg-inverse {
  background-color: #303548 !important;
  color: #fff;
}

.bg-info {
  background-color: #62d1f3 !important;
  color: #fff;
}

/*======= Background-color css end  ======= */
/*======= Button-color css starts  ======= */
.color-primary {
  background-color: #4680ff;
}

.color-warning {
  background-color: #FFB64D;
}

.color-default {
  background-color: #e0e0e0;
}

.color-danger {
  background-color: #FC6180;
}

.color-success {
  background-color: #93BE52;
}

.color-inverse {
  background-color: #303548;
}

.color-info {
  background-color: #62d1f3;
}

/*======= Button-color css ends  ======= */
/*======= Loader-color css starts  ======= */
.loader-primary {
  background-color: #4680ff !important;
}

.loader-warning {
  background-color: #FFB64D !important;
}

.loader-default {
  background-color: #e0e0e0 !important;
}

.loader-danger {
  background-color: #FC6180 !important;
}

.loader-success {
  background-color: #93BE52 !important;
}

.loader-inverse {
  background-color: #303548 !important;
}

.loader-info {
  background-color: #62d1f3 !important;
}

/*======= Loader-color css ends  ======= */
/*======= Nestable-color css starts  ======= */
.nestable-primary {
  background-color: #4680ff !important;
  border-color: #4680ff;
  color: #fff !important;
}

.nestable-warning {
  background-color: #FFB64D !important;
  border-color: #FFB64D;
  color: #fff !important;
}

.nestable-default {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0;
  color: #fff !important;
}

.nestable-danger {
  background-color: #FC6180 !important;
  border-color: #FC6180;
  color: #fff !important;
}

.nestable-success {
  background-color: #93BE52 !important;
  border-color: #93BE52;
  color: #fff !important;
}

.nestable-inverse {
  background-color: #303548 !important;
  border-color: #303548;
  color: #fff !important;
}

.nestable-info {
  background-color: #62d1f3 !important;
  border-color: #62d1f3;
  color: #fff !important;
}

/*======= Nestable-color css ends  ======= */
/*======= Table-Border-Bottom-color css starts  ======= */
table thead .border-bottom-primary th {
  border-bottom: 1px solid #4680ff;
}
table tbody .border-bottom-primary th, table tbody .border-bottom-primary td {
  border-bottom: 1px solid #4680ff;
}
table thead .border-bottom-warning th {
  border-bottom: 1px solid #FFB64D;
}
table tbody .border-bottom-warning th, table tbody .border-bottom-warning td {
  border-bottom: 1px solid #FFB64D;
}
table thead .border-bottom-default th {
  border-bottom: 1px solid #e0e0e0;
}
table tbody .border-bottom-default th, table tbody .border-bottom-default td {
  border-bottom: 1px solid #e0e0e0;
}
table thead .border-bottom-danger th {
  border-bottom: 1px solid #FC6180;
}
table tbody .border-bottom-danger th, table tbody .border-bottom-danger td {
  border-bottom: 1px solid #FC6180;
}
table thead .border-bottom-success th {
  border-bottom: 1px solid #93BE52;
}
table tbody .border-bottom-success th, table tbody .border-bottom-success td {
  border-bottom: 1px solid #93BE52;
}
table thead .border-bottom-inverse th {
  border-bottom: 1px solid #303548;
}
table tbody .border-bottom-inverse th, table tbody .border-bottom-inverse td {
  border-bottom: 1px solid #303548;
}
table thead .border-bottom-info th {
  border-bottom: 1px solid #62d1f3;
}
table tbody .border-bottom-info th, table tbody .border-bottom-info td {
  border-bottom: 1px solid #62d1f3;
}

/*======= Table-Border-Bottom-color css ends  ======= */
/*======= Table styling css starts  ======= */
.table-styling .table-primary, .table-styling.table-primary {
  background-color: #4680ff;
  color: #fff;
  border: 3px solid #4680ff;
}
.table-styling .table-primary thead, .table-styling.table-primary thead {
  background-color: #135dff;
  border: 3px solid #135dff;
}
.table-styling .table-warning, .table-styling.table-warning {
  background-color: #FFB64D;
  color: #fff;
  border: 3px solid #FFB64D;
}
.table-styling .table-warning thead, .table-styling.table-warning thead {
  background-color: #ffa11a;
  border: 3px solid #ffa11a;
}
.table-styling .table-default, .table-styling.table-default {
  background-color: #e0e0e0;
  color: #fff;
  border: 3px solid #e0e0e0;
}
.table-styling .table-default thead, .table-styling.table-default thead {
  background-color: #c7c7c7;
  border: 3px solid #c7c7c7;
}
.table-styling .table-danger, .table-styling.table-danger {
  background-color: #FC6180;
  color: #fff;
  border: 3px solid #FC6180;
}
.table-styling .table-danger thead, .table-styling.table-danger thead {
  background-color: #fb2f58;
  border: 3px solid #fb2f58;
}
.table-styling .table-success, .table-styling.table-success {
  background-color: #93BE52;
  color: #fff;
  border: 3px solid #93BE52;
}
.table-styling .table-success thead, .table-styling.table-success thead {
  background-color: #79a13c;
  border: 3px solid #79a13c;
}
.table-styling .table-inverse, .table-styling.table-inverse {
  background-color: #303548;
  color: #fff;
  border: 3px solid #303548;
}
.table-styling .table-inverse thead, .table-styling.table-inverse thead {
  background-color: #1c1e29;
  border: 3px solid #1c1e29;
}
.table-styling .table-info, .table-styling.table-info {
  background-color: #62d1f3;
  color: #fff;
  border: 3px solid #62d1f3;
}
.table-styling .table-info thead, .table-styling.table-info thead {
  background-color: #33c3ef;
  border: 3px solid #33c3ef;
}
.table-styling tfoot.table-info, .table-stylingtfoot.table-info {
  background-color: #33c3ef;
  border: none;
  border-top: 3px solid #33c3ef;
  border-bottom: 3px solid #33c3ef;
}

/*======= Table styling css ends  ======= */
/*======= Toolbar css starts  ======= */
.toolbar-primary .tool-item {
  background: #4680ff !important;
}
.toolbar-primary .tool-item.selected, .toolbar-primary .tool-item:hover {
  background: #135dff !important;
}
.toolbar-primary.tool-top .arrow {
  border-color: #4680ff transparent transparent;
}
.toolbar-primary.tool-bottom .arrow {
  border-color: transparent transparent #4680ff;
}
.toolbar-primary.tool-left .arrow {
  border-color: transparent transparent transparent #4680ff;
}
.toolbar-primary.tool-right .arrow {
  border-color: transparent #4680ff transparent transparent;
}

.btn-toolbar-primary.pressed {
  background-color: #4680ff;
}

.toolbar-warning .tool-item {
  background: #FFB64D !important;
}
.toolbar-warning .tool-item.selected, .toolbar-warning .tool-item:hover {
  background: #ffa11a !important;
}
.toolbar-warning.tool-top .arrow {
  border-color: #FFB64D transparent transparent;
}
.toolbar-warning.tool-bottom .arrow {
  border-color: transparent transparent #FFB64D;
}
.toolbar-warning.tool-left .arrow {
  border-color: transparent transparent transparent #FFB64D;
}
.toolbar-warning.tool-right .arrow {
  border-color: transparent #FFB64D transparent transparent;
}

.btn-toolbar-warning.pressed {
  background-color: #FFB64D;
}

.toolbar-light .tool-item {
  background: #e0e0e0 !important;
}
.toolbar-light .tool-item.selected, .toolbar-light .tool-item:hover {
  background: #c7c7c7 !important;
}
.toolbar-light.tool-top .arrow {
  border-color: #e0e0e0 transparent transparent;
}
.toolbar-light.tool-bottom .arrow {
  border-color: transparent transparent #e0e0e0;
}
.toolbar-light.tool-left .arrow {
  border-color: transparent transparent transparent #e0e0e0;
}
.toolbar-light.tool-right .arrow {
  border-color: transparent #e0e0e0 transparent transparent;
}

.btn-toolbar-light.pressed {
  background-color: #e0e0e0;
}

.toolbar-danger .tool-item {
  background: #FC6180 !important;
}
.toolbar-danger .tool-item.selected, .toolbar-danger .tool-item:hover {
  background: #fb2f58 !important;
}
.toolbar-danger.tool-top .arrow {
  border-color: #FC6180 transparent transparent;
}
.toolbar-danger.tool-bottom .arrow {
  border-color: transparent transparent #FC6180;
}
.toolbar-danger.tool-left .arrow {
  border-color: transparent transparent transparent #FC6180;
}
.toolbar-danger.tool-right .arrow {
  border-color: transparent #FC6180 transparent transparent;
}

.btn-toolbar-danger.pressed {
  background-color: #FC6180;
}

.toolbar-success .tool-item {
  background: #93BE52 !important;
}
.toolbar-success .tool-item.selected, .toolbar-success .tool-item:hover {
  background: #79a13c !important;
}
.toolbar-success.tool-top .arrow {
  border-color: #93BE52 transparent transparent;
}
.toolbar-success.tool-bottom .arrow {
  border-color: transparent transparent #93BE52;
}
.toolbar-success.tool-left .arrow {
  border-color: transparent transparent transparent #93BE52;
}
.toolbar-success.tool-right .arrow {
  border-color: transparent #93BE52 transparent transparent;
}

.btn-toolbar-success.pressed {
  background-color: #93BE52;
}

.toolbar-dark .tool-item {
  background: #303548 !important;
}
.toolbar-dark .tool-item.selected, .toolbar-dark .tool-item:hover {
  background: #1c1e29 !important;
}
.toolbar-dark.tool-top .arrow {
  border-color: #303548 transparent transparent;
}
.toolbar-dark.tool-bottom .arrow {
  border-color: transparent transparent #303548;
}
.toolbar-dark.tool-left .arrow {
  border-color: transparent transparent transparent #303548;
}
.toolbar-dark.tool-right .arrow {
  border-color: transparent #303548 transparent transparent;
}

.btn-toolbar-dark.pressed {
  background-color: #303548;
}

.toolbar-info .tool-item {
  background: #62d1f3 !important;
}
.toolbar-info .tool-item.selected, .toolbar-info .tool-item:hover {
  background: #33c3ef !important;
}
.toolbar-info.tool-top .arrow {
  border-color: #62d1f3 transparent transparent;
}
.toolbar-info.tool-bottom .arrow {
  border-color: transparent transparent #62d1f3;
}
.toolbar-info.tool-left .arrow {
  border-color: transparent transparent transparent #62d1f3;
}
.toolbar-info.tool-right .arrow {
  border-color: transparent #62d1f3 transparent transparent;
}

.btn-toolbar-info.pressed {
  background-color: #62d1f3;
}

/*======= Toolbar css ends  ======= */
/*======= Card-Border-Top-color css starts  ======= */
.card-border-primary {
  border-top: 4px solid #4680ff;
}

.card-border-warning {
  border-top: 4px solid #FFB64D;
}

.card-border-default {
  border-top: 4px solid #e0e0e0;
}

.card-border-danger {
  border-top: 4px solid #FC6180;
}

.card-border-success {
  border-top: 4px solid #93BE52;
}

.card-border-inverse {
  border-top: 4px solid #303548;
}

.card-border-info {
  border-top: 4px solid #62d1f3;
}

/*======= Card-Border-Top-color css ends  ======= */
/*======= Panels-wells-color css starts  ======= */
.panels-wells .panel-primary {
  border-color: #4680ff;
}
.panels-wells .panel-warning {
  border-color: #FFB64D;
}
.panels-wells .panel-default {
  border-color: #e0e0e0;
}
.panels-wells .panel-danger {
  border-color: #FC6180;
}
.panels-wells .panel-success {
  border-color: #93BE52;
}
.panels-wells .panel-inverse {
  border-color: #303548;
}
.panels-wells .panel-info {
  border-color: #62d1f3;
}

/*======= Panels-wells-color css ends  ======= */
/*======= All-Borders-color css starts  ======= */
.b-t-primary {
  border-top: 1px solid #4680ff;
}

.b-b-primary {
  border-bottom: 1px solid #4680ff;
}

.b-l-primary {
  border-left: 1px solid #4680ff;
}

.b-r-primary {
  border-right: 1px solid #4680ff;
}

.b-t-warning {
  border-top: 1px solid #FFB64D;
}

.b-b-warning {
  border-bottom: 1px solid #FFB64D;
}

.b-l-warning {
  border-left: 1px solid #FFB64D;
}

.b-r-warning {
  border-right: 1px solid #FFB64D;
}

.b-t-default {
  border-top: 1px solid #e0e0e0;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.b-l-default {
  border-left: 1px solid #e0e0e0;
}

.b-r-default {
  border-right: 1px solid #e0e0e0;
}

.b-t-danger {
  border-top: 1px solid #FC6180;
}

.b-b-danger {
  border-bottom: 1px solid #FC6180;
}

.b-l-danger {
  border-left: 1px solid #FC6180;
}

.b-r-danger {
  border-right: 1px solid #FC6180;
}

.b-t-success {
  border-top: 1px solid #93BE52;
}

.b-b-success {
  border-bottom: 1px solid #93BE52;
}

.b-l-success {
  border-left: 1px solid #93BE52;
}

.b-r-success {
  border-right: 1px solid #93BE52;
}

.b-t-inverse {
  border-top: 1px solid #303548;
}

.b-b-inverse {
  border-bottom: 1px solid #303548;
}

.b-l-inverse {
  border-left: 1px solid #303548;
}

.b-r-inverse {
  border-right: 1px solid #303548;
}

.b-t-info {
  border-top: 1px solid #62d1f3;
}

.b-b-info {
  border-bottom: 1px solid #62d1f3;
}

.b-l-info {
  border-left: 1px solid #62d1f3;
}

.b-r-info {
  border-right: 1px solid #62d1f3;
}

.b-t-theme {
  border-top: 1px solid #ddd;
}

.b-b-theme {
  border-bottom: 1px solid #ddd;
}

.b-l-theme {
  border-left: 1px solid #ddd;
}

.b-r-theme {
  border-right: 1px solid #ddd;
}

/*======= All-Borders-color css ends  ======= */
/**====== Generic-class css end ======**/
/*
$col:#FF5370,#E53935,#f07178,#FF5370,#F78C6C,#F76D47,#FFCB6B,#FFB62C,#C3E88D,#91B859,#B2CCD6,#8796B0,#89DDFF,#39ADB5,#82AAFF,#6182B8,#C792EA,#7C4DFF,#bb80b3,#945EB8,#ab7967;
*/
.pcoded .pcoded-navbar .pcoded-item > li.active > a {
  font-weight: 600;
}
.pcoded .d-color > ul:nth-child(1) > li > a > .pcoded-micon {
  background-color: #ff3366;
}
.pcoded .d-color > ul:nth-child(2) > li > a > .pcoded-micon {
  background-color: #4680ff;
}
.pcoded .d-color > ul:nth-child(3) > li > a > .pcoded-micon {
  background-color: #FC6180;
}
.pcoded .d-color > ul:nth-child(4) > li > a > .pcoded-micon {
  background-color: #93BE52;
}
.pcoded .d-color > ul:nth-child(5) > li > a > .pcoded-micon {
  background-color: #FFB64D;
}
.pcoded .d-color > ul:nth-child(6) > li > a > .pcoded-micon {
  background-color: #ab7967;
}
.pcoded .d-color > ul:nth-child(7) > li > a > .pcoded-micon {
  background-color: #39ADB5;
}
.pcoded .d-color > ul:nth-child(8) > li > a > .pcoded-micon {
  background-color: #7C4DFF;
}
.pcoded .d-color > ul:nth-child(9) > li > a > .pcoded-micon {
  background-color: #FF5370;
}
.pcoded .d-color > ul:nth-child(10) > li > a > .pcoded-micon {
  background-color: #ff3366;
}
.pcoded .d-color > ul:nth-child(11) > li > a > .pcoded-micon {
  background-color: #4680ff;
}
.pcoded .d-color > ul:nth-child(12) > li > a > .pcoded-micon {
  background-color: #FC6180;
}
.pcoded .d-color > ul:nth-child(13) > li > a > .pcoded-micon {
  background-color: #93BE52;
}
.pcoded .d-color > ul:nth-child(14) > li > a > .pcoded-micon {
  background-color: #FFB64D;
}
.pcoded .d-color > ul:nth-child(15) > li > a > .pcoded-micon {
  background-color: #ab7967;
}
.pcoded .d-color > ul:nth-child(16) > li > a > .pcoded-micon {
  background-color: #39ADB5;
}
.pcoded .d-color > ul:nth-child(17) > li > a > .pcoded-micon {
  background-color: #7C4DFF;
}
.pcoded .d-color > ul:nth-child(18) > li > a > .pcoded-micon {
  background-color: #FF5370;
}
.pcoded .d-color > ul:nth-child(19) > li > a > .pcoded-micon {
  background-color: #ff3366;
}
.pcoded .d-color > ul:nth-child(20) > li > a > .pcoded-micon {
  background-color: #4680ff;
}
.pcoded .d-color > ul:nth-child(21) > li > a > .pcoded-micon {
  background-color: #FC6180;
}
.pcoded .d-color > ul:nth-child(22) > li > a > .pcoded-micon {
  background-color: #93BE52;
}
.pcoded .d-color > ul:nth-child(23) > li > a > .pcoded-micon {
  background-color: #FFB64D;
}
.pcoded .d-color > ul:nth-child(24) > li > a > .pcoded-micon {
  background-color: #ab7967;
}
.pcoded .d-color > ul:nth-child(25) > li > a > .pcoded-micon {
  background-color: #39ADB5;
}
.pcoded .d-color > ul:nth-child(26) > li > a > .pcoded-micon {
  background-color: #7C4DFF;
}
.pcoded .d-color > ul:nth-child(27) > li > a > .pcoded-micon {
  background-color: #FF5370;
}
.pcoded[nav-type=st1] .d-color > ul > li > a > .pcoded-micon i, .pcoded[nav-type=st3] .d-color > ul > li > a > .pcoded-micon i, .pcoded[nav-type=st2] .d-color > ul > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul > li > a > .pcoded-micon b {
  display: none;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(1) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(1) > li > a > .pcoded-micon {
  background-color: rgba(255, 128, 159, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(1) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(1) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(1) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(1) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(2) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(2) > li > a > .pcoded-micon {
  background-color: rgba(147, 181, 255, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(2) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(2) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(2) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(2) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(3) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(3) > li > a > .pcoded-micon {
  background-color: rgba(253, 172, 188, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(3) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(3) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(3) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(3) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(4) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(4) > li > a > .pcoded-micon {
  background-color: rgba(182, 211, 138, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(4) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(4) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(4) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(4) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(5) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(5) > li > a > .pcoded-micon {
  background-color: rgba(255, 213, 154, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(5) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(5) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(5) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(5) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(6) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(6) > li > a > .pcoded-micon {
  background-color: rgba(198, 164, 152, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(6) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(6) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(6) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(6) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(7) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(7) > li > a > .pcoded-micon {
  background-color: rgba(106, 202, 208, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(7) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(7) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(7) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(7) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(8) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(8) > li > a > .pcoded-micon {
  background-color: rgba(180, 154, 255, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(8) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(8) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(8) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(8) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(9) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(9) > li > a > .pcoded-micon {
  background-color: rgba(255, 160, 176, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(9) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(9) > li > a > .pcoded-micon i {
  color: #ec0028;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(9) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(9) > li > a > .pcoded-micon i {
  color: #ec0028;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(10) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(10) > li > a > .pcoded-micon {
  background-color: rgba(255, 128, 159, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(10) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(10) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(10) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(10) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(11) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(11) > li > a > .pcoded-micon {
  background-color: rgba(147, 181, 255, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(11) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(11) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(11) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(11) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(12) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(12) > li > a > .pcoded-micon {
  background-color: rgba(253, 172, 188, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(12) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(12) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(12) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(12) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(13) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(13) > li > a > .pcoded-micon {
  background-color: rgba(182, 211, 138, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(13) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(13) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(13) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(13) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(14) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(14) > li > a > .pcoded-micon {
  background-color: rgba(255, 213, 154, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(14) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(14) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(14) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(14) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(15) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(15) > li > a > .pcoded-micon {
  background-color: rgba(198, 164, 152, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(15) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(15) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(15) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(15) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(16) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(16) > li > a > .pcoded-micon {
  background-color: rgba(106, 202, 208, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(16) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(16) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(16) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(16) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(17) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(17) > li > a > .pcoded-micon {
  background-color: rgba(180, 154, 255, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(17) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(17) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(17) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(17) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(18) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(18) > li > a > .pcoded-micon {
  background-color: rgba(255, 160, 176, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(18) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(18) > li > a > .pcoded-micon i {
  color: #ec0028;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(18) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(18) > li > a > .pcoded-micon i {
  color: #ec0028;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(19) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(19) > li > a > .pcoded-micon {
  background-color: rgba(255, 128, 159, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(19) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(19) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(19) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(19) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(20) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(20) > li > a > .pcoded-micon {
  background-color: rgba(147, 181, 255, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(20) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(20) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(20) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(20) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(21) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(21) > li > a > .pcoded-micon {
  background-color: rgba(253, 172, 188, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(21) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(21) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(21) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(21) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(22) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(22) > li > a > .pcoded-micon {
  background-color: rgba(182, 211, 138, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(22) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(22) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(22) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(22) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(23) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(23) > li > a > .pcoded-micon {
  background-color: rgba(255, 213, 154, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(23) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(23) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(23) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(23) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(24) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(24) > li > a > .pcoded-micon {
  background-color: rgba(198, 164, 152, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(24) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(24) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(24) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(24) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(25) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(25) > li > a > .pcoded-micon {
  background-color: rgba(106, 202, 208, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(25) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(25) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(25) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(25) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(26) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(26) > li > a > .pcoded-micon {
  background-color: rgba(180, 154, 255, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(26) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(26) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(26) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(26) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(27) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(27) > li > a > .pcoded-micon {
  background-color: rgba(255, 160, 176, 0.5);
}
.pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(27) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(27) > li > a > .pcoded-micon i {
  color: #ec0028;
}
.pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(27) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color .pcoded-navbar[navbar-theme*=themelight] > ul:nth-child(27) > li > a > .pcoded-micon i {
  color: #ec0028;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(1) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(1) > li > a > .pcoded-micon {
  background-color: #ff809f;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(1) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(1) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(1) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(1) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(2) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(2) > li > a > .pcoded-micon {
  background-color: #93b5ff;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(2) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(2) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(2) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(2) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(3) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(3) > li > a > .pcoded-micon {
  background-color: #fdacbc;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(3) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(3) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(3) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(3) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(4) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(4) > li > a > .pcoded-micon {
  background-color: #b6d38a;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(4) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(4) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(4) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(4) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(5) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(5) > li > a > .pcoded-micon {
  background-color: #ffd59a;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(5) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(5) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(5) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(5) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(6) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(6) > li > a > .pcoded-micon {
  background-color: #c6a498;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(6) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(6) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(6) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(6) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(7) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(7) > li > a > .pcoded-micon {
  background-color: #6acad0;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(7) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(7) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(7) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(7) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(8) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(8) > li > a > .pcoded-micon {
  background-color: #b49aff;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(8) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(8) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(8) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(8) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(9) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(9) > li > a > .pcoded-micon {
  background-color: #ffa0b0;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(9) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(9) > li > a > .pcoded-micon i {
  color: #ec0028;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(9) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(9) > li > a > .pcoded-micon i {
  color: #ec0028;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(10) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(10) > li > a > .pcoded-micon {
  background-color: #ff809f;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(10) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(10) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(10) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(10) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(11) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(11) > li > a > .pcoded-micon {
  background-color: #93b5ff;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(11) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(11) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(11) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(11) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(12) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(12) > li > a > .pcoded-micon {
  background-color: #fdacbc;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(12) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(12) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(12) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(12) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(13) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(13) > li > a > .pcoded-micon {
  background-color: #b6d38a;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(13) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(13) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(13) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(13) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(14) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(14) > li > a > .pcoded-micon {
  background-color: #ffd59a;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(14) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(14) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(14) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(14) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(15) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(15) > li > a > .pcoded-micon {
  background-color: #c6a498;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(15) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(15) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(15) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(15) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(16) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(16) > li > a > .pcoded-micon {
  background-color: #6acad0;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(16) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(16) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(16) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(16) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(17) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(17) > li > a > .pcoded-micon {
  background-color: #b49aff;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(17) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(17) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(17) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(17) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(18) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(18) > li > a > .pcoded-micon {
  background-color: #ffa0b0;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(18) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(18) > li > a > .pcoded-micon i {
  color: #ec0028;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(18) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(18) > li > a > .pcoded-micon i {
  color: #ec0028;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(19) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(19) > li > a > .pcoded-micon {
  background-color: #ff809f;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(19) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(19) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(19) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(19) > li > a > .pcoded-micon i {
  color: #cc0033;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(20) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(20) > li > a > .pcoded-micon {
  background-color: #93b5ff;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(20) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(20) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(20) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(20) > li > a > .pcoded-micon i {
  color: #0046df;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(21) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(21) > li > a > .pcoded-micon {
  background-color: #fdacbc;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(21) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(21) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(21) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(21) > li > a > .pcoded-micon i {
  color: #f20534;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(22) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(22) > li > a > .pcoded-micon {
  background-color: #b6d38a;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(22) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(22) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(22) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(22) > li > a > .pcoded-micon i {
  color: #5d7c2e;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(23) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(23) > li > a > .pcoded-micon {
  background-color: #ffd59a;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(23) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(23) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(23) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(23) > li > a > .pcoded-micon i {
  color: #e68800;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(24) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(24) > li > a > .pcoded-micon {
  background-color: #c6a498;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(24) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(24) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(24) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(24) > li > a > .pcoded-micon i {
  color: #6f4a3d;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(25) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(25) > li > a > .pcoded-micon {
  background-color: #6acad0;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(25) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(25) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(25) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(25) > li > a > .pcoded-micon i {
  color: #216367;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(26) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(26) > li > a > .pcoded-micon {
  background-color: #b49aff;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(26) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(26) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(26) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(26) > li > a > .pcoded-micon i {
  color: #3d00e6;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(27) > li > a > .pcoded-micon, .pcoded[nav-type=st4] .d-color > ul:nth-child(27) > li > a > .pcoded-micon {
  background-color: #ffa0b0;
}
.pcoded[nav-type=st3] .d-color > ul:nth-child(27) > li > a > .pcoded-micon b, .pcoded[nav-type=st3] .d-color > ul:nth-child(27) > li > a > .pcoded-micon i {
  color: #ec0028;
}
.pcoded[nav-type=st4] .d-color > ul:nth-child(27) > li > a > .pcoded-micon b, .pcoded[nav-type=st4] .d-color > ul:nth-child(27) > li > a > .pcoded-micon i {
  color: #ec0028;
}

/**  =====================
      Left-side-menu css start
==========================  **/
.main-menu {
  background: #2c3e50;
  float: left;
  width: 270px;
  z-index: 99;
}
.main-menu .main-menu-header {
  align-items: center;
  background: #4a6076;
  display: flex;
  padding: 30px 20px 15px 20px;
}
.main-menu .main-menu-header .user-details {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}
.main-menu .main-menu-header .user-details #more-details {
  cursor: pointer;
}
.main-menu .main-menu-header .user-details span {
  color: #000;
  display: block;
  font-size: 14px;
  text-transform: capitalize;
}
.main-menu .main-menu-header .user-details span i {
  font-size: 10px;
  margin-left: 10px;
}
.main-menu .main-menu-header .user-details span:first-child {
  margin-bottom: 5px;
  font-weight: 600;
}
.main-menu .main-menu-header .user-details span:last-child {
  color: #999;
  font-size: 12px;
}
.main-menu .main-menu-content .more-details a {
  padding: 10px 25px;
  display: block;
  color: #000;
  transition: all ease-in 0.3s;
}
.main-menu .main-menu-content .more-details a:hover {
  color: #4680ff;
}
.main-menu .main-menu-content .more-details a i {
  margin-right: 10px;
}
.main-menu .main-menu-content .menu-search {
  padding: 20px;
  padding-bottom: 10px;
}
.main-menu .main-menu-content li {
  position: relative;
}

/*.main-menu .main-menu-content .more-details {
  display: none;
}*/
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .main-menu-header, .pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .main-menu-content {
  display: none;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .main-menu {
  width: 80px;
}

.sidebar_toggle a {
  margin: -5px;
  line-height: 1;
}

/**  =====================
      Main-header css start
==========================  **/
.header-navbar {
  min-height: 80px;
  padding: 0;
}
.header-navbar .navbar-wrapper .navbar-logo {
  position: relative;
  align-items: center;
  display: flex;
  float: left;
  height: 80px;
  text-align: center;
  text-transform: uppercase;
  width: 270px;
  padding: 10px;
}
.header-navbar .navbar-wrapper .navbar-logo h5 {
  margin-bottom: 0;
}
.header-navbar .navbar-wrapper .navbar-logo .mobile-menu {
  position: absolute;
  right: 22px;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px 9px;
}
.header-navbar .navbar-wrapper .navbar-logo .mobile-search, .header-navbar .navbar-wrapper .navbar-logo .mobile-options {
  display: none;
}
.header-navbar .navbar-wrapper .navbar-container .nav-left, .header-navbar .navbar-wrapper .navbar-container .nav-right {
  float: left;
  margin-bottom: 0;
}
.header-navbar .navbar-wrapper .navbar-container .nav-left li, .header-navbar .navbar-wrapper .navbar-container .nav-right li {
  float: left;
  line-height: 5;
  padding: 0 10px;
  position: relative;
}
.header-navbar .navbar-wrapper .navbar-container .nav-left li .sidebar_toggle a, .header-navbar .navbar-wrapper .navbar-container .nav-right li .sidebar_toggle a {
  display: none;
}
.header-navbar .navbar-wrapper .navbar-container .nav-left li > a, .header-navbar .navbar-wrapper .navbar-container .nav-right li > a {
  font-size: 16px;
}
.header-navbar .navbar-wrapper .navbar-container .nav-left li > a > i.flag-icon, .header-navbar .navbar-wrapper .navbar-container .nav-right li > a > i.flag-icon {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.header-navbar .navbar-wrapper .navbar-container .nav-left a, .header-navbar .navbar-wrapper .navbar-container .nav-right a {
  padding: 0 0.6rem;
}
.header-navbar .navbar-wrapper .navbar-container .nav-left .mega-menu-top i {
  font-size: 10px;
  margin-left: 10px;
}
.header-navbar .navbar-wrapper .navbar-container .nav-right {
  float: right;
}
.header-navbar .navbar-wrapper .navbar-container .nav-right .mega-menu-top i {
  font-size: 10px;
  margin-left: 10px;
}
.header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile img {
  margin-right: 10px;
  width: 40px;
}
.header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile i {
  font-size: 10px;
  margin-left: 10px;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top {
  perspective: 1000px;
  z-index: 99;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .badge, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .badge {
  border-radius: 100px;
  right: 15px;
  position: absolute;
  top: 20px;
  padding: 4px;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
  -webkit-transform: translateY(30px);
  background: #fff;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.03), 0 -2px 1px -2px rgba(0, 0, 0, 0.02), 0 0 0 -1px rgba(0, 0, 0, 0.03);
  opacity: 0;
  position: absolute;
  right: 0;
  transform: translateY(30px);
  transition: all linear 0.3s;
  visibility: hidden;
  width: 24rem;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification a, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification a, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a {
  color: #666;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li {
  line-height: initial;
  padding: 1em 20px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child {
  padding: 10px 20px;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child h6, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child h6, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child h6, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child h6 {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child label, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child label, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child label, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child label {
  float: right;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child:hover, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child:hover, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child:hover, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child:hover {
  background-color: transparent;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li img, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li img, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li img, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li img {
  width: 40px;
  margin-right: 10px;
  align-self: flex-start !important;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:hover, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:hover, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:hover {
  background-color: #f1f1f1;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-user, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .notification-user, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-user, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .notification-user {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-msg, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .notification-msg, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-msg, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .notification-msg {
  margin-bottom: 5px;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-time, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .notification-time, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-time, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .notification-time {
  font-size: 12px;
  color: #919aa3;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
  width: 17em;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a {
  font-size: 15px;
  padding-left: 0;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification i, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification i {
  font-size: 15px;
  margin-left: 0;
  margin-right: 5px;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li {
  border-top: none;
  padding: 0.7em 20px;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child {
  padding: 0.7em 20px;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification:hover .show-notification, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top:hover .show-notification, .header-navbar .navbar-wrapper .navbar-container .header-notification:hover .profile-notification, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top:hover .profile-notification {
  -webkit-transform: translateY(0px);
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
}
.header-navbar .navbar-wrapper .navbar-container .lng-dropdown .show-notification, .header-navbar .navbar-wrapper .navbar-container .lng-dropdown .header-notification .profile-notification {
  width: 14em;
}
.header-navbar .navbar-wrapper .navbar-container .header-notification .lng-dropdown .profile-notification, .header-navbar .navbar-wrapper .navbar-container .lng-dropdown .mega-menu-top .profile-notification {
  width: 14em;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top {
  perspective: inherit;
  position: static !important;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .lng-dropdown .profile-notification {
  width: 14em;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
  left: 0;
  margin: 0 50px;
  width: calc(100% - 70px);
  z-index: 9999;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child {
  padding: 20px;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child h6, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child h6 {
  border-bottom: 1px solid #f1f1f1;
  display: block;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li h6, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li h6 {
  border-bottom: 1px solid #ddd;
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li img, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li img {
  border-radius: 0;
  transition: all ease-in-out 0.3s;
  width: auto;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li img:hover, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li img:hover {
  box-shadow: 0px 0px 10px 2px rgba(128, 128, 128, 0.37);
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .mega-mailbox li, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li {
  border-top: none;
  padding-left: 0;
  padding-top: 0;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .mega-mailbox li a, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li a {
  padding: 0;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .mega-mailbox li a i, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li a i {
  font-size: 20px;
  margin-left: 0;
  margin-right: 15px;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .mega-mailbox li h5, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li h5 {
  font-size: 15px;
  font-weight: 600;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .mega-menu-links li {
  border-top: none;
  padding: 10px 0;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .mega-menu-links li:first-child {
  padding-top: 0;
  padding: 10px 0;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .mega-menu-links a {
  display: block;
  font-size: 14px;
  padding: 0;
  text-transform: capitalize;
}
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .mega-menu-contact label, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .mega-menu-contact .form-control {
  font-size: 14px;
}

@media only screen and (max-width: 992px) {
  .header-navbar .navbar-wrapper .navbar-logo {
    justify-content: center;
  }
}
@media only screen and (max-width: 575px) {
  .header-navbar .navbar-wrapper .navbar-logo .mobile-search {
    display: none !important;
  }
}
@media only screen and (max-width: 575px) {
  .header-navbar .navbar-wrapper .navbar-container .nav-left li, .header-navbar .navbar-wrapper .navbar-container .nav-right li {
    padding: 0 5px;
  }
}
/*======= Main-header css end ======*/
.pcoded .pcoded-container {
  position: relative;
  background: #d5dae6;
}
.pcoded .pcoded-header {
  position: relative;
  display: block;
}
.pcoded .pcoded-navbar {
  display: block;
}

.pcoded-main-container {
  display: block;
  position: relative;
  background: #F6F7FB;
  min-height: calc(100vh - 80px);
}

.pcoded .pcoded-content {
  position: relative;
  display: block;
}

.pcoded-inner-navbar {
  display: block;
  position: relative;
}

.pcoded-inner-content {
  padding: 10px;
}

.pcoded .pcoded-navbar .pcoded-item {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.pcoded .pcoded-navbar .pcoded-item > li {
  display: block;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
}
.pcoded .pcoded-navbar .pcoded-item > li > a {
  display: block;
  font-size: 14px;
  padding: 0 15px;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
  display: block;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
  display: block;
  font-size: 14px;
  font-weight: 100;
  padding: 0 15px;
  text-decoration: none;
  position: relative;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a > .pcoded-mtext {
  transition: all 0.3s ease-in-out;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a > .pcoded-mtext:before {
  transition: all 0.3s ease-in-out;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-micon {
  font-size: 10px;
  padding-right: 5px;
}
.pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
  font-size: 15px;
  padding: 4px;
  margin-right: 10px;
  color: #fff;
  border-radius: 4px;
  width: 30px;
  display: inline-block;
  height: 30px;
  text-align: center;
}
.pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon b {
  margin: 0 2px;
  font-size: 14px;
  line-height: 1;
}
.pcoded[vertical-placement=right] .main-body .page-wrapper .page-header-title i {
  margin-right: 0;
  margin-left: 20px;
}

.pcoded-inner-navbar {
  height: 100%;
}

.pcoded[theme-layout=vertical] .pcoded-header {
  width: 100%;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
  z-index: 1029;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header {
  display: block;
  z-index: 1028;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header .pcoded-logo {
  opacity: 1;
  text-align: center;
  visibility: visible;
  height: 50px;
  white-space: nowrap;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header .pcoded-logo a {
  display: block;
  text-decoration: none;
  padding: 9px 0;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header .pcoded-logo .logo-icon {
  bottom: -21px;
  font-size: 60px;
  left: 8px;
  position: absolute;
  z-index: -1;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-icon {
  font-size: 45px;
  bottom: -7px;
  left: 5px;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}
.pcoded[theme-layout=vertical][pcoded-device-type=desktop] .pcoded-header .pcoded-left-header .pcoded-logo .logo-text, .pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text, .pcoded[theme-layout=vertical][pcoded-device-type=tablet] .pcoded-header .pcoded-left-header .pcoded-logo .logo-text {
  font-family: open sans;
  font-size: 16px;
  font-weight: 600;
  left: 80px;
  margin: 0 auto !important;
  opacity: 1;
  position: absolute;
  text-transform: uppercase;
  top: 25px;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  visibility: visible;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-header .navbar-logo {
  width: 200px;
  padding: 25px 15px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-header .navbar-logo .mobile-menu {
  right: 15px;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.nocollapsed .pcoded-left-header .pcoded-logo .logo-text {
  font-family: open sans;
  font-size: 16px;
  font-weight: 400;
  margin: 0 auto !important;
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  position: relative;
  top: 5px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text {
  opacity: 0;
  position: absolute;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  visibility: hidden;
}
.pcoded[theme-layout=vertical][vertical-nav-type=offcanvas] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo {
  opacity: 0;
  visibility: hidden;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-header .pcoded-left-header .pcoded-logo a .hide-in-smallsize {
  display: none;
}
.pcoded[theme-layout=vertical] .pcoded-header .sidebar_toggle a {
  border-radius: 4px;
  float: left;
  font-size: 18px;
  height: 35px;
  margin-right: 5px;
  position: relative;
  text-align: center;
  top: 7px;
  width: 40px;
  border-width: 0;
  border-style: solid;
}
.pcoded[theme-layout=vertical] .pcoded-header .sidebar_toggle a i {
  position: relative;
  top: -7px;
}
.pcoded[theme-layout=vertical] .pcoded-header .sidebar_toggle a:hover {
  text-decoration: none;
}

.sidebar_toggle a:focus {
  text-decoration: none;
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header {
  line-height: 50px;
  padding: 0 15px;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header {
  float: left;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rr-header {
  float: right;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header {
  padding-left: 20px;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header .pcoded-right-header .pcoded-rl-header {
  padding-left: 0;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.pcoded-right-header .pcoded-rr-header ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header ul > li, .pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rr-header ul > li {
  float: left;
  list-style: outside none none;
  margin: 0;
  padding: 0 5px;
  position: relative;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header ul > li > a, .pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rr-header ul > li > a {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header li.icons > a > i, .pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rr-header li.icons > a > i {
  font-size: 18px;
  position: relative;
  top: 3px;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header li.icons > a, .pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rr-header li.icons > a {
  padding: 0 5px;
}
.pcoded[theme-layout=vertical] .pcoded-right-header .pcoded-badge {
  background-color: #777;
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  min-height: 17px;
  min-width: 10px;
  opacity: 1;
  padding: 4px 7px;
  position: absolute;
  right: -4px;
  text-align: center;
  top: 9px;
  transition: opacity 0.3s linear 0s;
  -webkit-transition: opacity 0.3s linear 0s;
  -ms-transition: opacity 0.3s linear 0s;
  -moz-transition: opacity 0.3s linear 0s;
  -o-transition: opacity 0.3s linear 0s;
  vertical-align: middle;
  visibility: visible;
  white-space: nowrap;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone] .hide-small-device {
  display: none;
}
.pcoded[theme-layout=vertical] .pcoded-navbar {
  z-index: 1027;
  height: 100%;
}
.pcoded[theme-layout=vertical] .pcoded-content {
  position: relative;
  display: block;
}
.pcoded[theme-layout=vertical] .pcoded-container {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}
.pcoded[theme-layout=vertical].pcoded-wrapper, .pcoded[theme-layout=vertical] .pcoded-main-container {
  position: relative;
  margin: 0 auto;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-search {
  padding: 20px 20px 5px;
  position: relative;
}
.pcoded[theme-layout=vertical][vnavigation-view=view2] .pcoded-navbar .pcoded-search {
  border-radius: 4px 4px 0 0;
}

.pcoded-search input[type=text] {
  display: block;
  margin: 0;
  width: 100%;
  font-size: 14px;
  -webkit-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #FBFCFE;
  padding: 6px 22px 6px 10px;
  border: none;
  border: 1px solid #ddd;
}
.pcoded-search input[type=text]:focus {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.style-4 input[type=text].focus {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.pcoded-search .search-icon {
  cursor: pointer;
  margin-top: -33px;
  padding: 6px;
  position: absolute;
  right: 25px;
}

.pcoded[vertical-nav-type=collapsed] .pcoded-search-box {
  visibility: hidden;
  opacity: 0;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  position: absolute;
}
.pcoded[vertical-nav-type=collapsed] .pcoded-search input[type=text] {
  padding: 6px 22px 6px 10px;
}

.searchbar-toggle:before {
  content: "";
  cursor: pointer;
  float: right;
  font-family: FontAwesome;
  left: 0;
  position: absolute;
  top: 10px;
  visibility: hidden;
  opacity: 0;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
}

.pcoded[vertical-nav-type=collapsed] .searchbar-toggle:before {
  left: 35px;
  top: 10px;
  visibility: visible;
  opacity: 1;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
}
.pcoded[vertical-nav-type=collapsed] .pcoded-search.open .searchbar-toggle:before {
  content: "";
}
.pcoded[vertical-nav-type=collapsed] .pcoded-search.open .pcoded-search-box {
  margin-left: 60px;
  opacity: 1;
  position: absolute;
  top: 0;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  visibility: visible;
  width: 200px;
  padding-right: 10px;
}
.pcoded[vertical-nav-type=collapsed] .pcoded-search .search-icon {
  margin-top: -35px;
}
.pcoded[vertical-nav-type=collapsed][vertical-placement=right] .pcoded-search.open .pcoded-search-box {
  margin-left: 0;
  margin-right: 70px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item > li > a {
  text-align: left;
  padding: 10px 20px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
  position: relative;
  width: 100%;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
  text-align: left;
  padding: 8.7px 10px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=expanded] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
  padding-left: 10px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
  width: 80%;
  list-style: outside none none;
  margin: 0 auto;
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
  -webkit-transform-origin: 0 0;
  transform-origin: 10% 10%;
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-90deg);
  -webkit-transform: rotateX(-90deg);
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  position: relative;
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=true][item-border-style=dashed] li > a {
  border-bottom-style: dashed;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=true][item-border-style=dotted] li > a {
  border-bottom-style: dotted;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=true][item-border-style=solid] li > a {
  border-bottom-style: solid;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=true][item-border-style=none] li > a {
  border: none;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=false] > li > a {
  border-bottom-width: 0;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=true] > li > a {
  border-bottom-width: 1px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[subitem-border=false] .pcoded-hasmenu .pcoded-submenu li > a {
  border-bottom-width: 0;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[subitem-border=true] .pcoded-hasmenu .pcoded-submenu li > a {
  border-bottom-width: 1px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[subitem-border=true] .pcoded-hasmenu .pcoded-submenu li:last-child > a {
  border-bottom-width: 0;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu > a:after {
  float: right;
  font-style: normal;
  margin-left: 3px;
  font-size: 10px;
  position: absolute;
  right: 15px;
  top: 10px;
  font-family: "themify";
  padding-top: 12px;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon=style1] > a:after {
  content: "";
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon=style1] > a:after {
  content: "";
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon=style2] > a:after {
  content: "";
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon=style2] > a:after {
  content: "";
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon=style3] > a:after {
  content: "";
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon=style3] > a:after {
  content: "";
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu > a:after {
  float: right;
  font-style: normal;
  margin-left: 3px;
  font-size: 10px;
  position: absolute;
  right: 15px;
  top: 0;
  padding-top: 13px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a:after {
  float: right;
  font-style: normal;
  margin-left: 3px;
  position: absolute;
  right: 15px;
  font-size: 12px;
  top: 0;
  padding-top: 12px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger > a:after {
  float: right;
  font-style: normal;
  margin-left: 3px;
  font-size: 10px;
  position: absolute;
  right: 15px;
  top: 10px;
  color: #353c4e !important;
  font-family: "themify";
  padding-top: 12px;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header[pcoded-lheader-position=relative] {
  position: relative;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header[pcoded-lheader-position=fixed] {
  position: fixed;
}
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header[pcoded-lheader-position=absolute] {
  position: absolute;
}
.pcoded[theme-layout=vertical] .pcoded-navbar[pcoded-navbar-position=relative] {
  position: relative;
}
.pcoded[theme-layout=vertical] .pcoded-navbar[pcoded-navbar-position=fixed] {
  position: fixed;
}
.pcoded[theme-layout=vertical] .pcoded-navbar[pcoded-navbar-position=absolute] {
  position: absolute;
}
.pcoded[theme-layout=vertical] .pcoded-header[pcoded-header-position=relative] {
  position: relative;
}
.pcoded[theme-layout=vertical] .pcoded-header[pcoded-header-position=fixed] {
  position: fixed;
  z-index: 1028;
}
.pcoded[theme-layout=vertical] .pcoded-header[pcoded-header-position=absolute] {
  position: absolute;
}
.pcoded[theme-layout=vertical][vertical-layout=wide] .pcoded-container {
  width: 100%;
  margin: 0 auto;
}
.pcoded[theme-layout=vertical][vertical-layout=box] #styleSelector, .pcoded[theme-layout=vertical][vertical-layout=box] .p-chat-user, .pcoded[theme-layout=vertical][vertical-layout=box] .showChat_inner {
  position: absolute;
}
.pcoded[theme-layout=vertical][pcoded-device-type=desktop][vertical-layout=box] .pcoded-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.pcoded[theme-layout=vertical][pcoded-device-type=desktop][vertical-layout=box] .pcoded-container .pcoded-header {
  max-width: 1200px;
}
.pcoded[theme-layout=vertical][pcoded-device-type=tablet][vertical-layout=box] .pcoded-container {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-layout=box] .pcoded-container {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}
.pcoded[theme-layout=vertical][vertical-layout=widebox] .pcoded-container {
  width: 100%;
  margin: 0 auto;
}
.pcoded[theme-layout=vertical][pcoded-device-type=desktop][vertical-layout=widebox] .pcoded-main-container, .pcoded[theme-layout=vertical][pcoded-device-type=desktop][vertical-layout=widebox] .pcoded-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.pcoded[theme-layout=vertical][pcoded-device-type=tablet][vertical-layout=widebox] .pcoded-main-container, .pcoded[theme-layout=vertical][pcoded-device-type=tablet][vertical-layout=widebox] .pcoded-wrapper {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-layout=widebox] .pcoded-main-container, .pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-layout=widebox] .pcoded-wrapper {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}
.pcoded[theme-layout=vertical][vertical-layout=widebox] .pcoded-wrapper {
  overflow: hidden;
}
.pcoded .pcoded-overlay-box {
  background: #000 none repeat scroll 0 0;
  bottom: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  overflow: hidden;
}
.pcoded[vertical-nav-type=compact][vertical-effect=overlay] .pcoded-overlay-box, .pcoded[vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-overlay-box, .pcoded[vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-overlay-box, .pcoded[vertical-nav-type=fullsub-collapsed][vertical-effect=overlay] .pcoded-overlay-box, .pcoded[vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-overlay-box {
  visibility: visible;
  z-index: 1026;
  opacity: 0.3;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  overflow: hidden;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-micon {
  display: none;
  font-size: 10px;
  padding-right: 5px;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
  font-style: normal;
  padding-right: 4px;
  font-size: 10px;
  position: relative;
  left: 0;
  top: 0;
  font-family: themify;
  opacity: 1;
  visibility: visible;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style1] .pcoded-submenu li > a .pcoded-mtext:before {
  content: "";
  padding-right: 5px;
  font-size: 12px;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style2] .pcoded-submenu li > a .pcoded-mtext:before {
  content: "";
  padding-right: 5px;
  font-size: 12px;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style3] .pcoded-submenu li > a .pcoded-mtext:before {
  content: "";
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style4] .pcoded-submenu li > a .pcoded-mtext:before {
  content: "";
  padding-right: 10px;
  font-size: 12px;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style5] .pcoded-submenu li > a .pcoded-mtext:before {
  content: "";
  padding-right: 10px;
  font-size: 12px;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style6] .pcoded-submenu li > a .pcoded-mtext:before {
  content: "";
  padding-right: 10px;
  font-size: 8px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item > li.active > a > .pcoded-mcaret {
  background: transparent none repeat scroll 0 0;
  border-bottom: 0 solid transparent;
  border-right: 0 solid #fff;
  border-top: 0 solid transparent;
  display: block;
  float: right;
  height: 0;
  position: absolute;
  right: 0;
  top: calc(50% - 10px);
  width: 0;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item > li:hover > a:before {
  border-left-style: solid;
  border-left-width: 5px;
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
  border-left-style: solid;
  border-left-width: 5px;
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}
.pcoded[theme-layout=vertical] .pcoded-navbar {
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item > li.active > a:before {
  border-left-style: solid;
  border-left-width: 5px;
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar {
  float: right;
  right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .main-menu-content, .pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .main-menu-header {
  direction: rtl;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .main-menu-content ul {
  padding-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-content {
  float: unset;
  left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left] .pcoded-navbar {
  float: left;
}
.pcoded[theme-layout=vertical][vertical-placement=left] .pcoded-content {
  float: unset;
  right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-header .pcoded-left-header {
  float: right;
  right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-header .pcoded-right-header {
  float: unset;
  left: unset;
}
.pcoded[theme-layout=vertical][vertical-placement=left] .pcoded-header .pcoded-left-header {
  float: left;
}
.pcoded[theme-layout=vertical][vertical-placement=left] .pcoded-header .pcoded-right-header {
  float: unset;
  right: unset;
}
.pcoded[theme-layout=vertical][vertical-nav-type=expanded] .pcoded-header .pcoded-left-header, .pcoded[theme-layout=vertical][vertical-nav-type=expanded] .pcoded-navbar {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 270px;
  margin-right: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=push] .pcoded-content {
  margin-left: 270px;
  margin-right: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
  margin-left: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=push] .pcoded-content {
  margin-right: 270px;
  margin-left: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header, .pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-content {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header, .pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-content {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-content {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-content {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-effect=overlay] .pcoded-navbar .pcoded-item > li.active > a > .pcoded-mcaret {
  background: transparent !important;
  border-bottom: transparent !important;
  border-right: transparent !important;
  border-top: 15px solid transparent;
}
.pcoded[theme-layout=vertical][vertical-nav-type=expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout=vertical][vertical-nav-type=expanded][pcoded-device-type=desktop] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 270px;
  z-index: 1024;
  top: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout=vertical][vertical-nav-type=expanded][pcoded-device-type=tablet] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 270px;
  z-index: 1024;
  top: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-header .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar {
  width: 80px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > li > a {
  padding: 10px 25px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 80px;
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-content {
  margin-left: 80px;
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 45px;
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-content {
  margin-right: 80px;
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-content {
  margin-left: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-content {
  margin-right: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-content {
  margin-left: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 80px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-content {
  margin-right: 80px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
  opacity: 1;
  visibility: visible;
  position: absolute;
  top: 16px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu > a:after {
  opacity: 1;
  visibility: visible;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > li.pcoded-trigger > a .pcoded-mtext {
  opacity: 1;
  visibility: visible;
  position: absolute;
  padding-left: 22px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item li.pcoded-hasmenu > a:after {
  opacity: 0;
  visibility: hidden;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger a:after {
  opacity: 1;
  visibility: visible;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > li.pcoded-trigger {
  display: block;
  position: relative;
  width: 290px;
  z-index: 1024;
  border-right: none;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.13);
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 80px;
  position: absolute;
  width: 210px;
  z-index: 1024;
  border-left-style: solid;
  border-left-width: 1px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  position: relative;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  position: absolute;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > li.pcoded-trigger.active > a > .pcoded-mcaret {
  display: none;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0;
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-navbar {
  width: 270px;
  margin-left: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-content {
  margin-left: 0;
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-navbar {
  width: 270px;
  margin-right: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-content {
  margin-right: 0;
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-navbar {
  width: 270px;
  margin-left: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-content {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-navbar {
  width: 270px;
  margin-right: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-content {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-navbar {
  width: 270px;
  margin-left: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-content {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-navbar {
  width: 270px;
  margin-right: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-content {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 200px;
  margin-right: -200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-header .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-left: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-navbar {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-content {
  margin-left: 200px;
  margin-right: -200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 200px;
  margin-left: -200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-header .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-right: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-navbar {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-content {
  margin-right: 200px;
  margin-left: -200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-left: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-navbar {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-content {
  margin-left: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-right: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-navbar {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-content {
  margin-right: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-left: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-navbar {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-content {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-right: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-navbar {
  width: 200px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-content {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .main-menu-content, .pcoded[theme-layout=vertical][vertical-nav-type=compact] .main-menu-header {
  display: none;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item > li > a {
  line-height: 1;
  text-align: center;
  padding: 10px 20px 20px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
  opacity: 1;
  position: relative;
  text-align: center;
  visibility: visible;
  top: 10px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
  display: block;
  font-size: 18px;
  padding: 5px;
  margin: 0 auto;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item > li.active > a > .pcoded-mcaret {
  background: transparent none repeat scroll 0 0;
  border-bottom: 0 solid transparent;
  border-right: 0 solid #e9e9e9;
  border-top: 0 solid transparent;
  top: 18px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item > li.active > a:before {
  height: 100%;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item > li:hover > a:before {
  height: 100%;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger[dropdown-icon=style1] > a:after, .pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu[dropdown-icon=style1] > a:after {
  top: 18px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout=vertical][vertical-nav-type=compact][pcoded-device-type=desktop] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 210px;
  z-index: 1024;
  top: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout=vertical][vertical-nav-type=compact][pcoded-device-type=tablet] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 210px;
  z-index: 1024;
  top: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-header .pcoded-left-header, .pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-navbar {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 270px;
  margin-right: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-content {
  margin-left: 270px;
  margin-right: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
  margin-left: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-content {
  margin-right: 270px;
  margin-left: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header, .pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-content {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header, .pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-navbar {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-content {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-navbar {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-content {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-content {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-inner-navbar, .pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-inner-navbar {
  position: unset;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item, .pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item {
  position: unset;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item > li, .pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item > li {
  position: unset;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 230px;
  z-index: 1024;
  top: 0;
  bottom: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li, .pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
  position: unset;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 230px;
  z-index: 1024;
  top: 0;
  bottom: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-style: solid;
  border-left-width: 1px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-header.iscollapsed .pcoded-left-header, .pcoded[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-header .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 270px;
  margin-right: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-content {
  margin-left: 270px;
  margin-right: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
  margin-left: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-content {
  margin-right: 270px;
  margin-left: -270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header, .pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-content {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header, .pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-content {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-content {
  margin-left: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-content {
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item > li, .pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item > li {
  position: relative;
}
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 230px;
  z-index: 1024;
  top: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li, .pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
  position: relative;
}
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
  left: 100%;
  position: absolute;
  width: 230px;
  z-index: 1024;
  top: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item li.pcoded-trigger > a > .pcoded-mcaret, .pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item li.pcoded-trigger > a > .pcoded-mcaret {
  background: transparent none repeat scroll 0 0;
  border-bottom: 19px solid transparent;
  border-right: 12px solid;
  border-top: 19px solid transparent;
  display: block;
  float: right;
  height: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  z-index: 1025;
}
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-style: solid;
  border-left-width: 1px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=fullpage] .pcoded-header .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=fullpage] .pcoded-header .pcoded-right-header {
  margin-left: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=fullpage] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar {
  width: 100%;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-content {
  margin-left: 0;
  margin-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=fullpage] .pcoded-header .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=fullpage] .pcoded-header .pcoded-right-header {
  margin-right: 270px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=fullpage] .pcoded-header.iscollapsed .pcoded-left-header {
  width: 270px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  opacity: 1;
  visibility: visible;
  width: 100%;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li.active > a {
  background: transparent !important;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item[item-border=true] > li > a {
  border-bottom-width: 0 !important;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[pcoded-navbar-position=absolute] {
  position: absolute;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[pcoded-navbar-position=fixed] {
  position: fixed;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-inner-navbar {
  margin-top: 40px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-main-container {
  display: block;
  position: unset;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li > a {
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
  opacity: 1;
  position: relative;
  visibility: visible;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
  display: none;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-navigatio-lavel {
  display: none !important;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li.active > a:before, .pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li a .pcoded-badge {
  display: none;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li:hover > a {
  background: transparent !important;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li:hover > a:before {
  display: none;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li.active > a > .pcoded-mcaret {
  display: none;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li > a {
  padding: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .sidebar_toggle {
  height: 30px;
}

.pcoded-navbar .sidebar_toggle a {
  opacity: 1;
  visibility: visible;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  position: absolute;
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .sidebar_toggle a {
  color: #f1f1f1;
  float: right;
  font-size: 36px;
  opacity: 1;
  position: relative;
  right: 10px;
  text-decoration: none;
  top: 0;
  transition: opacity 0.8s linear 0s;
  -webkit-transition: opacity 0.8s linear 0s;
  -ms-transition: opacity 0.8s linear 0s;
  -moz-transition: opacity 0.8s linear 0s;
  -o-transition: opacity 0.8s linear 0s;
  visibility: visible;
  transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
}

.pcoded-navbar .sidebar_toggle a:hover {
  color: #fff;
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a:after {
  display: none;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item > li.pcoded-trigger > a {
  background: transparent !important;
  border-bottom-color: transparent !important;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
  background: transparent !important;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item[subitem-border=true] .pcoded-hasmenu .pcoded-submenu li > a {
  border-bottom-width: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
  display: none;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a {
  background: transparent !important;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
  border-left-width: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-submenu > li.active > a {
  background: transparent !important;
  color: #FFF !important;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.nocollapsed .pcoded-left-header {
  width: 100% !important;
  padding-left: 270px;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-nav-type=collapsed] .pcoded-header.nocollapsed .pcoded-left-header {
  padding-left: 45px;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-effect=overlay] .pcoded-header.nocollapsed .pcoded-left-header, .pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-nav-type=fullpage] .pcoded-header.nocollapsed .pcoded-left-header, .pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-nav-type=offcanvas] .pcoded-header.nocollapsed .pcoded-left-header {
  padding-left: 0;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.nocollapsed {
  height: 100px !important;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.nocollapsed .pcoded-right-header {
  padding: 50px 5px 0 !important;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-effect=overlay] .pcoded-header.nocollapsed .pcoded-right-header, .pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-nav-type=offcanvas] .pcoded-header.nocollapsed .pcoded-right-header {
  margin: 0 !important;
}
.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.nocollapsed .sidebar_toggle a {
  background: transparent none repeat scroll 0 0;
  border-radius: 4px;
  color: #fff;
  float: left;
  font-size: 18px;
  height: 35px;
  margin-right: 5px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 6px;
  width: 40px;
  z-index: 1028;
}
.pcoded.nocollapsed[theme-layout=vertical][pcoded-device-type=phone] .pcoded-navbar {
  top: 0;
  z-index: 1030;
}
.pcoded.nocollapsed[theme-layout=vertical][pcoded-device-type=phone] .pcoded-main-container {
  position: unset;
}
.pcoded.nocollapsed[theme-layout=vertical][pcoded-device-type=phone] #styleSelector {
  top: 100px;
}
.pcoded .pcoded-navbar .pcoded-navigatio-lavel {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  opacity: 1;
  padding: 20px 20px 10px;
  text-transform: capitalize;
  visibility: visible;
  width: 100%;
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  border-bottom: 2px solid;
}
.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme1] {
  color: #8c8c8c !important;
}
.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme2] {
  color: #624e89 !important;
}
.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme3] {
  color: #3c4fb1 !important;
}
.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme4] {
  color: #919aa3 !important;
}
.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme5] {
  color: #999 !important;
}
.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme6] {
  color: #fff !important;
}
.pcoded[vertical-nav-type=collapsed] .pcoded-navbar .pcoded-navigatio-lavel {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: rotateX(90deg);
  -webkit-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -o-transform: rotateX(90deg);
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
}
.pcoded[vertical-nav-type=compact] .pcoded-navbar .pcoded-navigatio-lavel {
  display: none;
}
.pcoded .pcoded-navbar .pcoded-item li a .pcoded-badge {
  position: absolute;
  right: 30px;
  text-align: center;
  top: 18px;
  vertical-align: middle;
  white-space: nowrap;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s linear;
}
.pcoded .pcoded-navbar .pcoded-item .pcoded-submenu > li a .pcoded-badge {
  top: 10px;
  right: 2px;
}
.pcoded[vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > li > a .pcoded-badge {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
}
.pcoded[vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > li.pcoded-trigger > a .pcoded-badge {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item > li.active > a:before {
  left: auto;
  right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item > li.active > a > .pcoded-mcaret {
  background: transparent none repeat scroll 0 0;
  border-bottom: 0 solid transparent;
  border-left: 0 solid #e9e9e9;
  border-top: 0 solid transparent;
  left: 0;
  right: auto;
  top: 4px;
  border-right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu > a:after {
  left: 8px;
  right: auto;
  top: -1px;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item > li > a .pcoded-mtext {
  left: auto;
  right: 0;
  padding-right: 60px;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item li a .pcoded-badge {
  position: relative;
  right: 30px;
  top: -5px;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item > li:hover > a:before {
  left: auto;
  right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
  left: auto;
  right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
  text-align: right;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
  float: right;
  left: 6px;
  top: 3px;
  padding-right: 10px;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item li .pcoded-submenu li > a .pcoded-badge {
  left: 8px;
  position: absolute;
  right: auto;
  top: 11px;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-navigatio-lavel {
  text-align: right;
  padding: 10px 40px 2px 20px;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-navigatio-lavel:before {
  left: auto;
  right: 20px;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon=style1] > a:after {
  content: "";
  /*font-family: 'icofont';*/
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon=style1] > a:after {
  content: "";
  font-family: "icofont";
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][pcoded-device-type=desktop] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: auto;
  right: 100%;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu, .pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][pcoded-device-type=tablet] .pcoded-navbar.is-hover .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: auto;
  right: 100%;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu > a:after {
  left: 7px;
  right: auto;
  top: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > li.pcoded-trigger {
  left: auto;
  right: 210px;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  left: auto;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item li a .pcoded-badge {
  position: absolute;
  right: auto;
  top: 10px;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-header .pcoded-right-header .pcoded-rl-header {
  float: right;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-header .pcoded-right-header .pcoded-rr-header {
  float: left;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-header .sidebar_toggle {
  float: right;
}
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed] .pcoded-search.open .pcoded-search-box {
  right: 0;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
  text-align: right;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
  padding-left: 10px;
  transform: rotate(180deg);
}

body[themebg-pattern=pattern1] {
  background-image: url("assets/images/pattern1.png");
}
body[themebg-pattern=pattern2] {
  background-image: url("assets/images/pattern2.png");
}
body[themebg-pattern=pattern3] {
  background-image: url("assets/images/pattern3.png");
}
body[themebg-pattern=pattern4] {
  background-image: url("assets/images/pattern4.png");
}
body[themebg-pattern=pattern5] {
  background-image: url("assets/images/pattern5.png");
}
body[themebg-pattern=pattern6] {
  background-image: url("assets/images/pattern6.png");
}
body[themebg-pattern=pattern7] {
  background-image: url("assets/images/pattern7.png");
}
body[themebg-pattern=pattern8] {
  background-image: url("assets/images/pattern8.png");
}
body[themebg-pattern=pattern9] {
  background-image: url("assets/images/pattern9.png");
}

.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #FE8A7D !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #FE8A7D;
}
.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item > li.active > a:after {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme1] .searchbar-toggle {
  color: #FE8A7D;
}
.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #FC6180 !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #FC6180;
}
.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item > li.active > a:after {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme2] .searchbar-toggle {
  color: #FC6180;
}
.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #93BE52 !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #93BE52;
}
.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item > li.active > a:after {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme3] .searchbar-toggle {
  color: #93BE52;
}
.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #4680ff !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #4680ff;
}
.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme4] .searchbar-toggle {
  color: #4680ff;
}
.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #FFB64D !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #FFB64D;
}
.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item > li.active > a:after {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme5] .searchbar-toggle {
  color: #FFB64D;
}
.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #ff5e3a !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #ff5e3a;
}
.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item > li.active > a:after {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme6] .searchbar-toggle {
  color: #ff5e3a;
}
.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #e64056 !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #e64056;
}
.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item > li.active > a:after {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme7] .searchbar-toggle {
  color: #e64056;
}
.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #3c4fb1 !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #3c4fb1;
}
.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item > li.active > a:after {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme8] .searchbar-toggle {
  color: #3c4fb1;
}
.pcoded .pcoded-navbar[active-item-theme=theme9] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme9] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #FF5370 !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme9] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme9] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme9] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #FF5370;
}
.pcoded .pcoded-navbar[active-item-theme=theme9] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme9] .pcoded-item > li.active > a:after {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme9] .searchbar-toggle {
  color: #FF5370;
}
.pcoded .pcoded-navbar[active-item-theme=theme10] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme10] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #F76D47 !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme10] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme10] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme10] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #F76D47;
}
.pcoded .pcoded-navbar[active-item-theme=theme10] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme10] .pcoded-item > li.active > a:after {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme10] .searchbar-toggle {
  color: #F76D47;
}
.pcoded .pcoded-navbar[active-item-theme=theme11] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme11] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #39ADB5 !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme11] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme11] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme11] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #39ADB5;
}
.pcoded .pcoded-navbar[active-item-theme=theme11] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme11] .pcoded-item > li.active > a:after {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme11] .searchbar-toggle {
  color: #39ADB5;
}
.pcoded .pcoded-navbar[active-item-theme=theme12] .pcoded-item li .pcoded-submenu li.active > a, .pcoded .pcoded-navbar[active-item-theme=theme12] .pcoded-item li .pcoded-submenu li:hover > a {
  color: #7C4DFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme12] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[active-item-theme=theme12] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme12] .pcoded-item li:hover > a {
  color: #fff !important;
  background: #7C4DFF;
}
.pcoded .pcoded-navbar[active-item-theme=theme12] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme12] .pcoded-item > li.active > a:after {
  color: #FFF !important;
}
.pcoded .pcoded-navbar[active-item-theme=theme12] .searchbar-toggle {
  color: #7C4DFF;
}
.pcoded .pcoded-navbar .pcoded-header[header-theme=theme1] {
  color: #fff;
}
.pcoded .pcoded-navbar .pcoded-header[header-theme=theme1] .sidebar_toggle a {
  background: transparent;
  color: #fff;
  border-color: #fff;
}
.pcoded .pcoded-navbar .pcoded-header.nocollapsed .pcoded-left-header[lheader-theme=theme1] {
  background: transparent;
}
.pcoded .pcoded-navbar .pcoded-header.iscollapsed .pcoded-left-header[lheader-theme=theme1] {
  background: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] {
  background: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .main-menu {
  background-color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .main-menu .main-menu-header {
  background-color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .main-menu .main-menu-header .user-details span {
  color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .main-menu .main-menu-content .more-details a {
  color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li > a {
  color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.active > a {
  background: #303548;
  color: #FFF;
  border-bottom-color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.active > a:before {
  border-left-color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li > a {
  border-bottom-color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
  background: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item li.pcoded-hasmenu .pcoded-submenu li > a {
  border-bottom-color: #444c67;
  color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.pcoded-hasmenu > a:after, .pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu > a:after {
  color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a:after, .pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger > a:after {
  color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.active:hover > a, .pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li:hover > a {
  background: #303548;
  color: #FFF;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a {
  background-color: #303548;
  color: #FFF;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
  border-left-color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a {
  color: #FFF;
  background-color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a .pcoded-mtext:before {
  border-bottom-color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a .pcoded-mtext:after {
  border-left-color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
  border-bottom-color: #ffff;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:after {
  border-left-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li:hover > a:before {
  border-left-color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.pcoded-hasmenu:hover > a:after {
  color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.pcoded-trigger > a {
  background: #303548;
  border-bottom-color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.pcoded-trigger.active > a {
  background: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover > a:after {
  color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.pcoded-hasmenu.pcoded-trigger.active > a:after {
  color: #303548;
}
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-navigatio-lavel {
  color: #303548;
  border-bottom-color: transparent;
}
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar[navbar-theme=theme1] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu, .pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-navbar[navbar-theme=theme1] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-color: transparent;
}
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme1] .pcoded-item li.pcoded-trigger > a > .pcoded-mcaret {
  border-right-color: #303548 !important;
}
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme1] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-color: transparent;
}
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme1] {
  background-color: #303548;
}
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li:hover > a {
  color: #FFF;
}
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme1] .sidebar_toggle a {
  color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] {
  background: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item li .pcoded-submenu li.active > a {
  font-weight: 600;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item li.pcoded-hasmenu:hover > a {
  color: #000 !important;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item li:hover > a {
  color: #000 !important;
  background: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item li:hover > a:before {
  border-left-color: transparent !important;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .main-menu {
  background-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .main-menu .main-menu-header {
  background-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .main-menu .main-menu-header .user-details span {
  color: #000;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .main-menu .main-menu-content .more-details a {
  color: #000;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
  background: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item li.pcoded-hasmenu .pcoded-submenu li > a {
  border-bottom-color: #e6e6e6;
  color: #000;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.pcoded-hasmenu > a:after, .pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu > a:after {
  color: #000;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a:after, .pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger > a:after {
  color: #000;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.active:hover > a, .pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li:hover > a {
  background: #fff;
  color: #000;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a {
  background-color: #fff;
  color: #000;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover > a:before {
  border-left-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a {
  color: #000;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a .pcoded-mtext:before {
  border-bottom-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active > a .pcoded-mtext:after {
  border-left-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:before {
  border-bottom-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a .pcoded-mtext:after {
  border-left-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li:hover > a:before {
  border-left-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.pcoded-hasmenu:hover > a:after {
  color: #000;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.pcoded-trigger > a {
  background: #fff;
  border-bottom-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.pcoded-trigger.active > a {
  background: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover > a:after {
  color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li > a {
  color: #000 !important;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.active > a {
  background: #fff !important;
  color: #FFF;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.active > a:before {
  border-left-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li > a {
  border-bottom-color: #fff;
}
.pcoded .pcoded-navbar[navbar-theme=themelight1] .pcoded-navigatio-lavel {
  color: #fff;
  border-bottom-color: transparent;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li > a {
  border-bottom-color: #e6e6e6;
}
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li > a {
  border-bottom-color: #444c67;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-color: #ededed;
  box-shadow: 3px 2px 11px -2px rgba(0, 0, 0, 0.13);
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar[navbar-theme=themelight1] .pcoded-item > li.pcoded-trigger > a {
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar[navbar-theme=theme1] .pcoded-item > .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  border-left-color: #3e455d;
  box-shadow: 3px 2px 11px -2px rgba(0, 0, 0, 0.13);
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar[navbar-theme=theme1] .pcoded-item > li.pcoded-trigger > a {
  border-bottom: 1px solid #444c67;
  border-top: 1px solid #444c67;
}
.pcoded .pcoded-header .navbar-logo[logo-theme=theme1] {
  background-color: #303549;
}
.pcoded .pcoded-header .navbar-logo[logo-theme=theme2] {
  background-color: #ef5350;
}
.pcoded .pcoded-header .navbar-logo[logo-theme=theme3] {
  background-color: #00bcd4;
}
.pcoded .pcoded-header .navbar-logo[logo-theme=theme4] {
  background-color: #66bb6a;
}
.pcoded .pcoded-header .navbar-logo[logo-theme=theme5] {
  background-color: #4680ff;
}
.pcoded .pcoded-header .navbar-logo[logo-theme=theme6] {
  background-color: #303548;
}
.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=desktop] .pcoded-navbar, .pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=tablet] .pcoded-navbar {
  margin-top: 10px;
  border-radius: 4px 4px 0 0;
}
.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=desktop] .pcoded-navbar .profile-box, .pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=tablet] .pcoded-navbar .profile-box {
  border-radius: 4px 4px 0 0;
}
.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=desktop] .pcoded-header, .pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=desktop] .pcoded-main-container {
  padding-left: 10px;
}
.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=tablet] .pcoded-header, .pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=tablet] .pcoded-main-container {
  padding-left: 10px;
}
.pcoded[theme-layout=vertical][vnavigation-view=view2][vertical-layout=widebox][pcoded-device-type=desktop] .pcoded-header .pcoded-wrapper, .pcoded[theme-layout=vertical][vnavigation-view=view2][vertical-layout=widebox][pcoded-device-type=tablet] .pcoded-header .pcoded-wrapper {
  padding-left: 5px;
}
.pcoded[theme-layout=vertical][vnavigation-view=view3] .pcoded-navbar {
  top: 0;
}
.pcoded[theme-layout=vertical][vnavigation-view=view3] .pcoded-main-container {
  position: unset;
}
.pcoded[theme-layout=vertical][vnavigation-view=view3] .pcoded-header .pcoded-left-header {
  display: none;
}
.pcoded #styleSelector {
  min-height: 300px;
  height: calc(100vh - 80px);
  border: 1px solid #e9e9e9;
  background: #FFF;
  position: fixed;
  margin: 0;
  padding: 20px;
  width: 400px;
  top: 80px;
  z-index: 100;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -o-transition: 0.5s;
}
.pcoded #styleSelector.open {
  background: #FFF none repeat scroll 0 0;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.pcoded #styleSelector a > i {
  padding: 0;
  margin: 0;
  margin-right: 5px;
}
.pcoded #styleSelector a.soc-icon {
  width: 55px;
  height: 55px;
  padding: 15px;
  border-radius: 50%;
}
.pcoded #styleSelector .soc-icon i {
  font-size: 25px;
  margin-right: 0px;
}
.pcoded[theme-layout=horizontal] #styleSelector, .pcoded[vertical-placement=left] #styleSelector {
  right: -400px;
  border-left: 2px solid rgba(48, 53, 73, 0.28);
}
.pcoded[theme-layout=horizontal] #styleSelector.open, .pcoded[vertical-placement=left] #styleSelector.open {
  right: 0;
}
.pcoded[vertical-placement=right] #styleSelector {
  left: -400px;
  border-right: 2px solid rgba(48, 53, 73, 0.28);
}
.pcoded[vertical-placement=right] #styleSelector.open {
  left: 0;
}

@media only screen and (max-width: 992px) {
  .pcoded #styleSelector {
    display: none;
  }
}
.selector-toggle {
  position: relative;
}

.pcoded .selector-toggle > a {
  position: absolute;
  top: 200px;
  width: 60px;
  height: 60px;
  display: block;
  cursor: pointer;
  text-align: center;
  background: #4680ff;
  color: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 14px 8px;
}
.pcoded[theme-layout=horizontal] .selector-toggle > a, .pcoded[vertical-placement=left] .selector-toggle > a {
  left: -80px;
  border-right: 0;
  border-radius: 7px 0 0 7px;
}
.pcoded[vertical-placement=right] .selector-toggle > a {
  right: -80px;
  border-left: 0;
  border-radius: 0 7px 7px 0;
}

.selector-toggle > a:before {
  font-family: "themify";
  font-size: 20px;
  content: "";
  position: relative;
  float: left;
  left: 13px;
  line-height: 35px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-animation: mymove 1.3s infinite linear;
  animation: mymove 1.3s infinite linear;
}

.open .selector-toggle > a:before {
  font-family: "themify";
  font-size: 20px;
  content: "";
  position: relative;
  float: left;
  left: 13px;
  line-height: 35px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-animation: mymove 0s infinite linear;
  animation: mymove 0s infinite linear;
}

#styleSelector .nav-tabs {
  margin-bottom: 20px;
  border-bottom: 2px solid #4680ff;
  padding-bottom: 5px;
}
#styleSelector .nav-tabs .nav-item {
  width: calc(100% / 2);
  margin: 0;
  padding: 0;
  background-color: transparent;
  float: left;
}
#styleSelector .nav-tabs .nav-item a {
  color: #303548;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  background-color: transparent;
  padding: 0.66rem 1rem;
  border-radius: 2px;
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#styleSelector .nav-tabs .nav-item a.active {
  color: #fff;
  background-color: #4680ff;
}
#styleSelector .style-cont {
  background-color: #F6F7FB;
  padding: 10px;
}
#styleSelector .st-main-title {
  font-size: 18px;
}
#styleSelector .btn-success {
  background-color: #11c15b;
}
#styleSelector .checkbox-fade label {
  margin-bottom: 0.8rem;
}
#styleSelector .sub-title {
  text-transform: capitalize;
}
#styleSelector #menu-effect {
  padding-bottom: 15px;
}
#styleSelector #menu-effect .radio > label {
  padding-left: 0rem;
}
#styleSelector #menu-effect .radio .helper {
  bottom: -30px;
  left: 3px;
}
#styleSelector #menu-effect .radio .helper:after, #styleSelector #menu-effect .radio .helper:before {
  top: auto;
  bottom: 0px;
}
#styleSelector #menu-effect .micon {
  font-size: 15px;
  padding: 4px;
  border-radius: 4px;
  width: 30px;
  display: block;
  height: 30px;
  text-align: center;
}
#styleSelector #menu-effect .micon.st1 i, #styleSelector #menu-effect .micon.st3 i, #styleSelector #menu-effect .micon.st2 b, #styleSelector #menu-effect .micon.st5 b, #styleSelector #menu-effect .micon.st4 b {
  display: none;
}
#styleSelector #menu-effect .micon.st1 {
  background-color: #4680ff;
}
#styleSelector #menu-effect .micon.st1 b, #styleSelector #menu-effect .micon.st1 i {
  color: #fff;
}
#styleSelector #menu-effect .micon.st2 {
  background-color: #93BE52;
}
#styleSelector #menu-effect .micon.st2 b, #styleSelector #menu-effect .micon.st2 i {
  color: #fff;
}
#styleSelector #menu-effect .micon.st3 {
  background-color: #ffd59a;
}
#styleSelector #menu-effect .micon.st3 b, #styleSelector #menu-effect .micon.st3 i {
  color: #e68800;
}
#styleSelector #menu-effect .micon.st4 {
  background-color: #fdacbc;
}
#styleSelector #menu-effect .micon.st4 b, #styleSelector #menu-effect .micon.st4 i {
  color: #f20534;
}
#styleSelector #menu-effect .micon.st5 {
  background-color: transparent;
}
#styleSelector #menu-effect .micon.st5 i {
  color: #4680ff;
}
#styleSelector #bg-pattern-visiblity {
  padding-left: 30px;
}
#styleSelector .form-control {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f1f1f1;
  margin-bottom: 10px;
}
#styleSelector .form-control:focus {
  border: none;
  background-color: #f1f1f1;
}
#styleSelector ul {
  border: 0 none;
  margin: 0;
  padding: 0;
  width: 100%;
}
#styleSelector ul li {
  border-bottom: 0 solid #e9e9e9;
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}
#styleSelector ul li p.selector-title, #styleSelector ul li span.selector-title {
  color: #444;
  font-size: 14px;
  margin: 0 0 15px;
  padding: 10px 0;
  text-align: left;
}
#styleSelector ul li p.selector-title {
  font-weight: 600;
  border-bottom: 1px solid #e9e9e9;
}
#styleSelector ul li p.selector-title.main-title {
  font-weight: 600;
}
#styleSelector ul li p.sub-title.drp-title {
  border-bottom: 0 solid transparent;
  margin-bottom: 0;
  margin-top: 10px;
}
#styleSelector > ul > li > .sub-title {
  color: #9c9c9c;
  display: block;
  font-size: 13px;
  margin: 0;
  padding: 5px 0;
  position: relative;
  text-align: left;
}
#styleSelector li {
  padding: 5px;
}

.pcoded #styleSelector .soc-icon {
  width: 55px;
  height: 55px;
  padding: 15px;
  border-radius: 50%;
}

.theme-option select {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 3px 10px;
}

@-moz-document url-prefix() {
  .theme-option select {
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 2px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 3px 10px;
    margin: 0;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .theme-option select.minimal {
    background-image: -webkit-linear-gradient(45deg, transparent 50%, gray 50%), -webkit-linear-gradient(315deg, gray 50%, transparent 50%), -webkit-linear-gradient(left, #ccc, #ccc);
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 18px) calc(1em + -1px), calc(100% - 13px) calc(1em + -1px), calc(100% - 2.5em) 4px;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }
  .theme-option select.minimal:active, .theme-option select.minimal:focus {
    background-image: -webkit-linear-gradient(45deg, gray 50%, transparent 50%), -webkit-linear-gradient(315deg, transparent 50%, gray 50%), -webkit-linear-gradient(left, gray, gray);
    background-image: linear-gradient(45deg, gray 50%, transparent 50%), linear-gradient(135deg, transparent 50%, gray 50%), linear-gradient(to right, gray, gray);
    background-position: calc(100% - 15px) 13px, calc(100% - 20px) 13px, calc(100% - 2.5em) 0.3em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: #66afe9;
    outline: 0;
  }
}
.theme-color {
  padding: 0;
  width: 100%;
}
.theme-color a {
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin: 5px 4px 5px 0;
  -webkit-box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
  overflow: hidden;
  width: 45px;
  height: 40px;
}
.theme-color a:focus, .theme-color a:hover {
  text-decoration: none;
}
.theme-color a.small {
  width: 20px;
  height: 20px;
}
.theme-color a .cont, .theme-color a .head {
  display: block;
  position: relative;
}
.theme-color a .cont:after, .theme-color a .cont:before {
  content: "";
  height: 100%;
  display: inline-block;
  position: absolute;
}
.theme-color a .head:after, .theme-color a .head:before {
  content: "";
  height: 100%;
  display: inline-block;
  position: absolute;
}
.theme-color a .cont:after, .theme-color a .head:after {
  width: 40%;
  left: 0;
}
.theme-color a .cont:before {
  width: 60%;
  right: 0;
}
.theme-color a .head {
  height: 10px;
}
.theme-color a .head:before {
  width: 60%;
  right: 0;
  background: #fff;
}
.theme-color a .cont {
  height: 40px;
}
.theme-color a .cont:before {
  background: #F6F7FB;
}
.theme-color a[lheader-theme=theme1] {
  background: #8c8c8c;
}
.theme-color a[lheader-theme=theme2] {
  background: #624e89;
}
.theme-color a[lheader-theme=theme3] {
  background: #3c4fb1;
}
.theme-color a[lheader-theme=theme4] {
  background: #919aa3;
}
.theme-color a[lheader-theme=theme5] {
  background: #999;
}
.theme-color a[lheader-theme=theme6] {
  background: #fff;
}
.theme-color a[header-theme=theme1] .head:after {
  background-color: #303549;
}
.theme-color a[header-theme=theme1] .head:before {
  background-color: #fff;
}
.theme-color a[header-theme=theme2] .head:after, .theme-color a[header-theme=theme2] .head:before {
  background: #ef5350;
}
.theme-color a[header-theme=theme3] .head:after, .theme-color a[header-theme=theme3] .head:before {
  background: #00bcd4;
}
.theme-color a[header-theme=theme4] .head:after, .theme-color a[header-theme=theme4] .head:before {
  background: #66bb6a;
}
.theme-color a[header-theme=theme5] .head:after, .theme-color a[header-theme=theme5] .head:before {
  background: #4680ff;
}
.theme-color a[header-theme=theme6] .head:after, .theme-color a[header-theme=theme6] .head:before {
  background: #303548;
}
.theme-color a[navbar-theme=theme1] {
  background: #303548;
}
.theme-color a[logo-theme=theme1] .head:after {
  background: #303549;
}
.theme-color a[logo-theme=theme2] .head:after {
  background: #ef5350;
}
.theme-color a[logo-theme=theme3] .head:after {
  background: #00bcd4;
}
.theme-color a[logo-theme=theme4] .head:after {
  background: #66bb6a;
}
.theme-color a[logo-theme=theme5] .head:after {
  background: #4680ff;
}
.theme-color a[logo-theme=theme6] .head:after {
  background: #303548;
}
.theme-color a[navbar-theme=themelight1] {
  background: #fff;
}
.theme-color a[active-item-theme=theme1] {
  background: #FE8A7D;
}
.theme-color a[active-item-theme=theme2] {
  background: #FC6180;
}
.theme-color a[active-item-theme=theme3] {
  background: #93BE52;
}
.theme-color a[active-item-theme=theme4] {
  background: #4680ff;
}
.theme-color a[active-item-theme=theme5] {
  background: #FFB64D;
}
.theme-color a[active-item-theme=theme6] {
  background: #ff5e3a;
}
.theme-color a[active-item-theme=theme7] {
  background: #e64056;
}
.theme-color a[active-item-theme=theme8] {
  background: #3c4fb1;
}
.theme-color a[active-item-theme=theme9] {
  background: #FF5370;
}
.theme-color a[active-item-theme=theme10] {
  background: #F76D47;
}
.theme-color a[active-item-theme=theme11] {
  background: #39ADB5;
}
.theme-color a[active-item-theme=theme12] {
  background: #7C4DFF;
}
.theme-color a[fream-type="1"] .cont:after, .theme-color a[fream-type="1"] .head:after {
  background: #000;
}
.theme-color a[fream-type="2"] .cont:after, .theme-color a[fream-type="2"] .head:after {
  background: #16D39A;
}
.theme-color a[fream-type="3"] .cont:after, .theme-color a[fream-type="3"] .head:after {
  background: #FF7588;
}
.theme-color a[fream-type="4"] .cont:after, .theme-color a[fream-type="4"] .head:after {
  background: #303548;
}
.theme-color a[fream-type="5"] .cont:after, .theme-color a[fream-type="5"] .head:after {
  background: #2196F3;
}
.theme-color a[fream-type="6"] .cont:after, .theme-color a[fream-type="6"] .head:after {
  background: #E91E63;
}
.theme-color a[sub-item-theme=theme1] {
  background: #70ca63;
}
.theme-color a[sub-item-theme=theme2] {
  background: #3498DB;
}
.theme-color a[sub-item-theme=theme3] {
  background: #485BBD;
}
.theme-color a[sub-item-theme=theme4] {
  background: #e7604a;
}
.theme-color a[sub-item-theme=theme5] {
  background: #333843;
}
.theme-color a[sub-item-theme=theme6] {
  background: #624e89;
}
.theme-color a[sub-item-theme=theme7] {
  background: #FFF;
}
.theme-color a[sub-item-theme=theme8] {
  background: #384b5f;
}
.theme-color a[sub-item-theme=theme9] {
  background: #578ebe;
}
.theme-color a[layout-type=dark] .cont:after {
  background: #303548;
}
.theme-color a[layout-type=dark] .cont:before {
  background: #444c67;
}
.theme-color a[layout-type=dark] .head:after {
  background: #444c67;
}
.theme-color a[layout-type=dark] .head:before {
  background: #303548;
}
.theme-color a[themebg-pattern=pattern1] {
  background-image: url("assets/images/pattern1.png");
}
.theme-color a[themebg-pattern=pattern2] {
  background-image: url("assets/images/pattern2.png");
}
.theme-color a[themebg-pattern=pattern3] {
  background-image: url("assets/images/pattern3.png");
}
.theme-color a[themebg-pattern=pattern4] {
  background-image: url("assets/images/pattern4.png");
}
.theme-color a[themebg-pattern=pattern5] {
  background-image: url("assets/images/pattern5.png");
}
.theme-color a[themebg-pattern=pattern6] {
  background-image: url("assets/images/pattern6.png");
}
.theme-color a[themebg-pattern=pattern7] {
  background-image: url("assets/images/pattern7.png");
}
.theme-color a[themebg-pattern=pattern8] {
  background-image: url("assets/images/pattern8.png");
}
.theme-color a[themebg-pattern=pattern9] {
  background-image: url("assets/images/pattern9.png");
}

.sparkline-chart {
  text-align: center;
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone] .spark-chart, .pcoded[theme-layout=vertical][pcoded-device-type=tablet] .spark-chart {
  display: none;
}

.spark-chart-title {
  text-align: center;
}

.content-title {
  margin-bottom: 10px;
}

.main-title {
  color: #424242;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
}

.small-text {
  font-size: 14px;
}

.breadcrumb {
  background-color: transparent;
  border-radius: 0;
  padding: 8px 0;
  margin-bottom: 10px;
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone] .breadcrumb {
  display: none;
}
.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item > li > a .pcoded-micon {
  float: none;
  position: relative;
  z-index: 1051;
  left: 90%;
  top: 1px;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed][vertical-placement=right] .pcoded-navbar .pcoded-item > li > a .pcoded-micon i {
  left: 0;
}
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed][vertical-placement=right] .pcoded-navbar .pcoded-item > li.pcoded-hasmenu.pcoded-trigger > a .pcoded-micon i, .pcoded[theme-layout=vertical][vertical-nav-type=collapsed][vertical-placement=right] .pcoded-navbar .pcoded-item > li.pcoded-trigger.active > a .pcoded-micon i {
  left: 94%;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item {
  position: static;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li, .pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item > li {
  position: static;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item {
  position: static;
}
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li, .pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item > li {
  position: static;
}
.pcoded.nocollapsed[theme-layout=vertical][pcoded-device-type=phone] .pcoded-main-container {
  position: static;
}
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-main-container, .pcoded[theme-layout=vertical][vnavigation-view=view3] .pcoded-main-container {
  position: static;
}
.pcoded .pcoded-header[header-theme=theme1] {
  background: #fff;
}
.pcoded .pcoded-header[header-theme=theme1] a {
  color: #303548;
}
.pcoded .pcoded-header[header-theme=theme1] .navbar-logo a {
  color: #fff;
}
.pcoded .pcoded-header[header-theme=theme2] {
  background: #ef5350;
}
.pcoded .pcoded-header[header-theme=theme2] a {
  color: #fff;
}
.pcoded .pcoded-header[header-theme=theme3] {
  background: #00bcd4;
}
.pcoded .pcoded-header[header-theme=theme3] a {
  color: #fff;
}
.pcoded .pcoded-header[header-theme=theme4] {
  background: #66bb6a;
}
.pcoded .pcoded-header[header-theme=theme4] a {
  color: #fff;
}
.pcoded .pcoded-header[header-theme=theme5] {
  background: #4680ff;
}
.pcoded .pcoded-header[header-theme=theme5] a {
  color: #fff;
}
.pcoded .pcoded-header[header-theme=theme6] {
  background: #303548;
}
.pcoded .pcoded-header[header-theme=theme6] a {
  color: #fff;
}

body .pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > li > a .pcoded-micon {
  left: 0;
}
body .pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item > li.pcoded-trigger > a .pcoded-micon {
  left: calc(100% - 30px);
}
body .pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed] .pcoded-item > li.pcoded-trigger > a > .pcoded-mtext {
  padding-right: 90px;
}
body .pcoded[vertical-placement=right] .showChat_inner, body .pcoded[vertical-placement=right] .users {
  right: auto;
  left: 0;
}
body .pcoded[vertical-placement=right] .main-body {
  direction: rtl;
}
body .pcoded[vertical-placement=right] .main-body .page-wrapper .page-header-breadcrumb {
  float: left;
}
body .pcoded[vertical-placement=right] .main-body .language-markup {
  direction: ltr;
}
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-logo {
  float: right !important;
}
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container {
  margin-left: 0;
  margin-right: 240px;
}
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .nav-left li, body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .nav-right li {
  float: right !important;
}
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .nav-left {
  float: right;
}
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .nav-right {
  float: right;
  float: left;
}
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification, body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification, body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
  right: auto;
  left: 0;
}
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification, body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification, body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
  right: auto;
  left: 0;
}
body .pcoded[vertical-placement=right][vertical-nav-type=collapsed] .header-navbar .navbar-wrapper .navbar-container {
  margin-right: 0;
}
body .pcoded[vertical-nav-type=collapsed] .header-navbar .navbar-wrapper .navbar-container {
  margin-left: 0;
}
body .pcoded[vertical-nav-type=collapsed] .header-navbar .navbar-logo {
  width: 80px;
}
body .pcoded[vertical-nav-type=collapsed] .header-navbar .navbar-logo a img {
  display: none;
}
body .pcoded[vertical-nav-type=compact] .main-menu {
  width: 200px;
}
body .pcoded[vertical-nav-type=compact] .main-menu .pcoded-badge {
  display: none;
}
body.menu-bottom .navbar-wrapper {
  z-index: 1050;
}
body.menu-bottom .pcoded .pcoded-main-container {
  margin-top: 0 !important;
}
body.menu-bottom .p-chat-user, body.menu-bottom .showChat_inner {
  top: 0;
}
body.menu-bottom .navbar-wrapper {
  bottom: 0;
  top: auto;
  z-index: 1050;
}
body.menu-bottom .header-navbar {
  position: fixed !important;
  bottom: 0;
  z-index: 9999 !important;
  -webkit-box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08) !important;
}
body.menu-bottom .mega-menu-top .show-notification, body.menu-bottom .mega-menu-top .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
  bottom: 0 !important;
}
body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
  bottom: 0 !important;
}

@media only screen and (max-width: 992px) {
  body.menu-bottom .navbar-wrapper .pcoded-navbar {
    top: -56px;
  }
}
.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .profile-notification {
  bottom: 0 !important;
}

body.menu-bottom .header-notification .show-notification, body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification {
  bottom: 65px;
}

.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .show-notification, body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, .header-navbar .navbar-wrapper .navbar-container body.menu-bottom .header-notification .profile-notification, body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification, .header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .profile-notification {
  bottom: 65px;
}

body.menu-bottom #collapse-menu {
  display: none;
}

.mCSB_container, .mCustomScrollBox {
  overflow: visible !important;
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(48, 53, 72, 0.5) !important;
}

@media only screen and (min-width: 992px) {
  .header-navbar .navbar-wrapper .navbar-container .nav-right {
    overflow: visible !important;
    height: auto !important;
  }
}
#pcoded[vertical-nav-type=collapsed] .scroll-sidebar, #pcoded[vertical-nav-type=collapsed] .slimScrollDiv {
  overflow: visible !important;
}

/**  =====================
      Theme-Buttons css start
==========================  **/
.btn-default .badge {
  background-color: #fff;
  color: #e0e0e0;
}

.btn {
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 15px;
  padding: 10px 19px;
  cursor: pointer;
}

.button-page .card-block {
  margin-bottom: -20px;
}
.button-page .card-block ul {
  margin-bottom: 0;
  display: inline-block;
}
.button-page .card-block a {
  margin-bottom: 20px;
}
.button-page .card-block ul li {
  display: inline-block;
  margin-right: 20px;
  float: left;
}
.button-page .btn {
  margin-bottom: 20px;
  margin-right: 10px;
}

.btn.btn-round {
  border-radius: 2rem;
}
.btn.btn-square {
  border-radius: 0;
}
.btn.btn-skew {
  transform: skew(-15deg);
}
.btn i {
  margin-right: 5px;
}
.btn.btn-icon {
  border-radius: 50%;
  width: 40px;
  line-height: 30px;
  height: 40px;
  padding: 3px;
  text-align: center;
}
.btn.btn-out {
  outline: 1px solid #fff;
  outline-offset: -5px;
}
.btn.btn-out-dashed {
  outline: 1px dashed #fff;
  outline-offset: -5px;
}
.btn.btn-out-dotted {
  outline: 1px dotted #fff;
  outline-offset: -5px;
}

.btn-group, .btn-group-vertical {
  display: inline-block;
}

.btn-group .btn {
  float: left;
}

.icon-btn i {
  margin-right: 0 !important;
}

.button-page .btn-group {
  margin-right: 10px;
}

.show > .dropdown-menu {
  overflow: hidden;
}

.dropdown-danger, .dropdown-default, .dropdown-disabled, .dropdown-info, .dropdown-inverse, .dropdown-primary, .dropdown-success, .dropdown-warning {
  display: inline-block;
  vertical-align: center;
  position: relative;
}

.btn-group [class*=btn-], .dropdown-split [class*=btn-] {
  margin-right: 0;
}

.list-group-item.active {
  background-color: #4680ff;
  border-color: #4680ff;
}

.show > .btn-primary.dropdown-toggle, .sweet-alert .show > button.dropdown-toggle.confirm, .wizard > .actions .show > a.dropdown-toggle {
  background-color: #0956ff !important;
  border-color: #0956ff;
  box-shadow: none;
  color: #fff;
}

#Note-list li:hover .Note-delete {
  opacity: 1;
  transform: translateX(0px);
}
#Note-list li:hover .Note-delete:hover {
  background: rgba(0, 0, 0, 0.8);
}

.button-list .btn {
  margin-bottom: 10px;
  margin-right: 30px;
}

.Note-delete {
  margin-bottom: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  position: absolute;
  right: 20px;
  color: #fff;
  transition: all ease-in 0.3s;
  border-radius: 2px;
  cursor: pointer;
  transform: translateX(10px);
}

.popover-body .color-code {
  margin-bottom: 10px;
}
.popover-body .display-color {
  width: 75px;
  height: 2.5rem;
  border-radius: 0.25rem;
  margin-right: 30px;
  background-color: rgba(70, 128, 255, 0.5);
  border-color: rgba(70, 128, 255, 0.5);
}
.popover-body span.block {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.popover-title {
  text-align: center;
}

.btn.btn-disabled, .dropdown-disabled, .dropdown-split-disabled {
  cursor: not-allowed;
}

.nav-pills .nav-link.active {
  background: transparent;
  color: #0275d8;
  text-align: center;
  border-bottom: 1px solid #0275d8;
}
.nav-pills .nav-link.active:focus, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus, .nav-pills .nav-link.active:hover {
  background: transparent;
  color: #0275d8;
  text-align: center;
  border-bottom: 1px solid #0275d8;
}
.nav-pills .nav-link.active.active {
  background: transparent;
  color: #0275d8;
  text-align: center;
  border-bottom: 1px solid #0275d8;
}
.nav-pills .nav-link.active.active:focus, .nav-pills .nav-link.active.active:hover {
  background: transparent;
  color: #0275d8;
  text-align: center;
  border-bottom: 1px solid #0275d8;
}

/**====== Basic-Buttons css Start ======**/
.btn-primary, .sweet-alert button.confirm, .wizard > .actions a {
  background-color: #4680ff;
  border-color: #4680ff;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;
}

.btn-primary:hover, .sweet-alert button.confirm:hover, .wizard > .actions a:hover {
  background-color: #79a3ff;
  border-color: #79a3ff;
}

.btn-primary:active, .sweet-alert button.confirm:active, .wizard > .actions a:active {
  background-color: #0956ff !important;
  border-color: #0956ff;
  box-shadow: none;
  color: #fff;
}

.btn-primary:focus, .sweet-alert button.confirm:focus, .wizard > .actions a:focus {
  box-shadow: none;
  color: #fff;
}

.btn-primary.disabled, .sweet-alert button.disabled.confirm, .wizard > .actions a.disabled {
  background-color: rgba(70, 128, 255, 0.5);
  border-color: rgba(70, 128, 255, 0.5);
}

.btn-warning {
  background-color: #FFB64D;
  border-color: #FFB64D;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;
}
.btn-warning:hover {
  background-color: #ffcb80;
  border-color: #ffcb80;
  color: #fff;
}
.btn-warning:active {
  background-color: #ff9d10 !important;
  border-color: #ff9d10;
  box-shadow: none;
  color: #fff;
}
.btn-warning:focus {
  box-shadow: none;
  color: #fff;
}
.btn-warning.disabled {
  background-color: rgba(255, 182, 77, 0.5);
  border-color: rgba(255, 182, 77, 0.5);
}

.btn-danger {
  background-color: #fa3b3b;
  border-color: #fa3b3b;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;
}
.btn-danger:hover {
  background-color: #fd93a8;
  border-color: #fd93a8;
}
.btn-danger:active {
  background-color: #fb2550 !important;
  border-color: #fb2550;
  box-shadow: none;
  color: #fff;
}
.btn-danger:focus {
  box-shadow: none;
  color: #fff;
}
.btn-danger.disabled {
  background-color: rgba(252, 97, 128, 0.5);
  border-color: rgba(252, 97, 128, 0.5);
}

.btn-success {
  background-color: #93BE52;
  border-color: #93BE52;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;
}
.btn-success:hover {
  background-color: #aacc77;
  border-color: #aacc77;
}
.btn-success:active {
  background-color: #73993a !important;
  border-color: #73993a;
  box-shadow: none;
  color: #fff;
}
.btn-success:focus {
  box-shadow: none;
  color: #fff;
}
.btn-success.disabled {
  background-color: rgba(147, 190, 82, 0.5);
  border-color: rgba(147, 190, 82, 0.5);
}

.btn-inverse {
  background-color: #303548;
  border-color: #303548;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;
}
.btn-inverse:hover {
  background-color: #444c67;
  border-color: #444c67;
}
.btn-inverse:active {
  background-color: #181a23 !important;
  border-color: #181a23;
  box-shadow: none;
  color: #fff;
}
.btn-inverse:focus {
  box-shadow: none;
  color: #fff;
}
.btn-inverse.disabled {
  background-color: rgba(48, 53, 72, 0.5);
  border-color: rgba(48, 53, 72, 0.5);
}

.btn-info {
  background-color: #62d1f3;
  border-color: #62d1f3;
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;
}
.btn-info:hover {
  background-color: #91dff7;
  border-color: #91dff7;
}
.btn-info:active {
  background-color: #29c0ef !important;
  border-color: #29c0ef;
  box-shadow: none;
  color: #fff;
}
.btn-info:focus {
  box-shadow: none;
  color: #fff;
}
.btn-info.disabled {
  background-color: rgba(98, 209, 243, 0.5);
  border-color: rgba(98, 209, 243, 0.5);
}

.btn-disabled {
  background-color: rgba(70, 128, 254, 0.5);
  border-color: rgba(70, 128, 254, 0.5);
  color: #fff;
  cursor: pointer;
  transition: all ease-in 0.3s;
}

/**====== Basic-Buttons css end ======**/
/**====== Outline-Buttons css Start ======**/
.btn-outline-primary {
  color: #0956ff;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ff9d10;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-danger {
  color: #fb2550;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-success {
  color: #73993a;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-inverse {
  color: #181a23;
  background-color: #fff;
  background-color: transparent;
}
.btn-outline-inverse:hover {
  color: #fff;
}

.btn-outline-warning {
  color: #ff9d10;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-info {
  color: #29c0ef;
  background-color: #fff;
  background-color: transparent;
}

.btn-outline-disabled {
  color: #0956fe;
  background-color: #fff;
}

/**====== Outline-Buttons css end ======**/
/**====== gradient css Start ======**/
.btn-grd-danger, .btn-grd-disabled, .btn-grd-info, .btn-grd-inverse, .btn-grd-primary, .btn-grd-success, .btn-grd-warning {
  background-size: 200% auto;
  transition: 0.5s ease-in-out;
  color: #fff;
}

.btn-grd-danger:hover, .btn-grd-disabled:hover, .btn-grd-info:hover, .btn-grd-inverse:hover, .btn-grd-primary:hover, .btn-grd-success:hover, .btn-grd-warning:hover {
  background-position: right center;
}

.btn-grd-danger.hor-grd, .btn-grd-disabled.hor-grd, .btn-grd-info.hor-grd, .btn-grd-inverse.hor-grd, .btn-grd-primary.hor-grd, .btn-grd-success.hor-grd, .btn-grd-warning.hor-grd {
  background-size: auto 200%;
}

.btn-grd-danger.hor-grd:hover, .btn-grd-disabled.hor-grd:hover, .btn-grd-info.hor-grd:hover, .btn-grd-inverse.hor-grd:hover, .btn-grd-primary.hor-grd:hover, .btn-grd-success.hor-grd:hover, .btn-grd-warning.hor-grd:hover {
  background-position: bottom center;
}

.btn-grd-primary {
  background-image: linear-gradient(to right, #79a3ff 0%, #0956ff 51%, #79a3ff 100%);
}
.btn-grd-primary.hor-grd {
  background-image: linear-gradient(to top, #79a3ff 0%, #0956ff 51%, #79a3ff 100%);
}

.btn-grd-warning {
  background-image: linear-gradient(to right, #ffcb80 0%, #ff9d10 51%, #ffcb80 100%);
}
.btn-grd-warning.hor-grd {
  background-image: linear-gradient(to top, #ffcb80 0%, #ff9d10 51%, #ffcb80 100%);
}

.btn-grd-danger {
  background-image: linear-gradient(to right, #fd93a8 0%, #fb2550 51%, #fd93a8 100%);
}
.btn-grd-danger.hor-grd {
  background-image: linear-gradient(to top, #fd93a8 0%, #fb2550 51%, #fd93a8 100%);
}

.btn-grd-success {
  background-image: linear-gradient(to right, #aacc77 0%, #73993a 51%, #aacc77 100%);
}
.btn-grd-success.hor-grd {
  background-image: linear-gradient(to top, #aacc77 0%, #73993a 51%, #aacc77 100%);
}

.btn-grd-inverse {
  background-image: linear-gradient(to right, #444c67 0%, #181a23 51%, #444c67 100%);
}
.btn-grd-inverse.hor-grd {
  background-image: linear-gradient(to top, #444c67 0%, #181a23 51%, #444c67 100%);
}

.btn-grd-info {
  background-image: linear-gradient(to right, #91dff7 0%, #29c0ef 51%, #91dff7 100%);
}
.btn-grd-info.hor-grd {
  background-image: linear-gradient(to top, #91dff7 0%, #29c0ef 51%, #91dff7 100%);
}

.btn-grd-disabled {
  background-image: linear-gradient(to right, #79a3fe 0%, #0956fe 51%, #79a3fe 100%);
}
.btn-grd-disabled.hor-grd {
  background-image: linear-gradient(to top, #79a3fe 0%, #0956fe 51%, #79a3fe 100%);
}

/**====== gradient-Buttons css end ======**/
/**====== matrialized-Buttons css Start ======**/
.btn.btn-mat {
  position: relative;
  border-radius: 0;
  border: none;
}
.btn.btn-mat:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  height: 3px;
  transition: all 0.3s ease-in-out;
}
.btn.btn-mat:hover {
  border: none;
}
.btn.btn-mat:hover:before {
  left: 0;
  right: 0;
}

.btn-primary.btn-mat, .sweet-alert button.btn-mat.confirm, .wizard > .actions a.btn-mat {
  background-color: #4680ff;
}

.btn-primary.btn-mat:before, .sweet-alert button.btn-mat.confirm:before, .wizard > .actions a.btn-mat:before {
  background-color: #0956ff;
}

.btn-warning.btn-mat {
  background-color: #FFB64D;
}
.btn-warning.btn-mat:before {
  background-color: #ff9d10;
}

.btn-danger.btn-mat {
  background-color: #FC6180;
}
.btn-danger.btn-mat:before {
  background-color: #fb2550;
}

.btn-success.btn-mat {
  background-color: #93BE52;
}
.btn-success.btn-mat:before {
  background-color: #73993a;
}

.btn-inverse.btn-mat {
  background-color: #303548;
}
.btn-inverse.btn-mat:before {
  background-color: #181a23;
}

.btn-info.btn-mat {
  background-color: #62d1f3;
}
.btn-info.btn-mat:before {
  background-color: #29c0ef;
}

.btn-outline-disabled {
  background-color: #4680FE;
}
.btn-outline-disabled:before {
  background-color: #0956fe;
}

/**====== matrialized css end ======**/
/**====== Button-Size css start ======**/
.btn-xlg {
  padding: 14px 20px;
  font-size: 21px;
  line-height: 40px;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 12px 18px;
  font-size: 18px;
  line-height: 32px;
}

.btn-md {
  padding: 10px 16px;
  font-size: 15px;
  line-height: 23px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 8px 14px;
  line-height: 16px;
  font-size: 11px;
}

.btn-mini {
  padding: 5px 10px;
  line-height: 14px;
  font-size: 10px;
}

/**====== Button-Size css end ======**/
/**====== Badges-button css start ======**/
.btn-primary .badge, .sweet-alert button.confirm .badge, .wizard > .actions a .badge {
  color: #4680ff;
  background-color: #fff;
}

.btn-success .badge {
  color: #93BE52;
  background-color: #fff;
}

.btn-info .badge {
  color: #62d1f3;
  background-color: #fff;
}

.btn-warning .badge {
  color: #FFB64D;
  background-color: #fff;
}

.btn-danger .badge {
  color: #FC6180;
  background-color: #fff;
}

/**====== Badges-button css end ======**/
/**====== Social-Buttons css start ======**/
.btn-dribbble, .btn-dropbox, .btn-facebook, .btn-flickr, .btn-github, .btn-google-plus, .btn-instagram, .btn-linkedin, .btn-pinterest, .btn-skype, .btn-tumblr, .btn-twitter, .btn-youtube {
  color: #fff;
}

.btn-dribbble:focus, .btn-dribbble:hover {
  color: #fff;
  box-shadow: none;
}

.btn-dropbox:focus, .btn-dropbox:hover {
  color: #fff;
  box-shadow: none;
}

.btn-facebook:focus, .btn-facebook:hover {
  color: #fff;
  box-shadow: none;
}

.btn-flickr:focus, .btn-flickr:hover {
  color: #fff;
  box-shadow: none;
}

.btn-github:focus, .btn-github:hover {
  color: #fff;
  box-shadow: none;
}

.btn-google-plus:focus, .btn-google-plus:hover {
  color: #fff;
  box-shadow: none;
}

.btn-instagram:focus, .btn-instagram:hover {
  color: #fff;
  box-shadow: none;
}

.btn-linkedin:focus, .btn-linkedin:hover {
  color: #fff;
  box-shadow: none;
}

.btn-pinterest:focus, .btn-pinterest:hover {
  color: #fff;
  box-shadow: none;
}

.btn-skype:focus, .btn-skype:hover {
  color: #fff;
  box-shadow: none;
}

.btn-tumblr:focus, .btn-tumblr:hover {
  color: #fff;
  box-shadow: none;
}

.btn-twitter:focus, .btn-twitter:hover {
  color: #fff;
  box-shadow: none;
}

.btn-youtube:focus, .btn-youtube:hover {
  color: #fff;
  box-shadow: none;
}

.btn-facebook {
  background-color: #3b5998;
}

.btn-twitter {
  background-color: #00ACED;
}

.btn-linkedin {
  background-color: #007BB6;
}

.btn-dribbble {
  background-color: #EA4C89;
}

.btn-google-plus {
  background-color: #DD4B39;
}

.btn-instagram {
  background-color: #517FA4;
}

.btn-pinterest {
  background-color: #CB2027;
}

.btn-dropbox {
  background-color: #32506D;
}

.btn-tumblr {
  background-color: #00ACED;
}

/**====== Social-Buttons css end ======**/
/**====== Drop-down-Buttons css start ======**/
.dropdown-primary .dropdown-menu a:hover, .dropdown-split-primary .dropdown-menu a:hover {
  background-color: #4680ff;
  color: #fff;
}

.dropdown-split-success .dropdown-menu a:hover, .dropdown-success .dropdown-menu a:hover {
  background-color: #93BE52;
  color: #fff;
}

.dropdown-info .dropdown-menu a:hover, .dropdown-split-info .dropdown-menu a:hover {
  background-color: #62d1f3;
  color: #fff;
}

.dropdown-split-warning .dropdown-menu a:hover, .dropdown-warning .dropdown-menu a:hover {
  background-color: #FFB64D;
  color: #fff;
}

.dropdown-danger .dropdown-menu a:hover, .dropdown-split-danger .dropdown-menu a:hover {
  background-color: #FC6180;
  color: #fff;
}

.dropdown-inverse .dropdown-item, .dropdown-split-inverse .dropdown-item {
  transition: all 0.3s ease-in;
}

.dropdown-inverse .dropdown-item:hover, .dropdown-split-inverse .dropdown-item:hover {
  background-color: #303548;
  color: #fff;
}

/**====== Drop-down-Buttons css end ======**/
/**====== Theme-Buttons css end ======**/
/**  =====================
      Basic form elements css start
==========================  **/
.col-form-label {
  font-size: 14px;
}

.select2-container {
  width: 100% !important;
}

.form-group {
  margin-bottom: 1.25em;
}
.form-group .form-control-lg {
  font-size: 1.25em;
}
.form-group .form-control-round {
  border-radius: 50px;
}
.form-group .form-control-static:focus {
  outline: none;
}

.form-control {
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #cccccc;
}
.form-control:focus {
  border: 1px solid #4680ff;
}
.form-control.form-control-normal {
  font-weight: 400;
}
.form-control.form-control-bold {
  font-weight: 600;
}
.form-control.form-control-capitalize {
  text-transform: capitalize;
}
.form-control.form-control-uppercase {
  text-transform: uppercase;
}
.form-control.form-control-lowercase {
  text-transform: lowercase;
}
.form-control.form-control-variant {
  font-variant: small-caps;
}
.form-control.form-control-left {
  text-align: left;
}
.form-control.form-control-center {
  text-align: center;
}
.form-control.form-control-right {
  text-align: right;
}
.form-control.form-control-rtl {
  direction: rtl;
}
.form-control:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/*===== Border Inputs start =====*/
.form-control-primary {
  border-color: #4680ff;
  color: #4680ff;
}
.form-control-primary:focus {
  border-color: #4680ff;
}

.input-group-primary .input-group-addon {
  background-color: #4680ff;
}
.input-group-primary .form-control {
  border-color: #4680ff;
}

.form-control-warning {
  border-color: #FFB64D;
  color: #FFB64D;
}
.form-control-warning:focus {
  border-color: #FFB64D;
}

.input-group-warning .input-group-addon {
  background-color: #FFB64D;
}
.input-group-warning .form-control {
  border-color: #FFB64D;
}

.form-control-default {
  border-color: #e0e0e0;
  color: #e0e0e0;
}
.form-control-default:focus {
  border-color: #e0e0e0;
}

.input-group-default .input-group-addon {
  background-color: #e0e0e0;
}
.input-group-default .form-control {
  border-color: #e0e0e0;
}

.form-control-danger {
  border-color: #FC6180;
  color: #FC6180;
}
.form-control-danger:focus {
  border-color: #FC6180;
}

.input-group-danger .input-group-addon {
  background-color: #FC6180;
}
.input-group-danger .form-control {
  border-color: #FC6180;
}

.form-control-success {
  border-color: #93BE52;
  color: #93BE52;
}
.form-control-success:focus {
  border-color: #93BE52;
}

.input-group-success .input-group-addon {
  background-color: #93BE52;
}
.input-group-success .form-control {
  border-color: #93BE52;
}

.form-control-inverse {
  border-color: #303548;
  color: #303548;
}
.form-control-inverse:focus {
  border-color: #303548;
}

.input-group-inverse .input-group-addon {
  background-color: #303548;
}
.input-group-inverse .form-control {
  border-color: #303548;
}

.form-control-info {
  border-color: #62d1f3;
  color: #62d1f3;
}
.form-control-info:focus {
  border-color: #62d1f3;
}

.input-group-info .input-group-addon {
  background-color: #62d1f3;
}
.input-group-info .form-control {
  border-color: #62d1f3;
}

/*===== Form Input-Text start =====*/
.form-txt-primary {
  color: #4680ff;
}
.form-txt-primary:focus {
  color: #4680ff;
}
.form-txt-primary::-moz-placeholder {
  color: #4680ff;
  opacity: 1;
}
.form-txt-primary:-ms-input-placeholder, .form-txt-primary::-webkit-input-placeholder {
  color: #4680ff;
}

.form-txt-warning {
  color: #FFB64D;
}
.form-txt-warning:focus {
  color: #FFB64D;
}
.form-txt-warning::-moz-placeholder {
  color: #FFB64D;
  opacity: 1;
}
.form-txt-warning:-ms-input-placeholder, .form-txt-warning::-webkit-input-placeholder {
  color: #FFB64D;
}

.form-txt-default {
  color: #e0e0e0;
}
.form-txt-default:focus {
  color: #e0e0e0;
}
.form-txt-default::-moz-placeholder {
  color: #e0e0e0;
  opacity: 1;
}
.form-txt-default:-ms-input-placeholder, .form-txt-default::-webkit-input-placeholder {
  color: #e0e0e0;
}

.form-txt-danger {
  color: #FC6180;
}
.form-txt-danger:focus {
  color: #FC6180;
}
.form-txt-danger::-moz-placeholder {
  color: #FC6180;
  opacity: 1;
}
.form-txt-danger:-ms-input-placeholder, .form-txt-danger::-webkit-input-placeholder {
  color: #FC6180;
}

.form-txt-success {
  color: #93BE52;
}
.form-txt-success:focus {
  color: #93BE52;
}
.form-txt-success::-moz-placeholder {
  color: #93BE52;
  opacity: 1;
}
.form-txt-success:-ms-input-placeholder, .form-txt-success::-webkit-input-placeholder {
  color: #93BE52;
}

.form-txt-inverse {
  color: #303548;
}
.form-txt-inverse:focus {
  color: #303548;
}
.form-txt-inverse::-moz-placeholder {
  color: #303548;
  opacity: 1;
}
.form-txt-inverse:-ms-input-placeholder, .form-txt-inverse::-webkit-input-placeholder {
  color: #303548;
}

.form-txt-info {
  color: #62d1f3;
}
.form-txt-info:focus {
  color: #62d1f3;
}
.form-txt-info::-moz-placeholder {
  color: #62d1f3;
  opacity: 1;
}
.form-txt-info:-ms-input-placeholder, .form-txt-info::-webkit-input-placeholder {
  color: #62d1f3;
}

/*===== Form Background-inputs start =====*/
.form-bg-primary {
  background-color: #4680ff;
  border-color: #4680ff;
  color: #fff;
}
.form-bg-primary:focus {
  background-color: #4680ff;
  border-color: #4680ff;
  color: #fff;
}
.form-bg-primary::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-bg-primary:-ms-input-placeholder, .form-bg-primary::-webkit-input-placeholder {
  color: #fff;
}

.form-bg-warning {
  background-color: #FFB64D;
  border-color: #FFB64D;
  color: #fff;
}
.form-bg-warning:focus {
  background-color: #FFB64D;
  border-color: #FFB64D;
  color: #fff;
}
.form-bg-warning::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-bg-warning:-ms-input-placeholder, .form-bg-warning::-webkit-input-placeholder {
  color: #fff;
}

.form-bg-default {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #fff;
}
.form-bg-default:focus {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  color: #fff;
}
.form-bg-default::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-bg-default:-ms-input-placeholder, .form-bg-default::-webkit-input-placeholder {
  color: #fff;
}

.form-bg-danger {
  background-color: #FC6180;
  border-color: #FC6180;
  color: #fff;
}
.form-bg-danger:focus {
  background-color: #FC6180;
  border-color: #FC6180;
  color: #fff;
}
.form-bg-danger::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-bg-danger:-ms-input-placeholder, .form-bg-danger::-webkit-input-placeholder {
  color: #fff;
}

.form-bg-success {
  background-color: #93BE52;
  border-color: #93BE52;
  color: #fff;
}
.form-bg-success:focus {
  background-color: #93BE52;
  border-color: #93BE52;
  color: #fff;
}
.form-bg-success::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-bg-success:-ms-input-placeholder, .form-bg-success::-webkit-input-placeholder {
  color: #fff;
}

.form-bg-inverse {
  background-color: #303548;
  border-color: #303548;
  color: #fff;
}
.form-bg-inverse:focus {
  background-color: #303548;
  border-color: #303548;
  color: #fff;
}
.form-bg-inverse::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-bg-inverse:-ms-input-placeholder, .form-bg-inverse::-webkit-input-placeholder {
  color: #fff;
}

.form-bg-info {
  background-color: #62d1f3;
  border-color: #62d1f3;
  color: #fff;
}
.form-bg-info:focus {
  background-color: #62d1f3;
  border-color: #62d1f3;
  color: #fff;
}
.form-bg-info::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-bg-info:-ms-input-placeholder, .form-bg-info::-webkit-input-placeholder {
  color: #fff;
}

/*===== Form Validation states start =====*/
.has-success .col-form-label {
  color: #93BE52;
}
.has-success .form-control-success {
  border-color: #93BE52;
}

.has-warning .col-form-label {
  color: #FFB64D;
}
.has-warning .form-control-success {
  border-color: #FFB64D;
}

.has-danger .col-form-label {
  color: #FC6180;
}
.has-danger .form-control-success {
  border-color: #FC6180;
}

/*===== Form-icon css starts =====*/
.left-icon-control {
  position: relative;
}
.left-icon-control input {
  padding-left: 50px;
}
.left-icon-control .form-icon {
  position: absolute;
  top: 50%;
  left: 17px;
  margin-top: -12px;
}

.right-icon-control {
  position: relative;
}
.right-icon-control input {
  padding-right: 50px;
}
.right-icon-control .form-icon {
  position: absolute;
  top: 7px;
  right: 17px;
}

/*===== Form-icon css ends =====*/
/*===== Basic form elements ends =====*/
/**  =====================
      Form group-add-on css start
==========================  **/
.input-group {
  margin-bottom: 1.25em;
}

.input-group-addon {
  background-color: #4680ff;
  color: #fff;
}

.input-group-button [class*=btn-] {
  margin-bottom: 0;
}
.input-group-button .input-group-addon {
  transition: all ease-in 0.3s;
}
.input-group-button .input-group-addon:hover {
  background-color: #79a3ff;
  border-color: #79a3ff;
}
.input-group-button .input-group-addon:hover .btn {
  background-color: #79a3ff;
  border-color: #79a3ff;
}

.input-group-dropdown [class*=btn-] {
  margin-bottom: 0;
}

.input-group-text {
  background: #4680ff;
  color: #fff;
}

/*===== Form group-add-on ends =====*/
/**  =====================
      Switches css start
==========================  **/
.js-dynamic-disable, .js-dynamic-enable {
  vertical-align: inherit;
}

/*===== Switches css ends =====*/
/**  =====================
      Radio-button css start
==========================  **/
.form-radio {
  position: relative;
}
.form-radio .form-help {
  position: absolute;
  width: 100%;
}
.form-radio label {
  position: relative;
  padding-left: 1.5rem;
  text-align: left;
  color: #333;
  display: block;
  line-height: 1.8;
}
.form-radio input {
  width: auto;
  opacity: 1e-8;
  position: absolute;
  left: 0;
}

.radio .helper {
  position: absolute;
  top: -0.15rem;
  left: -0.25rem;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #999;
}
.radio .helper::after {
  transform: scale(0);
  background-color: #4680ff;
  border-color: #4680ff;
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  margin: 0.25rem;
  width: 1rem;
  height: 1rem;
  -webkit-transition: -webkit-transform 0.28s ease;
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  border-radius: 50%;
  border: 0.125rem solid #4680ff;
}
.radio .helper::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  margin: 0.25rem;
  width: 1rem;
  height: 1rem;
  -webkit-transition: -webkit-transform 0.28s ease;
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  border-radius: 50%;
  border: 0.125rem solid #4680ff;
}
.radio label:hover .helper {
  color: #4680ff;
}
.radio input:checked ~ .helper::after {
  transform: scale(0.5);
}
.radio input:checked ~ .helper::before {
  color: #4680ff;
}
.radio.radiofill input:checked ~ .helper::after {
  transform: scale(1);
}
.radio.radiofill .helper::after {
  background-color: #4680ff;
}
.radio.radio-outline input:checked ~ .helper::after {
  transform: scale(0.6);
}
.radio.radio-outline .helper::after {
  background-color: #fff;
  border: 0.225rem solid #4680ff;
}
.radio.radio-matrial input ~ .helper::after {
  background-color: #fff;
}
.radio.radio-matrial input:checked ~ .helper::after {
  transform: scale(0.5);
  box-shadow: 0 1px 7px -1px rgba(0, 0, 0, 0.72);
}
.radio.radio-matrial input:checked ~ .helper::before {
  background-color: #4680ff;
}
.radio.radio-disable {
  opacity: 0.7;
}
.radio.radio-disable label {
  cursor: not-allowed;
}

.radio-inline {
  display: inline-block;
  margin-right: 20px;
}

.radio.radiofill.radio-primary .helper::after {
  background-color: #4680ff;
  border-color: #4680ff;
}
.radio.radiofill.radio-primary .helper::before {
  border-color: #4680ff;
}
.radio.radio-outline.radio-primary .helper::after {
  background-color: #fff;
  border: 0.225rem solid #4680ff;
}
.radio.radio-outline.radio-primary .helper::before {
  border-color: #4680ff;
}
.radio.radio-matrial.radio-primary input ~ .helper::after {
  background-color: #fff;
  border-color: #fff;
}
.radio.radio-matrial.radio-primary input ~ .helper::before {
  background-color: #4680ff;
  border-color: #4680ff;
}
.radio.radiofill.radio-warning .helper::after {
  background-color: #FFB64D;
  border-color: #FFB64D;
}
.radio.radiofill.radio-warning .helper::before {
  border-color: #FFB64D;
}
.radio.radio-outline.radio-warning .helper::after {
  background-color: #fff;
  border: 0.225rem solid #FFB64D;
}
.radio.radio-outline.radio-warning .helper::before {
  border-color: #FFB64D;
}
.radio.radio-matrial.radio-warning input ~ .helper::after {
  background-color: #fff;
  border-color: #fff;
}
.radio.radio-matrial.radio-warning input ~ .helper::before {
  background-color: #FFB64D;
  border-color: #FFB64D;
}
.radio.radiofill.radio-default .helper::after {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}
.radio.radiofill.radio-default .helper::before {
  border-color: #e0e0e0;
}
.radio.radio-outline.radio-default .helper::after {
  background-color: #fff;
  border: 0.225rem solid #e0e0e0;
}
.radio.radio-outline.radio-default .helper::before {
  border-color: #e0e0e0;
}
.radio.radio-matrial.radio-default input ~ .helper::after {
  background-color: #fff;
  border-color: #fff;
}
.radio.radio-matrial.radio-default input ~ .helper::before {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}
.radio.radiofill.radio-danger .helper::after {
  background-color: #FC6180;
  border-color: #FC6180;
}
.radio.radiofill.radio-danger .helper::before {
  border-color: #FC6180;
}
.radio.radio-outline.radio-danger .helper::after {
  background-color: #fff;
  border: 0.225rem solid #FC6180;
}
.radio.radio-outline.radio-danger .helper::before {
  border-color: #FC6180;
}
.radio.radio-matrial.radio-danger input ~ .helper::after {
  background-color: #fff;
  border-color: #fff;
}
.radio.radio-matrial.radio-danger input ~ .helper::before {
  background-color: #FC6180;
  border-color: #FC6180;
}
.radio.radiofill.radio-success .helper::after {
  background-color: #93BE52;
  border-color: #93BE52;
}
.radio.radiofill.radio-success .helper::before {
  border-color: #93BE52;
}
.radio.radio-outline.radio-success .helper::after {
  background-color: #fff;
  border: 0.225rem solid #93BE52;
}
.radio.radio-outline.radio-success .helper::before {
  border-color: #93BE52;
}
.radio.radio-matrial.radio-success input ~ .helper::after {
  background-color: #fff;
  border-color: #fff;
}
.radio.radio-matrial.radio-success input ~ .helper::before {
  background-color: #93BE52;
  border-color: #93BE52;
}
.radio.radiofill.radio-inverse .helper::after {
  background-color: #303548;
  border-color: #303548;
}
.radio.radiofill.radio-inverse .helper::before {
  border-color: #303548;
}
.radio.radio-outline.radio-inverse .helper::after {
  background-color: #fff;
  border: 0.225rem solid #303548;
}
.radio.radio-outline.radio-inverse .helper::before {
  border-color: #303548;
}
.radio.radio-matrial.radio-inverse input ~ .helper::after {
  background-color: #fff;
  border-color: #fff;
}
.radio.radio-matrial.radio-inverse input ~ .helper::before {
  background-color: #303548;
  border-color: #303548;
}
.radio.radiofill.radio-info .helper::after {
  background-color: #62d1f3;
  border-color: #62d1f3;
}
.radio.radiofill.radio-info .helper::before {
  border-color: #62d1f3;
}
.radio.radio-outline.radio-info .helper::after {
  background-color: #fff;
  border: 0.225rem solid #62d1f3;
}
.radio.radio-outline.radio-info .helper::before {
  border-color: #62d1f3;
}
.radio.radio-matrial.radio-info input ~ .helper::after {
  background-color: #fff;
  border-color: #fff;
}
.radio.radio-matrial.radio-info input ~ .helper::before {
  background-color: #62d1f3;
  border-color: #62d1f3;
}

/*===== Radio Button css ends =====*/
/*===== Border-checkbox css start =====*/
.border-checkbox-section .border-checkbox-group {
  display: inline-block;
}
.border-checkbox-section .border-checkbox-group .border-checkbox:checked + .border-checkbox-label:after {
  -webkit-animation: check linear 0.5s;
  animation: check linear 0.5s;
  opacity: 1;
  border-color: #4680ff;
}
.border-checkbox-section .border-checkbox-group .border-checkbox:checked + .border-checkbox-label:after .border-checkbox-label:before {
  border-color: #eee;
}
.border-checkbox-section .border-checkbox-group .border-checkbox-label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  padding-left: 30px;
  margin-right: 15px;
}
.border-checkbox-section .border-checkbox-group .border-checkbox-label:after {
  content: "";
  display: block;
  width: 6px;
  height: 12px;
  opacity: 0.9;
  border-right: 2px solid #eee;
  border-top: 2px solid #eee;
  position: absolute;
  left: 4px;
  top: 11px;
  -webkit-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}
.border-checkbox-section .border-checkbox-group .border-checkbox-label:before {
  content: "";
  display: block;
  border: 2px solid #4680ff;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
}
.border-checkbox-section .border-checkbox {
  display: none;
}
.border-checkbox-section .border-checkbox:disabled ~ .border-checkbox-label {
  cursor: no-drop;
  color: #ccc;
}
.border-checkbox-section .border-checkbox-group-primary .border-checkbox-label:before {
  border: 2px solid #4680ff;
}
.border-checkbox-section .border-checkbox-group-primary .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #4680ff;
}
.border-checkbox-section .border-checkbox-group-warning .border-checkbox-label:before {
  border: 2px solid #FFB64D;
}
.border-checkbox-section .border-checkbox-group-warning .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #FFB64D;
}
.border-checkbox-section .border-checkbox-group-default .border-checkbox-label:before {
  border: 2px solid #e0e0e0;
}
.border-checkbox-section .border-checkbox-group-default .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #e0e0e0;
}
.border-checkbox-section .border-checkbox-group-danger .border-checkbox-label:before {
  border: 2px solid #FC6180;
}
.border-checkbox-section .border-checkbox-group-danger .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #FC6180;
}
.border-checkbox-section .border-checkbox-group-success .border-checkbox-label:before {
  border: 2px solid #93BE52;
}
.border-checkbox-section .border-checkbox-group-success .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #93BE52;
}
.border-checkbox-section .border-checkbox-group-inverse .border-checkbox-label:before {
  border: 2px solid #303548;
}
.border-checkbox-section .border-checkbox-group-inverse .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #303548;
}
.border-checkbox-section .border-checkbox-group-info .border-checkbox-label:before {
  border: 2px solid #62d1f3;
}
.border-checkbox-section .border-checkbox-group-info .border-checkbox:checked + .border-checkbox-label:after {
  border-color: #62d1f3;
}

@-webkit-keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 6px;
  }
  50% {
    height: 12px;
    width: 6px;
  }
}
@keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 6px;
  }
  50% {
    height: 12px;
    width: 6px;
  }
}
/*===== zoom Fade-in-checkbox css start =====*/
.checkbox-fade, .checkbox-zoom {
  display: inline-block;
  margin-right: 15px;
}

.checkbox-fade label, .checkbox-zoom label {
  line-height: 20px;
}

.checkbox-fade label input[type=checkbox], .checkbox-fade label input[type=radio] {
  display: none;
}

.checkbox-zoom label input[type=checkbox], .checkbox-zoom label input[type=radio] {
  display: none;
}

.checkbox-fade label input[type=checkbox]:checked + .cr > .cr-icon, .checkbox-fade label input[type=radio]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkbox-zoom label input[type=checkbox]:checked + .cr > .cr-icon, .checkbox-zoom label input[type=radio]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkbox-fade label input[type=checkbox] + .cr > .cr-icon, .checkbox-fade label input[type=radio] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkbox-zoom label input[type=checkbox] + .cr > .cr-icon, .checkbox-zoom label input[type=radio] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkbox-fade label:after, .checkbox-zoom label:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox-fade.fade-in-disable .cr, .checkbox-fade.fade-in-disable label {
  color: #ccc;
  cursor: no-drop;
}

.checkbox-zoom.fade-in-disable .cr, .checkbox-zoom.fade-in-disable label {
  color: #ccc;
  cursor: no-drop;
}

.checkbox-fade .cr, .checkbox-zoom .cr {
  border-radius: 0;
  border: 2px solid #4680ff;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 20px;
  margin-right: 0.5em;
  position: relative;
  width: 20px;
}

.checkbox-fade .cr .cr-icon, .checkbox-zoom .cr .cr-icon {
  color: #4680ff;
  font-size: 0.8em;
  left: 0;
  line-height: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
}

.checkbox-fade label {
  line-height: 20px;
}
.checkbox-fade label input[type=checkbox], .checkbox-fade label input[type=radio] {
  display: none;
}
.checkbox-fade label input[type=checkbox]:checked + .cr > .cr-icon, .checkbox-fade label input[type=radio]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}
.checkbox-fade label input[type=checkbox] + .cr > .cr-icon, .checkbox-fade label input[type=radio] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkbox-zoom label {
  line-height: 20px;
}
.checkbox-zoom label input[type=checkbox], .checkbox-zoom label input[type=radio] {
  display: none;
}
.checkbox-zoom label input[type=checkbox]:checked + .cr > .cr-icon, .checkbox-zoom label input[type=radio]:checked + .cr > .cr-icon {
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  opacity: 1;
}
.checkbox-zoom label input[type=checkbox] + .cr > .cr-icon, .checkbox-zoom label input[type=radio] + .cr > .cr-icon {
  transform: scale3d(0.2, 0.2, 0.1) translate3d(0, 0, 0);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkbox-fade.fade-in-primary .cr, .checkbox-fade.zoom-primary .cr {
  border: 2px solid #4680ff;
}

.checkbox-zoom.fade-in-primary .cr, .checkbox-zoom.zoom-primary .cr {
  border: 2px solid #4680ff;
}

.checkbox-fade.fade-in-primary .cr .cr-icon, .checkbox-fade.zoom-primary .cr .cr-icon {
  color: #4680ff;
}

.checkbox-zoom.fade-in-primary .cr .cr-icon, .checkbox-zoom.zoom-primary .cr .cr-icon {
  color: #4680ff;
}

.checkbox-fade.fade-in-warning .cr, .checkbox-fade.zoom-warning .cr {
  border: 2px solid #FFB64D;
}

.checkbox-zoom.fade-in-warning .cr, .checkbox-zoom.zoom-warning .cr {
  border: 2px solid #FFB64D;
}

.checkbox-fade.fade-in-warning .cr .cr-icon, .checkbox-fade.zoom-warning .cr .cr-icon {
  color: #FFB64D;
}

.checkbox-zoom.fade-in-warning .cr .cr-icon, .checkbox-zoom.zoom-warning .cr .cr-icon {
  color: #FFB64D;
}

.checkbox-fade.fade-in-default .cr, .checkbox-fade.zoom-default .cr {
  border: 2px solid #e0e0e0;
}

.checkbox-zoom.fade-in-default .cr, .checkbox-zoom.zoom-default .cr {
  border: 2px solid #e0e0e0;
}

.checkbox-fade.fade-in-default .cr .cr-icon, .checkbox-fade.zoom-default .cr .cr-icon {
  color: #e0e0e0;
}

.checkbox-zoom.fade-in-default .cr .cr-icon, .checkbox-zoom.zoom-default .cr .cr-icon {
  color: #e0e0e0;
}

.checkbox-fade.fade-in-danger .cr, .checkbox-fade.zoom-danger .cr {
  border: 2px solid #FC6180;
}

.checkbox-zoom.fade-in-danger .cr, .checkbox-zoom.zoom-danger .cr {
  border: 2px solid #FC6180;
}

.checkbox-fade.fade-in-danger .cr .cr-icon, .checkbox-fade.zoom-danger .cr .cr-icon {
  color: #FC6180;
}

.checkbox-zoom.fade-in-danger .cr .cr-icon, .checkbox-zoom.zoom-danger .cr .cr-icon {
  color: #FC6180;
}

.checkbox-fade.fade-in-success .cr, .checkbox-fade.zoom-success .cr {
  border: 2px solid #93BE52;
}

.checkbox-zoom.fade-in-success .cr, .checkbox-zoom.zoom-success .cr {
  border: 2px solid #93BE52;
}

.checkbox-fade.fade-in-success .cr .cr-icon, .checkbox-fade.zoom-success .cr .cr-icon {
  color: #93BE52;
}

.checkbox-zoom.fade-in-success .cr .cr-icon, .checkbox-zoom.zoom-success .cr .cr-icon {
  color: #93BE52;
}

.checkbox-fade.fade-in-inverse .cr, .checkbox-fade.zoom-inverse .cr {
  border: 2px solid #303548;
}

.checkbox-zoom.fade-in-inverse .cr, .checkbox-zoom.zoom-inverse .cr {
  border: 2px solid #303548;
}

.checkbox-fade.fade-in-inverse .cr .cr-icon, .checkbox-fade.zoom-inverse .cr .cr-icon {
  color: #303548;
}

.checkbox-zoom.fade-in-inverse .cr .cr-icon, .checkbox-zoom.zoom-inverse .cr .cr-icon {
  color: #303548;
}

.checkbox-fade.fade-in-info .cr, .checkbox-fade.zoom-info .cr {
  border: 2px solid #62d1f3;
}

.checkbox-zoom.fade-in-info .cr, .checkbox-zoom.zoom-info .cr {
  border: 2px solid #62d1f3;
}

.checkbox-fade.fade-in-info .cr .cr-icon, .checkbox-fade.zoom-info .cr .cr-icon {
  color: #62d1f3;
}

.checkbox-zoom.fade-in-info .cr .cr-icon, .checkbox-zoom.zoom-info .cr .cr-icon {
  color: #62d1f3;
}

/*===== Color-checkbox css start =====*/
.checkbox-color {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}
.checkbox-color label {
  display: inline-block;
  position: relative;
  padding-left: 10px;
  line-height: 20px;
}
.checkbox-color label::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  left: 0;
  right: 0;
  text-align: center;
  margin-left: -20px;
  border: 1px solid #ccc;
  border-radius: 0;
  background-color: #fff;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox-color label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: -6px;
  top: 0;
  margin-left: -17px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 20px;
  color: #fff;
}
.checkbox-color input[type=checkbox] {
  opacity: 0;
}
.checkbox-color input[type=checkbox]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.checkbox-color input[type=checkbox]:checked + label::after {
  font-family: "IcoFont";
  content: "";
}
.checkbox-color input[type=checkbox]:disabled + label {
  opacity: 0.65;
}
.checkbox-color input[type=checkbox]:disabled + label::before {
  background-color: #eee;
  cursor: not-allowed;
}
.checkbox-color.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox-color.checkbox-inline {
  margin-top: 0;
}

.checkbox-danger input[type=checkbox]:checked + label::after, .checkbox-info input[type=checkbox]:checked + label::after, .checkbox-primary input[type=checkbox]:checked + label::after, .checkbox-success input[type=checkbox]:checked + label::after, .checkbox-warning input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-primary input[type=checkbox]:checked + label::before {
  background-color: #2196f3;
  border-color: #2196f3;
  background-color: #4680ff;
}

.checkbox-warning input[type=checkbox]:checked + label::before {
  background-color: #FFB64D;
}

.checkbox-default input[type=checkbox]:checked + label::before {
  background-color: #e0e0e0;
}

.checkbox-danger input[type=checkbox]:checked + label::before {
  background-color: #FC6180;
}

.checkbox-success input[type=checkbox]:checked + label::before {
  background-color: #93BE52;
}

.checkbox-inverse input[type=checkbox]:checked + label::before {
  background-color: #303548;
}

.checkbox-info input[type=checkbox]:checked + label::before {
  background-color: #62d1f3;
}

/*===== Tag input css start =====*/
.bootstrap-tagsinput {
  border: 1px solid #4680ff;
  line-height: 30px;
  border-radius: 2px;
}
.bootstrap-tagsinput .tag {
  padding: 6px;
  border-radius: 2px;
}

/**  =====================
      Select-2 css start
==========================  **/
.select2-container--default:focus {
  border-color: #4680ff;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #fff transparent;
}
.select2-container--default .select2-search__field:focus {
  border: 1px solid #4680ff;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #4680ff;
}
.select2-container--default .select2-selection--multiple {
  padding: 3px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #4680ff;
  border: 1px solid #4680ff;
  padding: 5px 15px;
  color: #fff;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice span {
  color: #fff;
}
.select2-container--default .select2-selection--multiple .select2-search__field {
  border: none;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #4680ff;
}
.select2-container--default .select2-selection--single {
  color: #fff;
  height: auto;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  background-color: #4680ff;
  color: #fff;
  padding: 8px 30px 8px 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 10px;
  right: 15px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #fff transparent transparent transparent;
}

/*===== Select-2 css ends =====*/
/**  =====================
      Multi-select css start
==========================  **/
.ms-container .ms-list.ms-focus {
  border-color: #4680ff;
  box-shadow: inset 0 1px 1px rgba(26, 188, 156, 0.49), 0 0 8px rgba(26, 188, 156, 0.5);
}
.ms-container .ms-selectable li.ms-hover, .ms-container .ms-selection li.ms-hover {
  background-color: #4680ff;
}

.ms-selectable .custom-header, .ms-selection .custom-header {
  background-color: #4680ff;
  color: #fff;
  text-align: center;
}

.ms-list {
  display: block !important;
}
.ms-list li {
  width: 100%;
}

/*===== Multi-select css ends =====*/
/**  =====================
      Validation-forms css start
==========================  **/
.form-group .messages p {
  margin-bottom: 0;
  transition: all ease-in 0.3s;
}
.form-group .popover-valid {
  position: absolute;
  right: 40px;
  top: 7px;
}

/*===== Validation-forms css end =====*/
/**  =====================
      Form-wizards css start
==========================  **/
.wizard > .steps .current a {
  background: #4680ff;
  transition: all ease-in 0.3s;
  cursor: pointer;
  border-radius: 2px;
  outline: 1px solid #fff;
  outline-offset: -7px;
}
.wizard > .steps .current a:hover {
  background: #6092ff;
  outline-offset: 0;
}

#design-wizard .steps li {
  position: relative;
  z-index: 99;
  margin-bottom: 20px;
}
#design-wizard .steps li:after {
  content: "";
  position: absolute;
  height: 2px;
  background: #4680ff;
  width: 100%;
  top: 30px;
  z-index: -1;
}
#design-wizard .steps li a {
  width: 20%;
  margin: 0 auto;
  text-align: center;
  border-radius: 2px;
}

.wizard > .steps .done a {
  background: #e0e0e0;
}

.card .card-block .wizard-form .steps ul {
  display: block;
}
.card .card-block .wizard-form li {
  margin-right: 0;
}

.wizard > .actions a {
  padding: 5px 15px;
}
.wizard > .content {
  border: 1px solid #ccc;
  border-radius: 2px;
  background: #fff;
}

/*===== Form-wizard forms css end =====*/
/**  =====================
      Label-Badges css start
==========================  **/
.label {
  border-radius: 4px;
  font-size: 75%;
  padding: 4px 7px;
  margin-right: 5px;
  font-weight: 700;
  color: #fff !important;
}

.label-main {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 5px;
}

.badge {
  border-radius: 10px;
  padding: 3px 7px;
}

.label.label-lg {
  padding: 8px 21px;
}
.label.label-md {
  padding: 6px 14px;
}

.badge-lg {
  padding: 5px 9px;
  font-size: 14px;
}

.badge-md {
  padding: 4px 8px;
  font-size: 14px;
}

span.badge {
  display: inline-block !important;
}

.label-default {
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0;
  box-shadow: none;
  color: #fff;
}

.label-inverse-default {
  border: 1px solid;
  border-color: #e0e0e0;
  color: #e0e0e0 !important;
}

.label-inverse-primary {
  border: 1px solid;
  border-color: #4680ff;
  color: #4680ff !important;
}

.label-inverse-success {
  border: 1px solid;
  border-color: #93BE52;
  color: #93BE52 !important;
}

.label-inverse-warning {
  border: 1px solid;
  border-color: #FFB64D;
  color: #FFB64D !important;
}

.label-inverse-danger {
  border: 1px solid;
  border-color: #FC6180;
  color: #FC6180 !important;
}

.label-inverse-info {
  border: 1px solid;
  border-color: #62d1f3;
  color: #62d1f3 !important;
}

.label-inverse-info-border {
  border: 1px solid;
  border-color: #303548;
  color: #303548 !important;
}

.badge-inverse-default {
  border: 1px solid;
  border-color: #e0e0e0;
  color: #e0e0e0 !important;
}

.badge-inverse-primary {
  border: 1px solid;
  border-color: #4680ff;
  color: #4680ff !important;
}

.badge-inverse-success {
  border: 1px solid;
  border-color: #93BE52;
  color: #93BE52 !important;
}

.badge-inverse-warning {
  border: 1px solid;
  border-color: #FFB64D;
  color: #FFB64D !important;
}

.badge-inverse-danger {
  border: 1px solid;
  border-color: #FC6180;
  color: #FC6180 !important;
}

.badge-inverse-info {
  border: 1px solid;
  border-color: #303548;
  color: #303548 !important;
}

.label-icon label {
  position: absolute;
}

.icofont.icofont-envelope {
  font-size: 20px;
}

/*===== Label and Badges css end =====*/
/* ======================
    All Icon Common Page css
======================== */
.data-table-main.icon-list-demo [class*=col-], .data-table-main.icon-svg-demo [class*=col-] {
  margin-bottom: 10px;
}

.icon-list-demo i {
  border: 1px solid #eceeef;
  border-radius: 3px;
  color: rgba(43, 61, 81, 0.7);
  display: inline-block;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  margin: 0 12px 0 0;
  text-align: center;
  vertical-align: middle;
  width: 50px;
}
.icon-list-demo div {
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 10px;
}
.icon-list-demo i:hover {
  color: #64b0f2;
}

/**====== Flag-icon css start ======**/
.flags .f-item {
  padding: 12px;
  border: 1px solid #ddd;
  margin-right: 15px;
  display: inline-block;
}

.data-table-main.flags [class*=col-] {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.content-flag label {
  margin-bottom: 0;
  cursor: pointer;
}
.content-flag .txt-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
}

.outer-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

label.txt-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 135px;
}

.flags .f-item .name, .flags .f-item .capital {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 0;
}
.flags .f-item .code {
  display: block;
  font-size: 90%;
  color: #999;
  padding-bottom: 0;
}

/**====== All icon common page css end ======**/
/**  =====================
      Data-table css start
==========================  **/
table.table-bordered.dataTable tbody th:focus, table.table-bordered.dataTable tbody td:focus {
  outline: none;
}

.card .card-block ul.pagination li {
  margin-right: 0;
}

.page-item.active .page-link {
  background-color: #4680ff;
  border-color: #4680ff;
}

.page-link {
  color: #222;
}

td.highlight {
  font-weight: 700;
  color: #62d1f3;
  background-color: #f5f5f5;
}

.table.compact td, .table.compact th {
  padding: 0.45rem;
}

.dataTables_paginate .pagination {
  float: right;
}

#multi-table_wrapper .dataTables_paginate .pagination {
  float: none;
}

#footer-search tfoot .form-control, #footer-select tfoot .form-control, #form-input-table .form-control, .search-api .form-control, #dt-live-dom .form-control {
  width: 90%;
}

.search-api .global_filter, .search-api .column_filter {
  margin: 0 auto;
}
.search-api .checkbox-fade {
  display: block;
  text-align: center;
}

td.details-control {
  background: url("assets/images/details_open.png") no-repeat center center;
  cursor: pointer;
}

tr.shown td.details-control {
  background: url("assets/images/details_close.png") no-repeat center center;
}

#row-select .selected, #row-delete .selected {
  background-color: #4680ff;
  color: #fff;
}

/*===== Autofill data-table ===== */
div.dt-autofill-list div.dt-autofill-button button {
  background-color: #4680ff;
  border-color: #4680ff;
}

table.dataTable {
  border-collapse: collapse !important;
}
table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
  background-color: #4680ff;
  color: #fff;
}
table.dataTable td.select-checkbox:before, table.dataTable th.select-checkbox:before {
  margin-top: 0;
}
table.dataTable tr.selected td.select-checkbox:after, table.dataTable tr.selected th.select-checkbox:after {
  margin-top: -6px;
  margin-left: -5px;
}

/*===== Button data-table ===== */
button.dt-button, div.dt-button, a.dt-button, button.dt-button:focus:not(.disabled), div.dt-button:focus:not(.disabled), a.dt-button:focus:not(.disabled) {
  background-color: #4680ff;
  border-color: #4680ff;
  border-radius: 2px;
  color: #fff;
  background-image: none;
  font-size: 14px;
}

button.dt-button:active:not(.disabled), button.dt-button.active:not(.disabled) {
  background-color: #4680ff;
  border-color: #4680ff;
  border-radius: 2px;
  color: #fff;
  background-image: none;
  font-size: 14px;
}

div.dt-button:active:not(.disabled), div.dt-button.active:not(.disabled) {
  background-color: #4680ff;
  border-color: #4680ff;
  border-radius: 2px;
  color: #fff;
  background-image: none;
  font-size: 14px;
}

a.dt-button:active:not(.disabled), a.dt-button.active:not(.disabled) {
  background-color: #4680ff;
  border-color: #4680ff;
  border-radius: 2px;
  color: #fff;
  background-image: none;
  font-size: 14px;
}

button.dt-button.btn-warning, div.dt-button.btn-warning, a.dt-button.btn-warning {
  background-color: #FFB64D;
  border-color: #FFB64D;
  border-radius: 2px;
  color: #fff;
  background-image: none;
}

button.dt-button.btn-danger, div.dt-button.btn-danger, a.dt-button.btn-danger {
  background-color: #FC6180;
  border-color: #FC6180;
  border-radius: 2px;
  color: #fff;
  background-image: none;
}

button.dt-button.btn-inverse, div.dt-button.btn-inverse, a.dt-button.btn-inverse {
  background-color: #303548;
  border-color: #303548;
  border-radius: 2px;
  color: #fff;
  background-image: none;
}

button.dt-button:hover:not(.disabled), div.dt-button:hover:not(.disabled), a.dt-button:hover:not(.disabled) {
  background-image: none;
  background-color: #6092ff;
  border-color: #4680ff;
}

button.dt-button.btn-warning:hover:not(.disabled), div.dt-button.btn-warning:hover:not(.disabled), a.dt-button.btn-warning:hover:not(.disabled) {
  background-image: none;
  background-color: #ffcb80;
  border-color: #FFB64D;
}

button.dt-button.btn-danger:hover:not(.disabled), div.dt-button.btn-danger:hover:not(.disabled), a.dt-button.btn-danger:hover:not(.disabled) {
  background-image: none;
  background-color: #fd93a8;
  border-color: #FC6180;
}

button.dt-button.btn-inverse:hover:not(.disabled), div.dt-button.btn-inverse:hover:not(.disabled), a.dt-button.btn-inverse:hover:not(.disabled) {
  background-image: none;
  background-color: #444c67;
  border-color: #303548;
}

div.dt-button-collection button.dt-button:active:not(.disabled), div.dt-button-collection button.dt-button.active:not(.disabled) {
  background-color: #4680ff;
  border-color: #4680ff;
  background-image: none;
}
div.dt-button-collection div.dt-button:active:not(.disabled), div.dt-button-collection div.dt-button.active:not(.disabled) {
  background-color: #4680ff;
  border-color: #4680ff;
  background-image: none;
}
div.dt-button-collection a.dt-button:active:not(.disabled), div.dt-button-collection a.dt-button.active:not(.disabled) {
  background-color: #4680ff;
  border-color: #4680ff;
  background-image: none;
}
div.dt-buttons {
  clear: both;
}

.card .table-card-header b {
  display: block;
  color: #4680ff;
  margin-top: 15px;
}
.card .table-card-header span {
  color: #135dff;
  display: inline-block;
  margin-top: 0;
}

div.dataTables_wrapper div.dataTables_info {
  display: inline-block;
}

/*===== Colreorder data-table ===== */
table.DTCR_clonedTable.dataTable {
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 202;
}

div.DTCR_pointer {
  width: 1px;
  background-color: #0259C4;
  z-index: 201;
}

/*===== Fixed-column data-table ===== */
table.DTFC_Cloned thead, table.DTFC_Cloned tfoot {
  background-color: white;
}

div.DTFC_Blocker {
  background-color: white;
}
div.DTFC_LeftWrapper table.dataTable, div.DTFC_RightWrapper table.dataTable {
  margin-bottom: 0;
  z-index: 2;
}
div.DTFC_LeftWrapper table.dataTable.no-footer, div.DTFC_RightWrapper table.dataTable.no-footer {
  border-bottom: none;
}

th, td {
  white-space: nowrap;
}

/*===== Fixed-header data-table ===== */
table.fixedHeader-floating {
  position: fixed !important;
  background-color: white;
}
table.fixedHeader-floating.no-footer {
  border-bottom-width: 0;
}
table.fixedHeader-locked {
  position: absolute !important;
  background-color: white;
}
table.dataTable th.focus {
  outline: 3px solid #4680ff;
  outline-offset: -1px;
}
table.dataTable td.focus {
  outline: 3px solid #4680ff;
  outline-offset: -1px;
  outline: 1px solid #FC6180;
  outline-offset: -3px;
  background-color: #f8e6e6 !important;
}

@media print {
  table.fixedHeader-floating {
    display: none;
  }
}
/*===== Key-table data-table ===== */
#events {
  margin-bottom: 1em;
  padding: 1em;
  background-color: #f6f6f6;
  border: 1px solid #999;
  border-radius: 3px;
  height: 100px;
  overflow: auto;
}

/*===== Row-Re-order data-table ===== */
table.dt-rowReorder-float {
  position: absolute !important;
  opacity: 0.8;
  table-layout: fixed;
  outline: 2px solid #888;
  outline-offset: -2px;
  z-index: 2001;
}

tr.dt-rowReorder-moving {
  outline: 2px solid #555;
  outline-offset: -2px;
}

body.dt-rowReorder-noOverflow {
  overflow-x: hidden;
}

table.dataTable td.reorder {
  text-align: center;
  cursor: move;
}

#result {
  border: 1px solid #888;
  background: #f7f7f7;
  padding: 1em;
  margin-bottom: 1em;
}

/*===== Scroller data-table ===== */
div.DTS {
  display: block !important;
}
div.DTS tbody th, div.DTS tbody td {
  white-space: nowrap;
}
div.DTS div.DTS_Loading {
  z-index: 1;
}
div.DTS div.dataTables_scrollBody table {
  z-index: 2;
}
div.DTS div.dataTables_paginate, div.DTS div.dataTables_length {
  display: none;
}

/**====== Data-table css end ======**/
/**  =====================
      Foo-table css start
==========================  **/
.footable .pagination > .active > a {
  background-color: #4680ff;
  border-color: #4680ff;
}
.footable .pagination > .active > a:focus, .footable .pagination > .active > a:hover {
  background-color: #4680ff;
  border-color: #4680ff;
}
.footable .pagination > .active > span {
  background-color: #4680ff;
  border-color: #4680ff;
}
.footable .pagination > .active > span:focus, .footable .pagination > .active > span:hover {
  background-color: #4680ff;
  border-color: #4680ff;
}
.footable .pagination > li > a, .footable .pagination > li > span {
  color: #222;
}

.footable-details.table, .footable.table {
  margin-bottom: 0;
}

table.footable > tfoot > tr.footable-paging > td > span.label {
  margin-bottom: 0;
}
table.footable-paging-center > tfoot > tr.footable-paging > td {
  padding-bottom: 0;
}

/**====== Foo-table css end ======**/
/**  =====================
      Handson css start
==========================  **/
.make-me-red {
  color: red;
}

.scroll-container .wtHolder {
  height: 350px !important;
}
.scroll-container #highlighting .wtHolder, .scroll-container #populating .wtHolder, .scroll-container #paginating .wtHolder, .scroll-container #searching .wtHolder, .scroll-container #drag .wtHolder, .scroll-container #validation .wtHolder, .scroll-container #readOnly .wtHolder, .scroll-container #nonEditable .wtHolder, .scroll-container #numericData .wtHolder, .scroll-container #dateDate .wtHolder, .scroll-container #timeData .wtHolder, .scroll-container #checkbox .wtHolder, .scroll-container #select .wtHolder, .scroll-container #dropdown .wtHolder, .scroll-container #autocomplete .wtHolder, .scroll-container #jQuery .wtHolder, .scroll-container #chromaJS .wtHolder, .scroll-container #context .wtHolder, .scroll-container #configuration .wtHolder, .scroll-container #copyPaste .wtHolder, .scroll-container #buttons .wtHolder, .scroll-container #comments .wtHolder {
  height: auto !important;
}

.handson-pagination {
  margin-top: 30px;
}

.currentRow {
  background-color: #F9F9FB !important;
}

.currentCol {
  background-color: #E7E8EF !important;
}

.contact-table tr td:nth-child(n-2) {
  text-align: center;
}
.contact-table tr td:last-child {
  position: relative;
}
.contact-table tr td:last-child .dropdown-menu {
  top: 52px;
}

/**====== Handson css end ======**/
#checkbox-select tr td.select-checkbox, #checkbox-select tr th.select-checkbox {
  padding-left: 30px;
}
#checkbox-select tr td.select-checkbox:before, #checkbox-select tr td.select-checkbox:after {
  left: 15px;
}
#checkbox-select tr th.select-checkbox:before, #checkbox-select tr th.select-checkbox:after {
  left: 15px;
}

.bg-facebook {
  background-color: #3B5997;
}

.text-facebook {
  color: #3B5997;
}

.bg-twiter {
  background-color: #42C0FB;
}

.text-twiter {
  color: #42C0FB;
}

.bg-dribble {
  background-color: #EC4A89;
}

.text-dribble {
  color: #EC4A89;
}

.bg-pinterest {
  background-color: #BF2131;
}

.text-pinterest {
  color: #BF2131;
}

.bg-youtube {
  background-color: #E0291D;
}

.text-youtube {
  color: #E0291D;
}

.bg-googleplus {
  background-color: #C73E2E;
}

.text-googleplus {
  color: #C73E2E;
}

.bg-instagram {
  background-color: #AA7C62;
}

.text-instagram {
  color: #AA7C62;
}

.bg-viber {
  background-color: #7B519D;
}

.text-viber {
  color: #7B519D;
}

.bg-amazon {
  background-color: #000;
}

.text-amazon {
  color: #000;
}

.bg-c-blue {
  background-color: #4680ff;
}

.text-c-blue {
  color: #4680ff;
}

.bg-c-pink {
  background-color: #FC6180;
}

.text-c-pink {
  color: #FC6180;
}

.bg-c-green {
  background-color: #93BE52;
}

.text-c-green {
  color: #93BE52;
}

.bg-c-yellow {
  background-color: #FFB64D;
}

.text-c-yellow {
  color: #FFB64D;
}

.bg-c-orenge {
  background-color: #FE8A7D;
}

.text-c-orenge {
  color: #FE8A7D;
}

.bg-c-lite-green {
  background-color: #69CEC6;
}

.text-c-lite-green {
  color: #69CEC6;
}

.card .card-bg-inverce {
  background-color: #353C4E;
}

.widget-card-1 {
  margin-top: 40px;
  text-align: right;
}
.widget-card-1 .card1-icon {
  width: 60px;
  height: 60px;
  position: absolute;
  top: -15px;
  font-size: 35px;
  border-radius: 8px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.widget-card-1 .card-block > span {
  color: #919aa3;
}
.widget-card-1 h4 {
  font-weight: 400;
  margin-top: 10px;
}
.widget-card-1:hover .card1-icon {
  top: -25px;
}

#viewyourshots {
  height: 450px;
}
#viewyourshots .chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 50px;
  padding: 5px 10px;
  border-radius: 5px;
  background: #4680ff;
  color: #fff;
  font-weight: 500;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  transition: all 0.3s linear;
}
#viewyourshots .chartist-tooltip:before {
  content: "";
  position: absolute;
  border: 5px solid transparent;
  border-right-color: #4680ff;
  border-bottom-color: #4680ff;
  transform: rotate(45deg);
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
}
#viewyourshots .chartist-tooltip.tooltip-show {
  opacity: 1;
}
#viewyourshots .ct-series-a .ct-area, #viewyourshots .ct-series-a .ct-slice-pie {
  fill: rgba(252, 97, 128, 0.7);
}
#viewyourshots .ct-series-a .ct-line {
  stroke: #FC6180;
  stroke-width: 6px;
}

.widget-statstic-card {
  position: relative;
  overflow: hidden;
}
.widget-statstic-card .st-icon {
  color: #fff;
  font-size: 23px;
  padding: 40px 40px 20px 20px;
  border-radius: 50%;
  position: absolute;
  top: -30px;
  right: -30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.widget-statstic-card:hover .st-icon {
  font-size: 50px;
}
.widget-statstic-card i {
  color: #ccc;
}
.widget-statstic-card h2 {
  font-weight: 600;
  display: inline-block;
}
.widget-statstic-card span {
  border-radius: 30px;
  background-color: #c6d8ff;
  padding: 5px 20px;
  color: #4680ff;
  font-weight: 600;
}

.prod-order-card #solid-gauge {
  height: 305px;
}
.prod-order-card ul li {
  display: inline-block;
  width: 32.5%;
  margin-top: 30px;
}
.prod-order-card ul li .legend-icon {
  display: inline-block;
  vertical-align: super;
}
.prod-order-card ul li .legend-icon span {
  display: block;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.prod-order-card ul li .legend-cont {
  display: inline-block;
}
.prod-order-card ul li .legend-cont p {
  margin-bottom: 0;
  line-height: 1;
  font-weight: 900;
  font-size: 14px;
}
.prod-order-card ul li .legend-cont span {
  color: #919aa3;
}

.widget-last-task .to-do-list {
  border: 0;
  padding: 0;
  margin: 0;
}
.widget-last-task .to-do-list .check-task {
  margin-bottom: 20px;
}
.widget-last-task .to-do-list .cr {
  border-radius: 5px;
  border: 2px solid #CFD4D8;
  cursor: pointer;
  display: inline-block;
  float: left;
  top: 10px;
  height: 22px;
  margin-right: 1.2em;
  position: relative;
  width: 22px;
}
.widget-last-task .to-do-list p {
  display: block;
  margin-bottom: 0;
}
.widget-last-task .to-do-list .check-task span {
  display: table-cell;
  font-weight: 600;
}

.card-power h2 {
  font-weight: 600;
  display: inline-block;
}
.card-power span {
  font-size: 20px;
  margin-left: 5px;
}
.card-power .map-area h6 {
  display: inline-block;
  font-weight: 600;
}
.card-power .map-area span {
  font-size: inherit;
  margin-left: 0;
  font-weight: 600;
}
.card-power .map-area p {
  line-height: 18px;
}

.widget-visitor-card {
  overflow: hidden;
  padding: 10px 0;
}
.widget-visitor-card i {
  color: #fff;
  font-size: 80px;
  position: absolute;
  bottom: -10px;
  opacity: 0.3;
  left: -10px;
  transform: rotate(15deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.widget-visitor-card:hover i {
  -webkit-transform: rotate(0deg) scale(1.4);
  transform: rotate(0deg) scale(1.4);
  opacity: 0.5;
}

.widget-card-user .card-user h4 {
  font-size: 20px;
  margin-bottom: 0;
}
.widget-card-user .card-user .card-bedges {
  position: absolute;
  top: 15px;
  right: 15px;
}
.widget-card-user .card-footer {
  background-color: #F6F7FB;
  border-top: 0;
  padding: 1rem 1.25rem;
}
.widget-card-user .card-footer .footer-menu + .footer-menu {
  border-left: 2px solid #F0F1F5;
}

.card-current-prog .table tr .last-line {
  display: inline-block;
}
.card-current-prog .table tr .last-line p {
  position: relative;
}
.card-current-prog .table tr .last-line p:after {
  content: "|";
  position: absolute;
  color: #ccc;
  font-size: 23px;
  padding: 0 40px;
  top: -5px;
}
.card-current-prog .table tr td {
  vertical-align: middle;
  width: calc(100% / 3);
  padding-bottom: 5px;
}
.card-current-prog .table tr td .card-img {
  display: inline-block;
  top: -10px;
  position: relative;
  width: auto;
}
.card-current-prog .table tr td .card-img img {
  width: 40px;
}
.card-current-prog .table tr td .card-contain {
  display: inline-block;
  margin-left: 10px;
}
.card-current-prog .table tr td i {
  display: inline-block;
  margin-right: 20px;
}
.card-current-prog .table tr td p {
  display: inline-block;
  margin-bottom: 0;
}
.card-current-prog .table tr td h6 {
  font-weight: 600;
  margin-bottom: 0;
}
.card-current-prog .table tr td .card-icon-time {
  display: inline-block;
  margin-left: 80px;
}
.card-current-prog .table tr td .card-icon-time + .card-icon-time {
  margin-left: 40px;
}
.card-current-prog .table tr td .progress {
  width: 55%;
  height: 10px;
  text-align: center;
  margin: 0 auto;
  background-color: #CDD8DC;
  border-radius: 30px;
}
.card-current-prog .table tr td .progress .progress-bar {
  border-radius: 30px;
}
.card-current-prog .table tr:first-child td {
  border-top: none;
}

.customer-card h3 {
  font-weight: 600;
}

.profile-view p {
  font-weight: 600;
  font-size: 14px;
}
.profile-view span {
  font-size: 26px;
}

.profile-comp-block input.dial {
  color: #353c4e !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  padding: 22px !important;
  height: auto !important;
  margin-top: 37px !important;
}

.cal-card .calendar-arrow .next {
  position: absolute;
  top: 50%;
  background-color: #FC6180;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #fff;
}
.cal-card .calendar-arrow .prev {
  position: absolute;
  top: 50%;
  background-color: #FC6180;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #fff;
  left: -15px;
  padding: 1px 6px;
}
.cal-card .calendar-arrow .next {
  right: -15px;
  padding: 1px 8px;
}

.cal-event .inverse-card {
  width: 100%;
  height: 100%;
  border-radius: 0 7px 7px 0;
  padding: 35px;
  background-color: #353C4E;
}
.cal-event .inverse-card h5 {
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 10px;
  margin-right: 0;
  display: block;
}
.cal-event .inverse-card h5:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 3px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  background-color: #5E6575;
}
.cal-event .inverse-card p {
  position: relative;
  color: #5E6575;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 20px;
}
.cal-event .inverse-card p:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #5E6575;
}
.cal-event .inverse-card p .event-close {
  border-radius: 50%;
  padding: 2px;
  color: #222;
  font-size: 10px;
  position: absolute;
  font-weight: 600;
  top: 30px;
  right: -4px;
}
.cal-event .inverse-card .btn-event {
  padding: 4px 7px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 6px;
  background-color: #242936;
}

.card-notification {
  position: relative;
}
.card-notification:before {
  content: "";
  width: 15px;
  height: 15px;
  border: 2px solid;
  border-radius: 50%;
  position: absolute;
}
.card-notification:nth-child(1):before {
  border-color: #4680ff;
}
.card-notification:nth-child(2):before {
  border-color: #FC6180;
}
.card-notification:nth-child(3):before {
  border-color: #93BE52;
}
.card-notification:nth-child(4):before {
  border-color: #FFB64D;
}
.card-notification:nth-child(5):before {
  border-color: #FE8A7D;
}
.card-notification:nth-child(6):before {
  border-color: #69CEC6;
}
.card-notification:after {
  content: "";
  background: #e0e0e0;
  height: 100%;
  width: 1px;
  position: absolute;
  top: 22px;
  left: 7px;
}
.card-notification:last-child:after {
  display: none;
}
.card-notification small {
  font-size: 12px;
}
.card-notification p {
  font-size: 14px;
}
.card-notification .card-noti-conatin {
  margin-left: 30px;
}

.client-description .client {
  margin: 0 auto;
  display: block;
  width: 130px;
}
.client-description a, .client-description p {
  font-weight: 600;
}

.marketing-card .table thead th {
  border-bottom: none;
  border-top: none;
}
.marketing-card .table tbody tr:first-child td {
  border-top: none;
}
.marketing-card .table tbody .marketing-header {
  background-color: #f7f7f7;
}
.marketing-card .table tbody td {
  vertical-align: middle;
}
.marketing-card .table tbody td p {
  margin-bottom: 0;
  display: inline-block;
}
.marketing-card .table tbody td .table-contain {
  display: inline-block;
  margin-left: 10px;
}
.marketing-card .table tbody td span {
  font-weight: 600;
}
.marketing-card .table tbody td h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.5;
}
.marketing-card .table tbody td .dropdown-toggle {
  background-color: transparent;
  border: none;
  padding: 0;
}
.marketing-card .table tbody td .dropdown-toggle i {
  margin-right: 0;
}
.marketing-card .table tbody td .dropdown-toggle:after {
  display: none;
}
.marketing-card .table tbody td .social-icon {
  width: 15px;
  height: 15px;
  padding: 6px 8px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  top: -10px;
  position: relative;
}
.marketing-card .table tbody td .btn-action {
  position: relative;
  padding: 6px 18px 6px 32px;
}
.marketing-card .table tbody td .btn-action:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  left: 12px;
  top: 10px;
}
.marketing-card .table tbody td .btn-action.btn-bg-c-blue:after {
  background-color: #4680ff;
}
.marketing-card .table tbody td .btn-action.btn-bg-c-pink:after {
  background-color: #FC6180;
}
.marketing-card .table tbody td .btn-action.btn-bg-c-green:after {
  background-color: #93BE52;
}
.marketing-card .table tbody td .btn-action.btn-bg-c-yellow:after {
  background-color: #FFB64D;
}
.marketing-card .table tbody td .btn-action.btn-bg-c-orenge:after {
  background-color: #FE8A7D;
}
.marketing-card .table tbody td .btn-action.btn-bg-c-lite-green:after {
  background-color: #69CEC6;
}
.marketing-card .marketing-card-footer .tab-cont > p {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-weight: 900;
  margin-top: -8px;
}
.marketing-card .marketing-card-footer .tab-cont > p i {
  font-size: 20px;
  margin: 0 5px;
}

#visitoranalsis .pieLabel > div {
  font-size: 13px !important;
}

.client-map img {
  vertical-align: top;
  width: 90px;
}
.client-map .client-detail {
  margin-bottom: 20px;
}
.client-map .client-profile {
  float: left;
}
.client-map .client-contain {
  display: inline-block;
  margin-left: 20px;
}
.client-map .client-contain h5 {
  color: #222;
  display: block;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 10px;
}
.client-map .client-card-box {
  border: 1px solid #ccc;
  border-radius: 5px;
}
.client-map .client-card-box .client-border {
  border-right: 1px solid #ccc;
}
.client-map .client-card-box .client-border-card {
  border-top: 1px solid #ccc;
}

.card-blue {
  background-color: #5087ff;
}

.card-pink {
  background-color: #fc6b88;
}

.card-green {
  background-color: #98c159;
}

.card-yellow {
  background-color: #ffba57;
}

.card-blue, .card-green, .card-pink, .card-yellow {
  overflow: hidden;
}

.card-blue .value-badges, .card-green .value-badges, .card-pink .value-badges, .card-yellow .value-badges {
  position: absolute;
  top: 10px;
  right: 5px;
  letter-spacing: 1.8px;
}

.total-card .text-left h4 {
  color: #fff;
  font-weight: 600;
  margin-bottom: 5px;
}
.total-card .text-left p {
  color: #fff;
  font-size: 15px;
}

.visitor-map .btn-default {
  background-color: transparent;
  color: #bdc3c7;
  border-color: #bdc3c7;
  cursor: pointer;
  transition: all ease-in 0.3s;
}
.visitor-map .visitor-graph {
  height: 400px;
}

.visitor-card h2 {
  font-weight: 400;
}
.visitor-card h2 span {
  font-weight: 400;
}
.visitor-card .progress {
  height: 8px;
}
.visitor-card .progress .progress-bar {
  border-radius: 30px;
}
.visitor-card i {
  font-size: 40px;
  float: right;
  opacity: 0.5;
  margin-top: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.visitor-card:hover i {
  opacity: 1;
  -webkit-transform: scale(1.5) rotate(360deg);
  transform: scale(1.5) rotate(360deg);
}

.review-resourse table tbody td {
  border-top: none;
  padding: 1rem;
}
.review-resourse table tbody td .trafic-resorce {
  height: 40px;
  width: 100%;
}
.review-resourse table tbody td h6 {
  margin-bottom: 0;
  line-height: 1.5;
}
.review-resourse table tbody td .peity {
  width: 100px;
  height: 25px;
}

.review-task {
  overflow: auto;
}

#visitoranalsis {
  width: 100%;
  height: 400px;
}

.review-task .table td {
  vertical-align: middle;
}
.review-task .table td img {
  display: inline-block;
}
.review-task .table td p {
  display: inline-block;
  margin-bottom: 0;
}
.review-task .table td h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 600;
}
.review-task .table td .review-date {
  margin-bottom: 0;
  position: relative;
  padding-right: 15px;
}
.review-task .table td .review-date:after {
  content: "|";
  position: absolute;
  font-size: 15px;
  padding: 0 6px;
}
.review-task .table td i + i {
  margin-left: 5px;
}
.review-task .table tbody tr:first-child td {
  border-top: none;
}

.last-week-card:hover i {
  opacity: 1;
}
.last-week-card i {
  opacity: 0.65;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.last-week-card p {
  font-weight: 600;
}
.last-week-card span {
  letter-spacing: 0.9px;
  padding: 4px 10px;
  font-size: 13px;
  font-weight: 400;
}

.review-project thead th {
  border-bottom: none;
  border-top: none;
}
.review-project tbody tr:first-child td {
  border-top: none;
}
.review-project tbody tr td h6, .review-project tbody tr td p {
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1.4;
}
.review-project .peity {
  width: 30px;
  height: 30px;
}
.review-project img {
  vertical-align: top;
}
.review-project .project-contain {
  margin-left: 15px;
  display: inline-block;
}
.review-project .progress {
  width: 100%;
  height: 8px;
  background-color: #CDD8DC;
  border-radius: 30px;
  position: relative;
  overflow: inherit;
}
.review-project .progress .progress-bar {
  border-radius: 30px;
}
.review-project .progress label {
  position: absolute;
  top: -22px;
  right: 0;
  color: #222;
  font-weight: 600;
  font-size: 12px;
}

.whether-card .card-footer h4 small {
  font-size: 60%;
}
.whether-card .daily-whether h5 {
  margin-right: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.whether-card .daily-whether > div .climacon {
  display: block;
  margin: 35px auto;
  width: 70px;
  height: 70px;
}
.whether-card .daily-whether > div:nth-child(1) .climacon_component-fill, .whether-card .daily-whether > div:nth-child(1) .climacon_component-stroke {
  fill: #4680ff;
}
.whether-card .daily-whether > div:nth-child(2) .climacon_component-fill, .whether-card .daily-whether > div:nth-child(2) .climacon_component-stroke {
  fill: #FC6180;
}
.whether-card .daily-whether > div:nth-child(3) .climacon_component-fill, .whether-card .daily-whether > div:nth-child(3) .climacon_component-stroke {
  fill: #93BE52;
}
.whether-card .daily-whether > div:nth-child(4) .climacon_component-fill, .whether-card .daily-whether > div:nth-child(4) .climacon_component-stroke {
  fill: #FFB64D;
}
.whether-card .daily-whether > div:nth-child(5) .climacon_component-fill, .whether-card .daily-whether > div:nth-child(5) .climacon_component-stroke {
  fill: #FE8A7D;
}
.whether-card .daily-whether > div:nth-child(6) .climacon_component-fill, .whether-card .daily-whether > div:nth-child(6) .climacon_component-stroke {
  fill: #69CEC6;
}

.online-form h3 {
  display: inline-block;
}
.online-form span {
  vertical-align: text-top;
  margin-left: 10px;
}
.online-form span i {
  color: #ddd;
}

.revenue-map p {
  display: inline-block;
}
.revenue-map #revenue-generate-graph + svg, .revenue-map #tax-deduction-graph + svg {
  height: 150px;
  width: 100%;
}
.revenue-map .order-graph + .peity {
  width: 100px;
  height: 100px;
}

.add-task-card .btn-card {
  font-size: 13px;
  padding: 5px 10px;
  margin: 0px 10px;
  color: #fff;
  border-radius: 5px;
}
.add-task-card .to-do-list {
  border: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.add-task-card .task-card-img {
  position: relative;
  top: 0px;
}

.card-progress .progress {
  height: 8px;
  width: 100%;
  position: relative;
  top: 8px;
  left: 0;
  border-radius: 0;
  overflow: inherit;
}

.visitor-chart-card {
  position: relative;
}
.visitor-chart-card span {
  position: relative;
  top: -5px;
  margin-left: 10px;
}
.visitor-chart-card span i {
  color: #ddd;
}
.visitor-chart-card .card-time {
  position: absolute;
  top: -35px;
  right: 50px;
}
.visitor-chart-card .card-time .btn {
  padding: 6px 15px;
  border-radius: 7px;
  font-size: 13px;
}

.recent-candidate-card thead th {
  border-bottom: none;
  border-top: none;
}
.recent-candidate-card tbody tr td {
  vertical-align: middle;
}
.recent-candidate-card tbody tr td h6 {
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
}
.recent-candidate-card tbody tr td p {
  line-height: 1.4;
  margin-bottom: 0;
}
.recent-candidate-card tbody tr:first-child td {
  border-top: none;
}
.recent-candidate-card .recent-contain {
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 3px;
}
.recent-candidate-card .border-img-view .btn, .recent-candidate-card .border-img-view a + a > img {
  margin-left: -20px;
}

.card-map .card-header .card-header-right i, .card-map h2, .card-map h5, .card-map h6, .card-map i {
  color: #fff;
}
.card-map h2 i {
  opacity: 0.5;
}

.card-chart {
  position: relative;
}
.card-chart .card-chart-right {
  position: absolute;
  right: 30px;
  top: 18px;
}

.card-view img {
  vertical-align: top;
}
.card-view .card-view-contain p {
  color: #f1f1f1;
}
.card-view .progress {
  width: 100%;
  height: 10px;
  background-color: #f1f1f1;
  border-radius: 4px;
}
.card-view .progress .progress-bar {
  border-radius: 4px;
}
.card-view h5, .card-view p, .card-view span {
  color: #fff;
}

.monthly-earning {
  position: relative;
  overflow: hidden;
}
.monthly-earning .monthly-data {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: rgba(231, 231, 231, 0.82);
  text-align: center;
}
.monthly-earning .monthly-data > div {
  height: 100%;
}
.monthly-earning .monthly-data > div > div {
  padding-top: 40px;
  padding-bottom: 40px;
}
.monthly-earning .monthly-data > div > div:first-child {
  background-color: rgba(70, 128, 255, 0.8);
  height: 100%;
}
.monthly-earning .monthly-data > div > div:first-child h3, .monthly-earning .monthly-data > div > div:first-child h5 {
  color: #fff;
}

.card-customer .progress {
  width: 100%;
  height: 9px;
  background-color: #CCC;
  border-radius: 30px;
}
.card-customer .progress .progress-bar {
  border-radius: 30px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  position: relative;
}
.card-customer .progress .progress-bar:before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
}

.card-overview span {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: 15px;
}

.card-comment {
  transition: all ease-in-out 0.5s;
}
.card-comment .card-block-small {
  border-bottom: 1px solid #f1f1f1;
}
.card-comment .card-block-small .comment-desc h6 {
  font-weight: 600;
}
.card-comment .card-block-small .comment-desc .btn-comment {
  font-size: 13px;
  padding: 5px 20px;
  line-height: 1;
  color: #fff;
}
.card-comment .card-block-small:hover {
  background-color: #F1F1F1;
}

.browser-card .btn-browser {
  padding: 2px 10px;
  color: #fff;
  font-size: 13px;
}

.card-contact-box .card-contain {
  position: relative;
}
.card-contact-box .card-contain p {
  position: relative;
}
.card-contact-box .card-contain p:after {
  content: "";
  position: absolute;
  width: 90px;
  height: 2px;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #919aa3;
}

.weather-update .climacon {
  width: 170px;
  height: 155px;
}
.weather-update .climacon .climacon_component-stroke {
  fill: #FC6180;
}

.card-statistics .label {
  border-radius: 30px;
  padding: 3px 10px;
  font-size: 13px;
}
.card-statistics .stats-block {
  display: flex;
  align-items: center;
}

.card-feed .feed-card-info i {
  padding: 10px;
  border-radius: 50%;
}
.card-feed .feed-card-info p {
  font-size: 14px;
  font-weight: 400;
}
.card-feed .feed-card-info:nth-child(1) i {
  color: #0046df;
  background-color: #acc6ff;
}
.card-feed .feed-card-info:nth-child(2) i {
  color: #f20534;
  background-color: #fec5d0;
}
.card-feed .feed-card-info:nth-child(3) i {
  color: #5d7c2e;
  background-color: #c1da9c;
}
.card-feed .feed-card-info:nth-child(4) i {
  color: #e68800;
  background-color: #ffe0b3;
}
.card-feed .feed-card-info:nth-child(5) i {
  color: #fd2f18;
  background-color: #ffe5e2;
}
.card-feed .feed-card-info:nth-child(6) i {
  color: #339e95;
  background-color: #b6e7e3;
}
.card-feed .feed-card-info:nth-child(7) i {
  color: #0046df;
  background-color: #acc6ff;
}
.card-feed .feed-card-info:nth-child(8) i {
  color: #f20534;
  background-color: #fec5d0;
}
.card-feed .feed-card-info:nth-child(9) i {
  color: #5d7c2e;
  background-color: #c1da9c;
}
.card-feed .feed-card-info:nth-child(10) i {
  color: #e68800;
  background-color: #ffe0b3;
}
.card-feed .feed-card-info:nth-child(11) i {
  color: #fd2f18;
  background-color: #ffe5e2;
}
.card-feed .feed-card-info:nth-child(12) i {
  color: #339e95;
  background-color: #b6e7e3;
}

@media only screen and (max-width: 480px) {
  .card-feed .feed-card-info span {
    display: none;
  }
}
.views-card {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.views-card h2, .views-card p, .views-card span {
  color: #fff;
  transition: all 0.5s ease-in-out;
}
.views-card i {
  width: 10px;
  height: 10px;
  padding: 4px 6px;
  border-radius: 50%;
  font-size: 15px;
  background-color: #fff;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.23);
  transition: all 0.5s ease-in-out;
}
.views-card.bg-c-blue {
  border: 1px solid #4680ff;
}
.views-card.bg-c-pink {
  border: 1px solid #FC6180;
}
.views-card.bg-c-yellow {
  border: 1px solid #FFB64D;
}
.views-card.bg-c-green {
  border: 1px solid #93BE52;
}
.views-card:hover {
  background-color: #fff;
}
.views-card:hover.bg-c-blue h2, .views-card:hover.bg-c-blue p, .views-card:hover.bg-c-blue span {
  color: #4680ff;
}
.views-card:hover.bg-c-blue i {
  background-color: #4680ff;
  color: #fff;
  box-shadow: 0 0 0 4px rgba(70, 128, 255, 0.5);
}
.views-card:hover.bg-c-pink h2, .views-card:hover.bg-c-pink p, .views-card:hover.bg-c-pink span {
  color: #FC6180;
}
.views-card:hover.bg-c-pink i {
  background-color: #FC6180;
  color: #fff;
  box-shadow: 0 0 0 4px rgba(252, 97, 128, 0.5);
}
.views-card:hover.bg-c-yellow h2, .views-card:hover.bg-c-yellow p, .views-card:hover.bg-c-yellow span {
  color: #FFB64D;
}
.views-card:hover.bg-c-yellow i {
  background-color: #FFB64D;
  color: #fff;
  box-shadow: 0 0 0 4px rgba(255, 182, 77, 0.5);
}
.views-card:hover.bg-c-green h2, .views-card:hover.bg-c-green p, .views-card:hover.bg-c-green span {
  color: #93BE52;
}
.views-card:hover.bg-c-green i {
  background-color: #93BE52;
  color: #fff;
  box-shadow: 0 0 0 4px rgba(147, 190, 82, 0.5);
}

.sales-revenue-card .card-header .card-header-right i, .sales-revenue-card .card-header h5 {
  color: #fff;
}
.sales-revenue-card h6 {
  color: #fff;
}
.sales-revenue-card h2 {
  color: #fff;
  letter-spacing: 1.2px;
}

.statetic-card {
  position: relative;
}
.statetic-card .btn-default {
  background-color: transparent;
  position: absolute;
  right: 50px;
  top: -100px;
  border-radius: 6px;
  padding: 6px 15px;
  color: #666;
  font-size: 13px;
}
.statetic-card .icon-stat {
  position: relative;
  top: -8px;
}

.task-sale-card p {
  line-height: 15px;
}

.week-sales-card .card-header h5, .week-sales-card .card-header .card-header-right i {
  color: #fff;
}

.green-contain-card .card-header .card-header-right i {
  color: #fff;
}
.green-contain-card h4 {
  color: #fff;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.green-contain-card .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.amount-card {
  overflow: hidden;
}
.amount-card #amount-processed + svg, .amount-card #amount-spent + svg, .amount-card #profit-processed + svg {
  width: 100%;
  height: 100px;
}

#realtimeupdate {
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-radius: 0 0 7px 7px;
}

#monthlyprofit-1, #monthlyprofit-2, #monthlyprofit-3 {
  text-align: center;
}

#monthlyprofit-1 + svg, #monthlyprofit-2 + svg, #monthlyprofit-3 + svg {
  width: 100%;
  height: 30px;
}

#monthlyprofit-1 + svg polyline, #monthlyprofit-2 + svg polyline, #monthlyprofit-3 + svg polyline {
  stroke-width: 2;
}

.sentiment-card .sentiment-center {
  align-items: center;
}

.revenue-card {
  position: relative;
  top: 19px;
}
.revenue-card span {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 6px;
}

.widget-chat-box .media .send-chat, .widget-chat-box .media .receive-chat {
  position: relative;
  background-color: #E6EDFD;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 30px;
  max-width: 300px;
  overflow: visible;
}
.widget-chat-box .media .send-chat:before, .widget-chat-box .media .receive-chat:before {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  border: 10px solid transparent;
  border-right: 15px solid #E6EDFD;
  left: -20px;
  top: 8px;
}
.widget-chat-box .media .send-chat .time {
  position: absolute;
  bottom: -27px;
  right: 0;
  font-size: 12px;
}
.widget-chat-box .media .receive-chat {
  background-color: #f7f7f7;
}
.widget-chat-box .media .receive-chat .time {
  position: absolute;
  bottom: -27px;
  right: 0;
  font-size: 12px;
}
.widget-chat-box .media .receive-chat:before {
  left: auto;
  right: -26px;
  border-left: 15px solid #f7f7f7;
  border-right: 15px solid transparent;
}
.widget-chat-box .media .receive-chat .time {
  left: 0;
}

.tab-card .media {
  margin-bottom: 10px;
}
.tab-card .media:last-child {
  margin-bottom: 0;
}

.feature-card-box .feature-icon {
  width: 50px;
  height: 50px;
  border-radius: 20px;
  color: #fff;
  margin: 0 auto;
  font-size: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.feature-card-box:hover .feature-icon {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.44);
  box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.44);
  border-radius: 0;
}

.sales-menu-card .valign-wrapper {
  align-items: center;
}
.sales-menu-card .valign-wrapper .sales-icon {
  top: -10px;
  position: relative;
}

.user-status-card .card-header .card-header-right i, .user-status-card h2, .user-status-card h5, .user-status-card h6 {
  color: #fff;
}
.user-status-card .progress {
  width: 100%;
  height: 10px;
  background-color: #f1f1f1;
  border-radius: 30px;
}
.user-status-card .progress .progress-bar {
  border-radius: 30px;
}
.user-status-card .progress .bg-darken-blue {
  background-color: #1d64ff;
}
.user-status-card .progress .bg-darken-pink {
  background-color: #fb3960;
}
.user-status-card .progress .bg-darken-yellow {
  background-color: #ffa524;
}

.serve-card .serve-chart-list {
  align-items: center;
}
.serve-card .serve-chart-list h6 {
  position: relative;
  margin-left: 40px;
}
.serve-card .serve-chart-list h6:before {
  content: "";
  position: absolute;
  width: 22px;
  height: 6px;
  border-radius: 30px;
  background-color: #93BE52;
  top: 6px;
  left: -39px;
}
.serve-card .serve-chart-list h6:last-child:before {
  background-color: #ccc;
}

.curr-plan-card .plan-icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0 0 0 5px rgba(148, 181, 253, 0.49);
  font-size: 40px;
  color: #fff;
  padding: 14px;
}

.email-sent-card {
  position: relative;
}
.email-sent-card .card-week {
  position: absolute;
  top: 25px;
  right: 50px;
}
.email-sent-card .card-week .btn {
  padding: 6px 15px;
  border-radius: 7px;
  font-size: 13px;
}
.email-sent-card p {
  position: relative;
  margin-left: 25px;
}
.email-sent-card p:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 5px;
  border-radius: 30px;
  background-color: #93BE52;
  top: 9px;
  left: -25px;
}
.email-sent-card .card-block > div > div:nth-child(2) p:before {
  background-color: #4680ff;
}
.email-sent-card .card-block > div > div:nth-child(3) p:before {
  background-color: #FC6180;
}
.email-sent-card .card-block > div > div:nth-child(4) p:before {
  background-color: #FFB64D;
}

.balance-card .progress {
  width: 100%;
  height: 8px;
  background-color: #E5E5E5;
  border-radius: 30px;
}
.balance-card .progress .progress-bar {
  border-radius: 30px;
}

.feed-card .card-block {
  position: relative;
  padding-left: 15px;
}
.feed-card .card-block .border-feed {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.feed-card .card-block .border-feed i {
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
}
.feed-card:hover .border-feed.bg-c-blue {
  background-color: rgba(70, 128, 255, 0.3);
}
.feed-card:hover .border-feed.bg-c-blue i {
  color: #4680ff;
}
.feed-card:hover .border-feed.bg-c-pink {
  background-color: rgba(252, 97, 128, 0.3);
}
.feed-card:hover .border-feed.bg-c-pink i {
  color: #FC6180;
}
.feed-card:hover .border-feed.bg-c-green {
  background-color: rgba(147, 190, 82, 0.3);
}
.feed-card:hover .border-feed.bg-c-green i {
  color: #93BE52;
}
.feed-card:hover .border-feed.bg-c-yellow {
  background-color: rgba(255, 182, 77, 0.3);
}
.feed-card:hover .border-feed.bg-c-yellow i {
  color: #FFB64D;
}

.user-detail-card .contact-icon .btn-default {
  background-color: #f1f1f1;
  color: #A5B0BD;
  border: 0;
  font-size: 20px;
  padding: 6px 10px;
  border-radius: 5px;
  transition: all ease-in-out 0.3s;
}
.user-detail-card .contact-icon .btn-default + .btn-default {
  margin-left: 0;
}
.user-detail-card .contact-icon .btn-default:hover {
  background-color: #c6d8ff;
  color: #4680ff;
}
.user-detail-card .user-detail i {
  margin-right: 10px;
}
.user-detail-card .user-detail div:nth-child(1) i {
  color: #4680ff;
}
.user-detail-card .user-detail div:nth-child(2) i {
  color: #FC6180;
}
.user-detail-card .user-detail div:nth-child(3) i {
  color: #93BE52;
}
.user-detail-card .user-detail div:nth-child(4) i {
  color: #FFB64D;
}
.user-detail-card .user-detail div:nth-child(5) i {
  color: #FE8A7D;
}
.user-detail-card .user-detail div:nth-child(6) i {
  color: #69CEC6;
}
.user-detail-card .user-detail div:nth-child(7) i {
  color: #4680ff;
}
.user-detail-card .user-detail div:nth-child(8) i {
  color: #FC6180;
}
.user-detail-card .user-detail div:nth-child(9) i {
  color: #93BE52;
}
.user-detail-card .user-detail div:nth-child(10) i {
  color: #FFB64D;
}
.user-detail-card .user-detail div:nth-child(11) i {
  color: #FE8A7D;
}
.user-detail-card .user-detail div:nth-child(12) i {
  color: #69CEC6;
}
.user-detail-card .user-detail > div {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.user-detail-card .user-detail > div:last-child {
  border-bottom: none;
}
.user-detail-card .user-detail > div h6 {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 15px;
}

@media only screen and (max-width: 575px) {
  .user-detail-card .user-detail {
    padding-top: 15px;
  }
  .user-detail-card .user-detail .m-b-30 {
    margin-bottom: 5px;
  }
  .user-detail-card .user-detail .row > div:nth-child(2) h6 {
    margin-left: 25px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
}
.invite-card hr {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0;
  border-top: 1px solid #ccc;
  width: 100px;
}
.invite-card .invite-card-chart {
  position: relative;
}
.invite-card .invite-card-chart .invite-card-cont {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.invite-card .invite-card-chart .invite-card-cont i {
  display: block;
  font-size: 35px;
  transform: rotate(25deg);
}

.timing-cloude {
  position: relative;
  line-height: 0;
}
.timing-cloude .dash {
  display: inline-block;
  width: 70px;
  height: 70px;
  border-radius: 5px;
}
.timing-cloude .dash .digit {
  display: inline-table;
  color: #fff;
  font-size: 45px;
  line-height: 1.5;
}
.timing-cloude .dash .delimeter {
  top: 24px;
  left: 0;
  right: 0;
  font-size: 80px;
  color: #919AA3;
  position: absolute;
}

.project-task thead th {
  border-bottom: none;
  border-top: none;
}
.project-task tbody tr td {
  vertical-align: middle;
}
.project-task .task-contain h6 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  line-height: 2.5;
  margin-bottom: 0;
  font-weight: 600;
}
.project-task .progress {
  width: 55%;
  height: 5px;
  text-align: center;
  margin: 0 auto;
  background-color: #E5E5E5;
  border-radius: 30px;
}
.project-task .progress .progress-bar {
  border-radius: 30px;
}

.past-payment-card thead th {
  border-bottom: none;
  border-top: none;
}
.past-payment-card tbody tr:last-child td {
  padding-bottom: 0;
}
.past-payment-card tbody tr td {
  vertical-align: middle;
}
.past-payment-card tbody tr:first-child td {
  border-top: none;
}
.past-payment-card img {
  width: 40px;
}

#design-graph-1, #design-graph-2, #design-graph-3 {
  height: 60px;
  width: 150px;
}

.blue-contain-card .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.order-task .order-blue-task .order-icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 2px solid #4680ff;
  background-color: #c6d8ff;
  text-align: center;
  padding: 18px;
}
.order-task .order-blue-task .border-top-blue {
  border-top: 1px solid #4680ff;
}
.order-task .order-pink-task .order-icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  text-align: center;
  padding: 18px;
  border: 2px solid #FC6180;
  background-color: #fedee5;
}
.order-task .order-pink-task .border-top-pink {
  border-top: 1px solid #FC6180;
}
.order-task .order-yellow-task .order-icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  text-align: center;
  padding: 18px;
  border: 2px solid #FFB64D;
  background-color: #ffeacd;
}
.order-task .order-yellow-task .border-top-yellow {
  border-top: 1px solid #FFB64D;
}

.view-card .border-img-view {
  border-top: 2px dotted #e0e0e0;
}
.view-card .border-img-view img {
  width: 40px;
  border-radius: 5px;
}
.view-card .chart-div {
  position: relative;
  display: inline-block;
  margin: 0 auto;
}
.view-card .chart-div .chart-more-icon {
  position: absolute;
  bottom: -25px;
  right: -20px;
}
.view-card .chart-div .chart-more-icon > span {
  background-color: #93BE52;
  padding: 7px 13px;
  border: 5px solid #eeeeee;
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 13px -3px rgba(0, 0, 0, 0.24);
  font-size: 16px;
  font-weight: 600;
}
.view-card .chart-div .chart-more-icon > p {
  margin-top: 15px;
}
.view-card .chart-div .dial {
  font-size: 44px !important;
  color: #000 !important;
}

.summery-card .progress {
  width: 85%;
  float: left;
  height: 8px;
  text-align: center;
  margin: 0 auto;
  background-color: #CDD8DC;
  border-radius: 30px;
}
.summery-card .progress .progress-bar {
  border-radius: 30px;
}

#visitor + svg {
  width: 100%;
  height: 200px;
  margin-bottom: -5px;
}

.total-request-card .progress {
  width: 85%;
  float: left;
  height: 5px;
  text-align: center;
  margin: 0 auto;
  background-color: #CDD8DC;
  border-radius: 30px;
}
.total-request-card .progress .progress-bar {
  border-radius: 30px;
}
.total-request-card hr {
  width: 50px;
  border-top: 1px solid #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
}
.total-request-card i {
  position: relative;
  top: 5px;
}

.in-design-card h3, .in-design-card h4, .in-design-card p {
  color: #fff;
}
.in-design-card .inner-dark-card {
  padding: 15px;
}
.in-design-card .inner-dark-card h3 {
  position: relative;
  font-size: 25px;
  margin-right: 5px;
  vertical-align: super;
}
.in-design-card .inner-dark-card p {
  font-size: 11px;
  margin-bottom: 0;
  line-height: 1.4;
}
.in-design-card .blue-drak-card {
  background-color: #276bff;
}
.in-design-card .pink-drak-card {
  background-color: #fb4368;
}
.in-design-card .yellow-drak-card {
  background-color: #ffa92e;
}

.app-design .btn {
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
}
.app-design .team-section img {
  width: 35px;
  border-radius: 5px;
}
.app-design .progress-box p {
  margin-bottom: 0;
}
.app-design .progress-box .progress {
  width: calc(100% - 80px);
  height: 8px;
  text-align: center;
  margin: 0 auto;
  background-color: #E5E5E5;
  border-radius: 30px;
  position: relative;
  overflow: inherit;
}
.app-design .progress-box .progress .progress-bar {
  border-radius: 30px;
}
.app-design .progress-box .progress .progress-bar label {
  position: absolute;
  top: -24px;
  right: 0;
  color: #222;
  font-weight: 600;
  font-size: 13px;
}

.fees-card > div {
  display: flex;
  align-items: center;
}
.fees-card .legend-icon {
  display: inline-block;
}
.fees-card .legend-icon span {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 4px;
  margin-right: 10px;
}
.fees-card li {
  margin-bottom: 15px;
}
.fees-card li .legend-cont {
  display: inline-block;
  vertical-align: top;
}
.fees-card li .legend-cont > span {
  color: #919aa3;
  margin: 0;
}
.fees-card li .legend-cont > p {
  font-weight: 900;
  line-height: 1;
  margin-bottom: 3px;
}

.web-trafic > div {
  display: flex;
  align-items: flex-end;
}
.web-trafic .legend-icon {
  display: inline-block;
}
.web-trafic .legend-icon span {
  width: 10px;
  height: 10px;
  display: block;
  margin-right: 10px;
}
.web-trafic li {
  margin-bottom: 15px;
}
.web-trafic li .legend-cont {
  display: inline;
  vertical-align: top;
}
.web-trafic li .legend-cont > span {
  color: #919aa3;
  margin: 0;
  float: right;
}
.web-trafic li .legend-cont > p {
  font-weight: 900;
  line-height: 1;
  display: inline-block;
  margin-bottom: 3px;
}

#stacked-transactions-graph + svg {
  width: 100%;
  height: 130px;
}

#chartdiv {
  width: 100%;
  height: 500px;
  margin: auto;
}

.card-block-big h4:first-child {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
}
.card-block-big h4:first-child small {
  margin-top: 20px;
  font-size: 12px;
}
.card-block-big h4:last-child {
  margin-bottom: 0;
}
.card-block-big h4:first-child {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
}
.card-block-big h4:first-child small {
  margin-top: 20px;
  font-size: 12px;
}
.card-block-big h4:last-child {
  margin-bottom: 0;
}

.card.social-network p {
  line-height: 1.6;
}

.card-block h6 {
  word-wrap: break-word;
}

#project-overview-chart .c3-chart-arc text {
  fill: none;
}

.project-overview-chart {
  text-align: center;
}

#project-over-sub-1, #project-over-sub-2, #project-over-sub-3 {
  height: 50px;
  width: 100%;
}

#sales-revenue-chart .c3-chart-arcs-title {
  font-size: 20px;
  fill: #fff !important;
}
#sales-revenue-chart .c3-legend-item {
  display: none;
}

@media only screen and (max-width: 1366px) {
  .sales-prediction h2 {
    font-size: 1.8em;
  }
}
.progressbar-v-1 .chart, .progressbar-v-2 .chart, .progressbar-v-3 .chart {
  text-align: center;
}

.progressbar-v-1 .chart-percent {
  font-size: 24px;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  font-weight: 400;
}

#revenue-status-d-graph + svg, #revenue-status-w-graph + svg {
  width: 100%;
  height: 50px;
}

#new-user-daily + svg, #page-views-today + svg {
  width: 100%;
  height: 150px;
}

.status-rev-chart {
  display: flex;
  align-items: center;
}

#monthly-earning + svg {
  width: 100%;
  height: 300px;
  margin-bottom: -5px;
}

.happyball, .sadball {
  width: 80px;
  height: 100px;
  margin: 0 auto;
}

.progressbar-v-1 .chart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.visitor-chart-card h3 {
  font-size: 1.25rem;
}
.visitor-chart-card span {
  margin-left: 2px;
  padding: 4px 2px;
  margin-right: 0;
}

.card-comment img {
  float: left;
}
.card-comment .comment-desc {
  padding-left: 80px;
  vertical-align: top;
}
.card-comment .comment-btn {
  display: inline-block;
}
.card-comment .date {
  display: inline-block;
  float: right;
}

.revenue-report h4 {
  margin-bottom: 20px;
}

.last-week-sales {
  width: 100%;
  height: 265px;
}

.green-contain-card p.text-muted {
  margin-bottom: 1.125rem;
}

.unique-visitor-card .progress {
  height: 5px;
}
.unique-visitor-card .footer-card {
  background-color: #fff;
}
.unique-visitor-card .footer-card .f-prog {
  border-right: 1px solid #ccc;
}

@media only screen and (max-width: 575px) {
  .unique-visitor-card .footer-card .f-prog {
    border-right: none;
  }
}
@media only screen and (max-width: 1366px) {
  .card-block-big h4:last-child {
    margin-left: 20px;
  }

  .weather-update h4 {
    padding-bottom: 0;
  }

  .green-contain-card .p-t-30 {
    padding-top: 0;
  }

  .client-description button {
    padding: 8px;
  }
}
@media only screen and (max-width: 1357px) {
  .client-map .client-profile {
    text-align: center;
    margin-bottom: 20px;
  }
  .client-map .client-contain {
    display: inherit;
    margin-left: 0;
    text-align: center;
  }
}
@media only screen and (max-width: 1299px) {
  .client-map .client-profile {
    float: none;
  }

  h2 {
    font-size: 1.7em;
  }
}
@media only screen and (max-width: 1280px) {
  .feature-card-box p {
    font-size: 13px;
  }

  .contact-icon .btn-default {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 1200px) {
  .task-sale-card .top {
    margin-bottom: 30px;
  }

  .card-statistics .card-block h2 {
    font-size: 1rem;
  }

  .sentiment-card .card-block-big {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 1199px) {
  .whether-card .daily-whether > div .climacon {
    margin: 0 auto;
  }
  .whether-card .daily-whether-block {
    margin-bottom: 30px;
  }

  .widget-card-1 {
    margin-top: 10px;
  }

  .widget-card-user .card-user {
    text-align: center;
  }

  .founder-block {
    margin-bottom: 1.125rem;
  }

  .marketing-card-footer .text-c-green, .marketing-card-footer .text-c-pink {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  .card-statistics {
    text-align: center;
  }
  .card-statistics .card-block h2 {
    margin-top: 20px;
  }

  .prod-order-card ul li {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .cal-event {
    padding-left: 15px !important;
  }
  .cal-event .inverse-card {
    border-radius: 0;
  }

  .main-body .page-wrapper .page-header-title i {
    margin-right: 8px;
    width: 40px;
    height: 40px;
  }

  .sales-revenue-chart, div#donut-department-sales {
    text-align: center;
  }

  .widget-card-user .footer-menu {
    margin-bottom: 1.125rem;
  }

  .order-blue-task h2, .order-pink-task h2, .order-yellow-task h2 {
    text-align: left;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-comment .date {
    float: none;
    margin-top: 20px;
  }

  .visitor-chart-card .card-time .btn {
    padding: 6px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 568px) {
  .fees-card li {
    margin-bottom: 15px;
    margin-right: 15px;
    display: inline-block;
  }
}
@media only screen and (max-width: 575px) {
  .card-progress label {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .sales-menu-card {
    text-align: center;
  }

  .main-body .page-wrapper .page-header-breadcrumb {
    display: block;
    margin-top: 20px;
  }

  .visitor-chart-card .card-time {
    position: initial;
  }

  .card-view img, .visitor-counter {
    margin-bottom: 20px;
  }

  .card-view-contain {
    margin-left: 0;
  }

  .card-comment .comment-desc {
    padding-left: 60px;
  }
  .card-comment .date {
    float: none;
    margin-top: 20px;
  }

  .statetic-card .btn-default {
    right: 0;
  }

  .sentiment-center h4 {
    margin-top: 10px;
  }

  .card-feed .feed-card-info i {
    margin-right: 2px;
    padding: 8px;
  }

  .monthly-earning .monthly-data > div > div:first-child {
    height: 40%;
    padding-top: 20px;
  }

  .spent {
    height: 60%;
    padding-top: 20px;
  }

  .personal-profile {
    margin-top: 20px;
  }

  .card.social-network .card-block {
    text-align: center;
  }

  .prod-order-card ul li {
    width: 100%;
  }

  .client-description {
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
  .rest-traffic h2 {
    text-align: center;
  }

  .p-l-30 {
    padding-left: 0;
  }

  .timing-cloude .dash {
    width: 60px;
  }
}
#monthly-graph .amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}
#monthly-graph .lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}
#monthly-graph .amcharts-graph-column-front {
  -webkit-transition: all 0.3s 0.3s ease-out;
  transition: all 0.3s 0.3s ease-out;
}
#monthly-graph .amcharts-graph-column-front:hover {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  fill-opacity: 0.4;
}
#monthly-graph .amcharts-graph-g3 {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 500%;
  -webkit-animation: am-draw 40s;
  animation: am-draw 40s;
}

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}
@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}
@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}
@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}
@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
/**  =====================
      Alert css start
==========================  **/
.alert {
  font-size: 15px;
  margin-bottom: 2rem;
}
.alert.icons-alert {
  border-left-width: 48px;
}
.alert.icons-alert .alert-icon {
  left: 15px;
  position: relative;
  text-align: center;
  top: 0;
  z-index: 3;
}
.alert.icons-alert p {
  line-height: 21px;
  margin-bottom: 0;
}

.close {
  font-size: 16px;
  margin-top: 5px;
}

/*===== Primary alert ===== */
.alert-default {
  background-color: #fff;
  border-color: #e0e0e0;
  color: #e0e0e0;
}

.alert-primary {
  background-color: #fff;
  border-color: #4680ff;
  color: #4680ff;
}

.alert-success {
  background-color: #fff;
  border-color: #93BE52;
  color: #93BE52;
}

.alert-info {
  background-color: #fff;
  border-color: #62d1f3;
  color: #62d1f3;
}

.alert-warning {
  background-color: #fff;
  border-color: #FFB64D;
  color: #FFB64D;
}

.alert-danger {
  background-color: #fff;
  border-color: #FC6180;
  color: #FC6180;
}

/*===== Border alert ===== */
.border-default {
  border-color: transparent;
  border-left: 3px solid #e0e0e0;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #e0e0e0;
}

.border-primary {
  border-color: transparent;
  border-left: 3px solid #4680ff;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #4680ff;
}

.border-success {
  border-color: transparent;
  border-left: 3px solid #93BE52;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #93BE52;
}

.border-info {
  border-color: transparent;
  border-left: 3px solid #62d1f3;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #62d1f3;
}

.border-warning {
  border-color: transparent;
  border-left: 3px solid #FFB64D;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #FFB64D;
}

.border-danger {
  border-color: transparent;
  border-left: 3px solid #FC6180;
  border-radius: 0;
  box-shadow: 0 0 1px #999;
  color: #FC6180;
}

/*===== Solid-background alert ===== */
.background-default {
  background-color: #e0e0e0;
  color: #fff;
}

.background-primary {
  background-color: #4680ff;
  color: #fff;
}

.background-success {
  background-color: #93BE52;
  color: #fff;
}

.background-info {
  background-color: #62d1f3;
  color: #fff;
}

.background-warning {
  background-color: #FFB64D;
  color: #fff;
}

.background-danger {
  background-color: #FC6180;
  color: #fff;
}

.icons-alert {
  position: relative;
}
.icons-alert:before {
  color: #fff;
  content: "";
  font-family: "IcoFont" !important;
  font-size: 16px;
  left: -30px;
  position: absolute;
}

[class*=alert-] code {
  margin-left: 10px;
}

/*======= Alert css ends ===========*/
/* =======================================
      Authentication-page css start
 ========================================= */
.common-img-bg {
  background-size: auto;
  background: url(assets/images/auth/bg.jpg) no-repeat center center fixed;
  height: 100%;
}

.header .login-card {
  margin-top: 30px;
}

@media only screen and (max-width: 991px) {
  .common-img-bg .text-center > img {
    display: none;
  }

  .header .login-card.auth-body {
    margin-top: 30px;
  }
}
.login-card.auth-body {
  width: 450px;
  margin: 0 auto;
}

.signin-card.auth-body {
  width: 550px;
}

.signup-card.auth-body {
  width: 650px;
}

.login-card {
  border-radius: 30px;
  position: relative;
  display: block;
}
.login-card h1 {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}

.login {
  text-align: left;
}
.login ul li label {
  color: #757575;
  font-weight: 600;
  letter-spacing: 1px;
}
.login h3 {
  color: #666666;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
}

/*
.login.header {
  align-items: flex-start;
}
*/
.social-login {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding-bottom: 30px;
}
.social-login .btn {
  margin-left: 5px;
  margin-right: 5px;
}

.login-card .card-footer {
  background: #fff;
}
.login-card h1 {
  color: #1b8bf9;
}
.login-card .forget {
  border-bottom: 1px solid #fff;
  font-size: 24px;
  line-height: 0.6;
}
.login-card h3 {
  color: #666666;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
}

.btn-signup .btn-md, .btn-singin1 .btn-md, .btn-singin2 .btn-md {
  font-size: 22px;
  font-weight: 400;
  width: 400px;
}

.form-group-main .form-group {
  margin: 30px 0;
}

.login-card .unlock, .login-card .btn-forgot {
  text-align: center;
}

.imgs-screen .img-circle {
  padding: 4px;
  border: 1px solid #E6E6E4;
}

.sign-in-with {
  font-weight: 600;
  margin: 24px auto 16px;
  position: relative;
  text-align: center;
}
.sign-in-with .text {
  display: inline-block;
  padding: 0 8px;
  position: relative;
  vertical-align: baseline;
  z-index: 9999;
}
.sign-in-with .text:after {
  left: 100%;
}
.sign-in-with .text:before {
  right: 100%;
}
.sign-in-with .text:after, .sign-in-with .text:before {
  border-top: 1px solid rgba(0, 0, 0, 0.28);
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  width: 60px;
}

.error-500, .error-503, .error-404, .error-400, .error-403, .login {
  align-items: center;
  background-color: #2196F3;
  display: flex;
  height: 100%;
  position: fixed;
  text-align: center;
  width: 100%;
}

.auth-box {
  background-color: #fff;
  border-radius: 8px;
  margin: 20px 0 0 0;
  padding: 20px;
}
.auth-box .confirm h3 {
  color: #4680ff;
  font-size: 34px;
}
.auth-box i.icofont-check-circled {
  font-size: 42px;
}

.btn-facebook {
  background-color: #3c5a99;
  border-color: #3c5a99;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
}

.btn-twitter {
  background-color: #41abe1;
  border-color: #41abe1;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
}

.btn-google-plus, .btn-linkedin, .btn-pinterest {
  border-radius: 2px;
}

.footer {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 90;
}
.footer p {
  margin-bottom: 0;
  padding: 18px;
  width: 100%;
}

.login-card-modal {
  margin: 0 auto;
}

.login.login-modal-main {
  background-color: #fff;
}

.mixed-form .tabbed-modal .nav-link {
  background-color: #f5f5f5;
  border-radius: 0;
  color: #888;
}
.mixed-form .tabbed-modal .nav-link h6 {
  padding: 10px 0;
}
.mixed-form .tabbed-modal .nav-link.active {
  background-color: transparent;
  border-color: transparent;
  color: #303548;
}
.mixed-form .modal-body {
  padding: 0;
}
.mixed-form .auth-box {
  background-color: #fff;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
}
.mixed-form .auth-box h3 {
  color: #666666;
  font-size: 22px;
  font-weight: 600;
  margin-top: 20px;
}
.mixed-form .auth-box i.icofont-check-circled {
  font-size: 42px;
}

.auth-box-main .navbar-nav li {
  color: #fff;
  line-height: 40px;
}

.offline-404 {
  background-color: #f3f3f3;
}
.offline-404 .auth-body {
  margin-top: -100px;
}
.offline-404 h1 {
  color: #2c3e50;
  font-size: 160px;
  font-weight: 600;
  letter-spacing: 5px;
  text-shadow: 3px -2px 4px rgba(128, 128, 128, 0.57);
}
.offline-404 h2 {
  color: #aaa;
  font-weight: 600;
  margin-bottom: 40px;
  margin-top: 30px;
}

/**====== Authentication-page css end ======**/
/**  =====================
      Breadcrumb css start
==========================  **/
.caption-breadcrumb .breadcrumb-header, .primary-breadcrumb .breadcrumb-header, .inverse-breadcrumb .breadcrumb-header, .danger-breadcrumb .breadcrumb-header, .info-breadcrumb .breadcrumb-header, .warning-breadcrumb .breadcrumb-header, .success-breadcrumb .breadcrumb-header {
  display: inline-block;
}

.caption-breadcrumb .breadcrumb-header span, .primary-breadcrumb .breadcrumb-header span, .inverse-breadcrumb .breadcrumb-header span, .danger-breadcrumb .breadcrumb-header span, .info-breadcrumb .breadcrumb-header span, .warning-breadcrumb .breadcrumb-header span, .success-breadcrumb .breadcrumb-header span {
  display: block;
  font-size: 13px;
  margin-top: 5px;
}

.front-icon-breadcrumb .breadcrumb-header {
  display: inline-block;
}
.front-icon-breadcrumb .big-icon {
  display: inline-block;
}
.front-icon-breadcrumb .big-icon i {
  font-size: 64px;
  color: #4680ff;
}
.front-icon-breadcrumb .d-inline-block span {
  display: block;
  font-size: 13px;
  margin-top: 5px;
}

.primary-breadcrumb, .inverse-breadcrumb, .danger-breadcrumb, .info-breadcrumb, .warning-breadcrumb, .success-breadcrumb {
  background-color: #4680ff;
  color: #fff;
}

.primary-breadcrumb h5, .inverse-breadcrumb h5, .danger-breadcrumb h5, .info-breadcrumb h5, .warning-breadcrumb h5, .success-breadcrumb h5, .primary-breadcrumb a, .inverse-breadcrumb a, .danger-breadcrumb a, .info-breadcrumb a, .warning-breadcrumb a, .success-breadcrumb a, .primary-breadcrumb .breadcrumb-title li:last-child a, .inverse-breadcrumb .breadcrumb-title li:last-child a, .danger-breadcrumb .breadcrumb-title li:last-child a, .info-breadcrumb .breadcrumb-title li:last-child a, .warning-breadcrumb .breadcrumb-title li:last-child a, .success-breadcrumb .breadcrumb-title li:last-child a, .primary-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .inverse-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .danger-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .info-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .warning-breadcrumb .breadcrumb-item + .breadcrumb-item::before, .success-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}

.inverse-breadcrumb {
  background-color: #303548;
}

.danger-breadcrumb {
  background-color: #FC6180;
}

.info-breadcrumb {
  background-color: #62d1f3;
}

.warning-breadcrumb {
  background-color: #FFB64D;
}

.success-breadcrumb {
  background-color: #93BE52;
}

/**====== Breadcrumb css end ======**/
/**  =====================
      Chatting css start
==========================  **/
.chat-bg {
  background-image: url("assets/images/LogoIASD_blue.png");
  background-size: cover;
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.45);
}

#main-chat .chat-single-box.active .chat-header {
  background-color: #4680ff;
}
#main-chat .chat-single-box.active .chat-header a {
  color: #fff;
}
#main-chat .chat-single-box .chat-header a {
  color: #fff;
}
#main-chat .chat-box ul.boxs li.chat-single-box .chat-header.custom-collapsed {
  position: absolute;
  bottom: 0;
  width: 300px;
}
#main-chat .minimized {
  background-color: #1e2730;
}
#main-chat .user-groups {
  padding-left: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}
#main-chat .user-groups .userlist-box {
  border-bottom: none;
}
#main-chat .user-groups .userlist-box:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
#main-chat .user-groups .media {
  padding-left: 0;
}
#main-chat .user-groups h6 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
}
#main-chat .user-groups li {
  margin-bottom: 20px;
}
#main-chat .user-groups li:last-child {
  margin-bottom: 0;
}
#main-chat .user-groups li.work, #main-chat .user-groups li.frnds {
  position: relative;
  padding-left: 20px;
}
#main-chat .user-groups li.work:before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #93BE52;
  border-radius: 50px;
  left: 0;
  top: 6px;
}
#main-chat .user-groups li.frnds:before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #93BE52;
  border-radius: 50px;
  left: 0;
  top: 6px;
  background-color: #FC6180;
}

.user-box {
  height: 100%;
}

.close {
  opacity: 1;
}

.write-msg {
  padding: 12px;
  bottom: 10px;
  border-top: 1px solid #ddd;
}
.write-msg #paper-btn i {
  font-size: 18px;
  margin-right: 0;
  cursor: pointer;
}

.chat-box .secondary:active:hover {
  background-color: #fff;
}

#main-chat .chat-box ul.boxs li.minimized {
  height: 30px;
  width: 30px;
  bottom: 0;
  position: absolute;
  left: -50px;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}
#main-chat .chat-box ul.boxs li.minimized .count {
  color: #fff;
  text-align: center;
  margin: 5px;
}
#main-chat .chat-box ul.boxs li.minimized .chat-dropdown {
  list-style: none;
  display: none;
  position: absolute;
  background-color: #f5f5f5;
  -webkit-box-shadow: 0 1px 8px 0 rgba(5, 5, 5, 0.5);
  -moz-box-shadow: 0 1px 8px 0 rgba(5, 5, 5, 0.5);
  box-shadow: 0 1px 8px 0 rgba(5, 5, 5, 0.5);
  width: 150px;
  z-index: 100;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 5px 0;
}
#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li {
  padding: 2px 5px;
}
#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li div {
  display: inline-block;
}
#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li .username {
  width: 85%;
  height: 22px;
}
#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li .remove {
  width: 13%;
  padding: 2PX 4PX;
  float: right;
}
#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li .remove:hover {
  background-color: #c0c0c0 !important;
}
#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li:hover {
  color: black;
}
#main-chat .chat-box ul.boxs li.hidden {
  display: none;
}

.chat-single-box {
  height: 440px;
  float: right;
  width: 300px;
  margin-right: 15px;
  direction: ltr;
  z-index: 999;
  bottom: 0;
}
.chat-single-box .chat-header {
  background-color: #e0e0e0;
  color: #fff;
}

.box-live-status {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  border: 1px solid;
}

.chat-msg-img {
  height: 50px;
  width: 50px;
}

.msg-reply {
  padding: 5px;
  position: relative;
  right: 10px;
  border-radius: 2px;
  top: 6px;
  margin-bottom: 10px;
}

.header-users, .users {
  right: 0;
  top: 103px;
  width: 300px;
  height: 100%;
}

.p-chat-user {
  position: fixed;
  top: 80px;
  z-index: 99;
  border-left: 1px solid #ccc;
}

.users-main {
  height: 100%;
  width: 300px;
  position: fixed;
  border-radius: 0px;
}

.userlist-box {
  cursor: pointer;
  border-bottom: 1px solid #efefef;
}
.userlist-box .media-left {
  padding-right: 10px;
}

.users-main .media {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.user-box .media-object {
  height: 45px;
  width: 45px;
  display: inline-block;
}

.chat-menu-content, .chat-menu-reply {
  background: #f3f3f3;
  position: relative;
  overflow: visible;
  border-radius: 4px;
}

.chat-menu-content:before {
  position: absolute;
  top: 20px;
  left: -14px;
  width: 0;
  height: 0;
  content: "";
  border: 8px solid transparent;
  border-right-color: #f3f3f3;
}

.chat-menu-reply:before {
  position: absolute;
  top: 20px;
  right: -14px;
  width: 0;
  height: 0;
  content: "";
  border: 8px solid transparent;
  border-left-color: #4680ff;
}

.chat-reply-box {
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  border-top: 1px solid #ccc;
}

.chat-inner-header {
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding: 20px;
}

.chat-send {
  position: absolute;
  bottom: 5px;
  right: 0;
  border: none;
  background-color: transparent;
  color: #4680ff;
  transform: rotate(45deg);
}

.showChat_inner .chat-messages {
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.chat-menu-content .chat-cont, .chat-menu-reply .chat-cont {
  padding: 10px;
}

.chat-menu-content .chat-time, .chat-menu-reply .chat-time {
  padding-left: 10px;
  padding-bottom: 10px;
}

.back_chatBox {
  margin: 0 auto;
  font-weight: 600;
}
.back_chatBox i {
  float: left;
  position: absolute;
  margin-top: 5px;
  left: 20px;
  cursor: pointer;
}

.designation {
  cursor: pointer;
}

.extra-profile-list {
  display: none;
}

.chat-menu-reply {
  background: #4680ff;
  margin-right: 10px;
}
.chat-menu-reply .chat-cont, .chat-menu-reply .chat-time {
  color: #fff;
}

.users-main .media .chat-header {
  font-size: 14px;
  font-weight: 600;
}
.users-main .media-body div + div {
  font-size: 12px;
}

.chat-body {
  overflow-y: auto;
  height: 340px;
}

.users-main-fix {
  height: auto;
  position: fixed;
  bottom: 0px;
  top: 151px;
}

.chat-footer, .chat-link {
  border-color: #4680ff;
}

.chat-footer .input-group-addon {
  padding: 7px 0.75rem;
}

.form-control:focus ~ .chat-send {
  border-color: #4680ff;
}

.minimized-box {
  padding: 5px 10px;
}

.write-msg input {
  border-radius: 0;
  border-right: 0;
}
.write-msg .btn {
  box-shadow: none;
  border-left: 0;
  font-size: 14px;
}
.write-msg .btn-secondary:hover {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 0;
}

.form-control:focus ~ .input-group-btn .btn-secondary {
  border-color: #66afe9;
  color: #4680ff;
}

.img-chat-profile {
  height: 45px;
  width: 45px;
}

.chat-minimize .chat-body, .chat-minimize .chat-footer {
  display: none;
}

.chat-box {
  margin-right: 290px;
  height: 440px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
}

.user-box .media-object {
  height: 45px;
  width: 45px;
  display: inline-block;
}

.friend-box .media-object {
  height: 45px;
  width: 45px;
  display: inline-block;
}
.friend-box img {
  margin-right: 10px;
  margin-bottom: 10px;
}

.chat-header {
  color: #222222;
}

.live-status {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 100%;
  border: 1px solid;
}

.showChat {
  display: none;
}

.showChat_inner {
  position: fixed;
  top: 80px;
  background-color: white;
  right: 0;
  width: 300px;
  height: calc(100% - 80px);
  z-index: 99;
  border-left: 1px solid #ccc;
}

/**====== Chatting css end ======**/
@media only screen and (min-width: 1367px) and (max-width: 1700px) {
  .timeline-dot:before, .timeline-dot:after {
    left: 34px;
  }
  .timeline-dot .social-timelines:before {
    left: 37px;
  }
  .timeline-dot .social-timelines-left:after {
    right: -30px;
  }
  .timeline-dot .timeline-right .card {
    margin-left: 30px;
  }
}
@media only screen and (max-width: 1366px) {
  .to-do-list {
    margin-bottom: 15px;
  }

  .img-tabs img {
    width: 75px;
  }

  .card .card-block .sub-title {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1279px) and (max-width: 1500px) {
  .cd-horizontal-timeline {
    margin: 0 auto;
  }

  #chartdiv {
    height: 440px;
  }

  .social-card-1 .card-block-big {
    padding: 1.3em;
  }

  #morris-extra-area {
    height: 400px !important;
  }

  .widget-chat-box .send-chat, .widget-chat-box .media .receive-chat, .widget-chat-box .receive-chat {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .similar-job .media {
    display: inline-block;
  }
  .similar-job .media .media-body {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1258px) {
  .user-head {
    text-align: center;
  }
  .user-head .user-face {
    margin: 0 auto;
  }
  .user-head .user-name {
    display: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .timeline-dot .card {
    margin-left: 50px;
  }
  .timeline-dot:after, .timeline-dot:before {
    left: 35px;
  }
  .timeline-dot .social-timelines:before {
    left: 38px;
  }
  .timeline-dot .social-timelines-left:after {
    right: -50px;
  }
}
@media only screen and (max-width: 1199px) {
  #design-wizard .steps li a {
    padding: 1em 0.5em;
  }

  .blog-page-card {
    margin-bottom: 30px;
  }
  .blog-page-card:last-child {
    margin-bottom: 0;
  }

  #draggablePanelList [class*=col-] .card-sub {
    margin-bottom: 30px;
  }
  #draggablePanelList [class*=col-]:last-child .card-sub {
    margin-bottom: 0px;
  }

  .user-head {
    justify-content: center;
  }

  .mail-box-head {
    text-align: center;
  }
  .mail-box-head form {
    float: inherit;
  }

  #external-events h6 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .just_edit .input-group {
    width: 100% !important;
  }

  .dotted-line-theme .ibtn_container {
    top: 10px;
    position: inherit;
  }

  .users-card [class*=col-] {
    margin-bottom: 20px;
  }
  .users-card [class*=col-]:last-child {
    margin-bottom: 0;
  }

  .timeline-btn {
    text-align: center;
    width: 100%;
    right: 0;
  }

  .social-timeline-left {
    position: inherit;
    top: 0;
    margin-right: 0;
  }

  .timeline-icon {
    position: absolute;
    margin-left: 0;
  }

  .social-timelines:before {
    left: 35px;
  }

  .timeline-dot:after, .timeline-dot:before {
    left: 32px;
  }

  .user-box .social-client-description {
    border-bottom: 1px solid #ddd;
  }
  .user-box .f-right {
    float: none;
    display: block;
  }

  .main-timeline {
    padding: 10px;
  }
  .main-timeline:before, .main-timeline:after {
    margin-left: -94px;
    margin-top: 25px;
    display: none;
  }

  .cd-timeline-content::before {
    top: 15px;
  }

  .cd-date {
    display: block;
    padding-left: 20px !important;
    padding-top: 0 !important;
  }

  .cd-details {
    display: block;
    padding-left: 20px !important;
    padding-top: 0 !important;
    margin-bottom: 20px;
  }

  .cd-timeline-content p {
    margin-bottom: 0;
  }

  .clock-widget .basic-alarm {
    display: none;
  }

  .weather-card-2 .weather-card-temp {
    padding: 0;
  }
  .weather-card-2 .card-footer {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .user-activity-card .card-block-big {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media only screen and (max-width: 992px) {
  .main-body .page-wrapper .align-items-end .page-header-title {
    text-align: center;
    display: block;
  }
  .main-body .page-wrapper .align-items-end .page-header-title i {
    float: none;
    margin-bottom: 10px;
  }
  .main-body .page-wrapper .align-items-end body .pcoded[vertical-placement=right] .main-body {
    direction: ltr;
  }
  .main-body .page-wrapper .align-items-end .page-header-breadcrumb {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    display: block;
  }

  .page-header-breadcrumb .breadcrumb-item {
    display: inline-block;
    float: none;
  }

  .messages-content {
    border-right: none;
  }

  .card-block [class*=col-]:nth-last-child(-n+3) .card.user-card, .card-block [class*=col-]:last-child .basic-list {
    margin-bottom: 0;
  }

  #footer-search tfoot .form-control, #footer-select tfoot .form-control, #form-input-table .form-control, .search-api .form-control, #dt-live-dom .form-control {
    width: 80%;
  }

  .pcoded-main-container {
    margin-top: 80px !important;
  }

  .card-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .header-navbar {
    position: fixed !important;
    z-index: 1209;
  }
  .header-navbar[pcoded-header-position=relative] {
    position: fixed !important;
    top: 0;
  }
  .header-navbar .navbar-wrapper .navbar-container {
    margin-left: 0;
  }
  .header-navbar .navbar-wrapper .navbar-logo {
    width: 100% !important;
  }
  .header-navbar .navbar-wrapper .navbar-logo a img {
    display: block !important;
  }
  .header-navbar .navbar-wrapper .navbar-logo .mobile-menu {
    display: block;
    position: absolute;
    left: 20px;
    top: 23px;
    right: auto;
  }
  .header-navbar .navbar-wrapper .navbar-logo .mobile-search {
    display: block;
    position: absolute;
    left: 70px;
    top: 30px;
  }
  .header-navbar .navbar-wrapper .navbar-logo .mobile-options {
    display: block;
    position: absolute;
    right: 30px;
  }
  .header-navbar .navbar-container .nav-left {
    display: none;
  }

  .btn-blog {
    text-align: center;
  }

  .animation-image img {
    margin: 20px auto 0;
  }

  .animation-type {
    text-align: center;
  }

  .nav-tabs.md-tabs.tab-timeline li a {
    font-size: 16px;
  }

  .widget-card-user .card-user {
    text-align: center;
  }
}
@media only screen and (min-width: 992px) {
  .btn-blog {
    text-align: right;
  }

  .basic-list, .card-block [class*=col-]:nth-last-child(-n+3) .card.user-card {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  #draggableWithoutImg [class*=col-]:last-child .m-t-20 {
    margin-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 872px) {
  .author-details .header-right li {
    margin-right: 40px;
  }

  .recent-candidate-card .recent-contain {
    margin-left: 0;
  }

  .author-details img ~ .dot1, .author-details img ~ .dot2 {
    top: 30%;
  }
}
@media only screen and (max-width: 768px) {
  .card-block.list-tag ul li:last-child {
    margin-bottom: 20px;
  }

  .inline-order-list {
    margin-top: 0;
  }

  h4 {
    font-size: 1rem;
    font-weight: 600;
  }

  .recent-candidate-card .recent-contain {
    margin-left: 0;
  }

  .mail-body-content .form-group:nth-child(2) .form-control {
    margin-bottom: 20px;
  }
  .mail-body-content .form-group:nth-child(2) .col-md-6:last-child .form-control {
    margin-bottom: 0;
  }

  .card h5 {
    font-size: 14px;
  }

  .header-navbar .navbar-wrapper .navbar-logo {
    display: inherit;
  }
  .header-navbar .navbar-wrapper .navbar-logo a {
    display: inline-block;
  }
  .header-navbar .navbar-wrapper .navbar-logo .mobile-options {
    top: 30px;
  }

  body.header-fixed .main-body .page-wrapper {
    padding-bottom: 0;
    padding-top: 15px;
  }

  .card-header-right i:nth-child(n+2) {
    display: none;
  }

  .dataTables_paginate .pagination {
    float: inherit;
    text-align: center;
    display: inline-block;
    margin-top: 10px !important;
  }

  div.dataTables_wrapper div.dataTables_info {
    display: inherit;
  }

  .wizard > .steps > ul > li {
    float: inherit;
    width: 100%;
  }

  .gallery-page .grid {
    padding: 0;
  }

  figure.effect-steve h2 {
    margin-top: 0;
  }

  .cover-profile .profile-bg-img {
    margin-bottom: 0;
  }

  .card-block.user-info {
    position: inherit;
    text-align: center;
    background-color: #e0e0e0;
    margin-bottom: 20px;
  }

  .user-info .media-left, .user-info .media-body {
    display: block;
  }

  .cover-btn {
    position: inherit;
    float: none;
  }
  .cover-btn .btn:first-child {
    margin-right: 10px;
  }

  .user-info .user-title {
    position: initial;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .nav-tabs.md-tabs.tab-timeline li a {
    font-size: 14px;
  }

  .user-content h4 {
    font-size: 1rem;
  }
  .user-content h5 {
    margin-right: 0;
    font-size: 14px;
  }

  .top-cap-text p {
    padding: 0;
  }
  .top-cap-text p:last-child {
    margin-bottom: 0;
  }

  .user-card .btn span {
    font-size: 13px;
  }

  .toolbar-page [class*=col-] {
    margin-bottom: 30px;
  }
  .toolbar-page [class*=col-]:last-child {
    margin-bottom: 0;
  }

  .offline-404 h1 {
    font-size: 80px;
  }
  .offline-404 h2 {
    font-size: 25px;
  }

  .invoice-contact {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .lng-dropdown {
    display: none;
  }

  .group-widget .card-block-big {
    height: 110px;
  }

  .whether-card .card-footer {
    text-align: center;
  }

  .client-blocks ul li {
    font-size: 30px;
  }

  .widget-profile-card-1 ~ .card-footer [class*=col-] {
    border: none;
    margin: 0 auto;
  }

  .large-widget-card i {
    top: 15px;
  }

  .user-activity-card .user-number {
    padding: 15px 0;
  }
  .user-activity-card .m-b-40 {
    margin-bottom: 10px;
  }

  .blur-user-card .blur-footer [class*=col-] {
    margin: 0 auto;
    border: none;
  }

  .switchery {
    margin-bottom: 20px;
  }

  .switchery-large {
    margin-bottom: 0;
  }
  .switchery-large ~ .switchery-default {
    margin-bottom: 0;
  }

  .switchery-small {
    margin-bottom: 0;
  }

  .js-dynamic-disable, .js-dynamic-enable {
    display: block;
    width: 100%;
  }

  .js-dynamic-disable {
    margin-bottom: 20px;
  }

  .radio-inline, .border-checkbox-section .border-checkbox-group, .checkbox-color {
    display: block;
  }

  .checkbox-fade {
    display: inline-block;
  }

  body.horizontal-icon .page-header, body.horizontal-icon-fixed .page-header {
    margin-top: 0;
  }
  body.horizontal-icon .pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon, body.horizontal-icon-fixed .pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
    display: inline-block !important;
    margin-right: 20px !important;
  }

  .pagination {
    float: inherit;
    text-align: center;
    display: inline-block;
    margin-top: 10px !important;
  }
  .pagination li {
    float: left;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .similar-job .media {
    display: inline-block;
  }
  .similar-job .media .media-body {
    margin-top: 20px;
  }

  .cal-event .inverse-card p:after, .login-card.auth-body, .signin-card.auth-body, .signup-card.auth-body {
    width: 100%;
  }

  .recent-candidate-card .recent-contain {
    margin-left: 10px;
  }

  .author-details .header-right {
    text-align: left;
    margin-top: 20px;
  }

  .post-new-contain textarea:focus {
    border: none;
  }

  .wizard {
    overflow: visible;
  }
  .wizard.vertical > .content, .wizard.vertical > .steps {
    width: 100%;
  }

  .comment-input .input-group {
    display: block;
  }
  .comment-input .input-group i {
    text-align: center;
    width: 100%;
  }
  .comment-input .input-group input {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 640px) {
  .dt-button {
    margin-bottom: 5px;
  }

  .email-read .card-header h6 {
    float: none;
    margin-top: 10px;
  }
  .email-read .media {
    display: inline-block;
  }
  .email-read .media.m-b-20 {
    margin-bottom: 0;
  }
  .email-read .mail-img [class*=col-] {
    margin-bottom: 30px;
  }
  .email-read .mail-img [class*=col-]:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 993px) {
  .navbar {
    width: 100% !important;
  }
  .navbar .navbar-logo {
    width: 100% !important;
  }

  .error-500, .error-503, .error-404, .error-400, .error-403, .login {
    display: block !important;
  }

  .header .login-card {
    margin-top: 80px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 573px) {
  .author-details .header-right li {
    margin-right: 20px;
    display: block;
    margin-top: 20px;
  }

  .md-tabs {
    display: initial;
  }

  .md-below-tabs.md-tabs {
    display: block;
  }

  .md-tabs .nav-item, .nav-tabs .slide {
    width: calc(100% / 1);
  }

  .toolbar-page [class*=col-] .sub-title {
    text-align: center;
  }

  #task-container li {
    width: 100%;
  }

  .nav-tabs.tabs {
    display: inherit;
  }
  .nav-tabs .nav-item.show .nav-link {
    border: none;
  }
  .nav-tabs .nav-link {
    text-align: center;
  }
  .nav-tabs .nav-link.active {
    border: none;
  }

  .search-content {
    display: block;
    text-align: center;
  }
  .search-content img {
    margin-top: 20px;
  }

  .seacrh-header {
    margin-top: 0;
  }
  .seacrh-header .input-group {
    margin-bottom: 0;
  }

  .payment-card .text-right {
    text-align: left !important;
  }

  .payment-tabs .md-tabs .nav-item + .nav-item {
    width: calc(100% / 1);
  }

  .jp-card {
    min-width: 225px !important;
  }

  .payment-form {
    padding: 0;
    padding-top: 20px;
  }

  .modal-mob-btn [class*=col-]:first-child {
    margin-bottom: 20px;
  }

  .location-mob-btn .btn {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .location-mob-btn .btn:last-child {
    margin-bottom: 0;
  }

  .job-cards .media {
    display: inline-block;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .job-cards .media p {
    margin-bottom: 0;
  }

  .invoice-btn-group .m-r-20 {
    margin-right: 0;
  }

  .table-card .row-table {
    display: block;
    text-align: center;
  }
  .table-card .row-table i {
    font-size: 28px;
    margin-bottom: 10px;
    display: inline-block;
  }
  .table-card .row-table .br {
    border: none;
    border-bottom: 1px solid #ccc;
  }
  .table-card .row-table > [class*=col-] {
    display: block;
  }

  .card-block-big {
    padding: 1em;
  }
  .card-block-big #barchart, .card-block-big #barchart2 {
    display: inline-block;
    margin-bottom: 10px;
  }

  .widget-primary-card [class*=col-] {
    padding: 1em;
  }
  .widget-primary-card [class*=col-] h6 {
    margin-bottom: 0;
  }

  .cd-horizontal-timeline .events-content em {
    text-align: left;
  }

  .events-content h2 {
    font-size: 20px;
  }

  .cd-horizontal-timeline {
    margin: 0 auto;
  }

  .social-widget-card i {
    top: 25px;
    font-size: 38px;
  }

  .widget-chat-box .card-header i {
    display: none;
  }

  .mobile-inputs [class*=col-]:first-child {
    margin-bottom: 20px;
  }

  .pagination-lg .page-link {
    padding: 0.75rem 0.65rem;
  }

  .mobtn {
    padding: 10px;
  }
}
@media only screen and (max-width: 460px) {
  .fc-toolbar .fc-right .fc-button-group {
    margin-right: 0;
  }

  .social-msg a {
    margin-bottom: 10px;
  }
  .social-msg a:last-child {
    margin-bottom: 0;
  }

  .bd-example-modal .modal-footer {
    justify-content: center;
  }
  .bd-example-modal .modal-footer button {
    margin-right: 0;
  }

  .invoice-total tbody {
    float: none;
  }

  .timeline-right .card {
    margin-left: 20px;
  }

  .user-box .media-left {
    padding-right: 10px;
  }

  figure.effect-moses h2 {
    width: 60%;
    height: 70%;
    padding: 10px;
    font-size: 25px;
  }
  figure.effect-moses p {
    padding: 5px;
  }
}
@media only screen and (max-width: 480px) {
  .main-body .page-wrapper {
    padding: 10px;
  }

  .blog-page hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .blog-big-user {
    text-align: center;
  }
  .blog-big-user img {
    margin-bottom: 20px;
  }

  .blog-s-reply .media .media-left {
    padding-right: 10px;
  }

  .j-forms .divider-text {
    height: auto;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .j-forms .divider-text span {
    white-space: pre-line;
    display: block;
    line-height: 1.8;
  }
  .j-forms .content {
    padding: 25px 10px 0;
  }

  .comment-block .media-left {
    padding-right: 10px;
  }

  .breadcrumb-page .card {
    text-align: center;
  }

  div.dataTables_wrapper div.dataTables_filter input {
    width: 150px;
  }

  #footer-search tfoot .form-control, #footer-select tfoot .form-control, #form-input-table .form-control, .search-api .form-control, #dt-live-dom .form-control {
    width: 70%;
  }

  .mytooltip .tooltip-content {
    width: 310px;
    margin: 0 0 20px -90px;
  }
  .mytooltip .tooltip-content:after {
    margin-left: -90px;
  }

  .cd-timeline-content .media {
    display: inherit !important;
    padding-left: 20px;
  }
  .cd-timeline-content .media .media-left {
    text-align: center;
    margin: 10px auto;
  }

  .summernote-page .modal-footer {
    display: inline-block;
    text-align: center;
  }
  .summernote-page .modal-footer > :not(:first-child) {
    margin-top: 10px;
    margin-left: 0;
  }

  .product-detail .txt-muted.d-inline-block {
    margin-top: 10px;
  }
  .product-detail .txt-muted.d-inline-block ~ .f-right {
    float: none;
    display: block;
    margin-top: 10px;
  }

  .mob-product-btn {
    text-align: center;
  }
  .mob-product-btn .btn-primary, .mob-product-btn .sweet-alert button.confirm {
    margin-right: 0;
    display: block;
    margin: 10px auto;
  }

  .sweet-alert .mob-product-btn button.confirm, .mob-product-btn .wizard > .actions a, .wizard > .actions .mob-product-btn a {
    margin-right: 0;
    display: block;
    margin: 10px auto;
  }
}
@media only screen and (max-width: 420px) {
  #msform {
    width: 320px;
    margin: 20px auto;
  }
}
@media only screen and (min-width: 576px) and (max-width: 1366px) {
  .job-card.card-columns {
    column-count: 2;
  }
}
@media only screen and (max-width: 576px) {
  .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification, .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
    min-width: 280px;
    left: 0;
    right: auto;
    width: auto;
  }
  .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
    left: auto;
    right: 0;
  }

  .nav-tabs .nav-link.active {
    margin-bottom: 5px;
  }

  .md-tabs .nav-item a {
    /* box-shadow: 0 0 0 1px #d2cece; */
    margin-bottom: 5px;
  }

  .add-task-card .to-do-list h6 span {
    display: inline-block;
  }
}
.badge-top-left {
  margin-right: -10px;
  right: 100%;
  top: -3px;
}

.badge-top-right {
  margin-left: -10px;
  top: -3px;
}

.badge-bottom-left {
  margin-left: -33px;
  bottom: -12px;
  right: 97%;
}

.badge-bottom-right {
  margin-left: -10px;
  bottom: -12px;
}

.label-icon {
  position: relative;
}

.card .md-content h3 {
  background-color: #4680ff;
}

.bar-chart canvas, .tri-state canvas {
  width: 100% !important;
}

.chart-js1 canvas {
  height: 100px !important;
}

.user-box .media-object, .friend-box .media-object {
  border-radius: 5px;
}

/* bootstrap 4-beta css start*/
.pull-0 {
  right: auto;
}

.pull-1 {
  right: 8.333333%;
}

.pull-2 {
  right: 16.666667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.333333%;
}

.pull-5 {
  right: 41.666667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.333333%;
}

.pull-8 {
  right: 66.666667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.333333%;
}

.pull-11 {
  right: 91.666667%;
}

.pull-12 {
  right: 100%;
}

.push-0 {
  left: auto;
}

.push-1 {
  left: 8.333333%;
}

.push-2 {
  left: 16.666667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.333333%;
}

.push-5 {
  left: 41.666667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.333333%;
}

.push-8 {
  left: 66.666667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.333333%;
}

.push-11 {
  left: 91.666667%;
}

.push-12 {
  left: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-sm-0 {
    right: auto;
  }

  .pull-sm-1 {
    right: 8.333333%;
  }

  .pull-sm-2 {
    right: 16.666667%;
  }

  .pull-sm-3 {
    right: 25%;
  }

  .pull-sm-4 {
    right: 33.333333%;
  }

  .pull-sm-5 {
    right: 41.666667%;
  }

  .pull-sm-6 {
    right: 50%;
  }

  .pull-sm-7 {
    right: 58.333333%;
  }

  .pull-sm-8 {
    right: 66.666667%;
  }

  .pull-sm-9 {
    right: 75%;
  }

  .pull-sm-10 {
    right: 83.333333%;
  }

  .pull-sm-11 {
    right: 91.666667%;
  }

  .pull-sm-12 {
    right: 100%;
  }

  .push-sm-0 {
    left: auto;
  }

  .push-sm-1 {
    left: 8.333333%;
  }

  .push-sm-2 {
    left: 16.666667%;
  }

  .push-sm-3 {
    left: 25%;
  }

  .push-sm-4 {
    left: 33.333333%;
  }

  .push-sm-5 {
    left: 41.666667%;
  }

  .push-sm-6 {
    left: 50%;
  }

  .push-sm-7 {
    left: 58.333333%;
  }

  .push-sm-8 {
    left: 66.666667%;
  }

  .push-sm-9 {
    left: 75%;
  }

  .push-sm-10 {
    left: 83.333333%;
  }

  .push-sm-11 {
    left: 91.666667%;
  }

  .push-sm-12 {
    left: 100%;
  }

  .offset-sm-0 {
    margin-left: 0%;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-md-0 {
    right: auto;
  }

  .pull-md-1 {
    right: 8.333333%;
  }

  .pull-md-2 {
    right: 16.666667%;
  }

  .pull-md-3 {
    right: 25%;
  }

  .pull-md-4 {
    right: 33.333333%;
  }

  .pull-md-5 {
    right: 41.666667%;
  }

  .pull-md-6 {
    right: 50%;
  }

  .pull-md-7 {
    right: 58.333333%;
  }

  .pull-md-8 {
    right: 66.666667%;
  }

  .pull-md-9 {
    right: 75%;
  }

  .pull-md-10 {
    right: 83.333333%;
  }

  .pull-md-11 {
    right: 91.666667%;
  }

  .pull-md-12 {
    right: 100%;
  }

  .push-md-0 {
    left: auto;
  }

  .push-md-1 {
    left: 8.333333%;
  }

  .push-md-2 {
    left: 16.666667%;
  }

  .push-md-3 {
    left: 25%;
  }

  .push-md-4 {
    left: 33.333333%;
  }

  .push-md-5 {
    left: 41.666667%;
  }

  .push-md-6 {
    left: 50%;
  }

  .push-md-7 {
    left: 58.333333%;
  }

  .push-md-8 {
    left: 66.666667%;
  }

  .push-md-9 {
    left: 75%;
  }

  .push-md-10 {
    left: 83.333333%;
  }

  .push-md-11 {
    left: 91.666667%;
  }

  .push-md-12 {
    left: 100%;
  }

  .offset-md-0 {
    margin-left: 0%;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-lg-0 {
    right: auto;
  }

  .pull-lg-1 {
    right: 8.333333%;
  }

  .pull-lg-2 {
    right: 16.666667%;
  }

  .pull-lg-3 {
    right: 25%;
  }

  .pull-lg-4 {
    right: 33.333333%;
  }

  .pull-lg-5 {
    right: 41.666667%;
  }

  .pull-lg-6 {
    right: 50%;
  }

  .pull-lg-7 {
    right: 58.333333%;
  }

  .pull-lg-8 {
    right: 66.666667%;
  }

  .pull-lg-9 {
    right: 75%;
  }

  .pull-lg-10 {
    right: 83.333333%;
  }

  .pull-lg-11 {
    right: 91.666667%;
  }

  .pull-lg-12 {
    right: 100%;
  }

  .push-lg-0 {
    left: auto;
  }

  .push-lg-1 {
    left: 8.333333%;
  }

  .push-lg-2 {
    left: 16.666667%;
  }

  .push-lg-3 {
    left: 25%;
  }

  .push-lg-4 {
    left: 33.333333%;
  }

  .push-lg-5 {
    left: 41.666667%;
  }

  .push-lg-6 {
    left: 50%;
  }

  .push-lg-7 {
    left: 58.333333%;
  }

  .push-lg-8 {
    left: 66.666667%;
  }

  .push-lg-9 {
    left: 75%;
  }

  .push-lg-10 {
    left: 83.333333%;
  }

  .push-lg-11 {
    left: 91.666667%;
  }

  .push-lg-12 {
    left: 100%;
  }

  .offset-lg-0 {
    margin-left: 0%;
  }

  .offset-lg-1 {
    margin-left: 8.333333%;
  }

  .offset-lg-2 {
    margin-left: 16.666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.333333%;
  }

  .offset-lg-5 {
    margin-left: 41.666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.333333%;
  }

  .offset-lg-8 {
    margin-left: 66.666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.333333%;
  }

  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pull-xl-0 {
    right: auto;
  }

  .pull-xl-1 {
    right: 8.333333%;
  }

  .pull-xl-2 {
    right: 16.666667%;
  }

  .pull-xl-3 {
    right: 25%;
  }

  .pull-xl-4 {
    right: 33.333333%;
  }

  .pull-xl-5 {
    right: 41.666667%;
  }

  .pull-xl-6 {
    right: 50%;
  }

  .pull-xl-7 {
    right: 58.333333%;
  }

  .pull-xl-8 {
    right: 66.666667%;
  }

  .pull-xl-9 {
    right: 75%;
  }

  .pull-xl-10 {
    right: 83.333333%;
  }

  .pull-xl-11 {
    right: 91.666667%;
  }

  .pull-xl-12 {
    right: 100%;
  }

  .push-xl-0 {
    left: auto;
  }

  .push-xl-1 {
    left: 8.333333%;
  }

  .push-xl-2 {
    left: 16.666667%;
  }

  .push-xl-3 {
    left: 25%;
  }

  .push-xl-4 {
    left: 33.333333%;
  }

  .push-xl-5 {
    left: 41.666667%;
  }

  .push-xl-6 {
    left: 50%;
  }

  .push-xl-7 {
    left: 58.333333%;
  }

  .push-xl-8 {
    left: 66.666667%;
  }

  .push-xl-9 {
    left: 75%;
  }

  .push-xl-10 {
    left: 83.333333%;
  }

  .push-xl-11 {
    left: 91.666667%;
  }

  .push-xl-12 {
    left: 100%;
  }

  .offset-xl-0 {
    margin-left: 0%;
  }

  .offset-xl-1 {
    margin-left: 8.333333%;
  }

  .offset-xl-2 {
    margin-left: 16.666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.333333%;
  }

  .offset-xl-5 {
    margin-left: 41.666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.333333%;
  }

  .offset-xl-8 {
    margin-left: 66.666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.333333%;
  }

  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
/* bootstrap 4-beta css end*/
/* components/advance/notifications*/
#toasty .toast {
  width: auto;
  height: 55px;
}
#toasty .toast .close-button {
  top: calc(50% - 12px);
  opacity: 1;
}
#toasty .toast .toast-text {
  padding: 5px 35px 5px 60px;
}

/* components/advance/notify*/
.small-icon .icon {
  width: auto !important;
  height: auto !important;
}

.no-icon .icon {
  display: none;
}

.simple-notification-wrapper {
  z-index: 10001 !important;
}

/* components/basic/accordion */
.squeezebox .sb-item {
  /*overflow: hidden;*/
}
.squeezebox .sb-item-head a {
  cursor: pointer;
  display: block;
}
.squeezebox .sb-item .sb-item-head a .toggle-icon {
  display: block;
  float: right;
  height: 16px;
  position: relative;
  transform: rotate(45deg);
  transition: 0.3s transform;
  width: 16px;
}
.squeezebox .sb-item.is-collapsed .sb-item-head a .toggle-icon {
  transform: rotate(0);
}
.squeezebox .sb-item-head a .toggle-icon:before {
  background: #000000;
  content: " ";
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px;
}
.squeezebox .sb-item-head a .toggle-icon:after {
  background: #000000;
  content: " ";
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px;
  transform: rotate(90deg);
}
.squeezebox .sb-item .sb-item-body {
  height: 0;
  /*overflow: hidden;*/
  transition: 0.5s height;
}
.squeezebox .sb-item .sb-item-body .inner {
  padding: 10px 0 0 0;
}
.squeezebox .sb-item .sb-item-head a .toggle-icon {
  display: none;
}

.sb-item-head a:focus, .sb-item-head a:hover {
  color: #014c8c !important;
}

.sb-item.is-collapsed .sb-item-head > a {
  color: #000;
}
.sb-item .sb-item-head > a {
  color: #014c8c;
}
.sb-item.is-collapsed .sb-item-head {
  pointer-events: auto;
}
.sb-item .sb-item-head {
  pointer-events: none;
}

.scale-accordion .sb-item .sb-item-head {
  pointer-events: auto;
}

/* components/basic/generic-class */
.md-tabs .nav-link {
  transition: all 0.4s ease-in-out;
  border-bottom: 4px solid transparent;
}
.md-tabs .nav-link.active {
  border-bottom: 4px solid #0073aa;
}

.tab-card .md-tabs {
  position: relative;
  top: -76px;
}
.tab-card .card-header {
  border-bottom: 0px;
}

.md-tabs .nav-link.active {
  border-bottom: 4px solid #0073aa;
}
.md-tabs .nav-link.active:focus, .md-tabs .nav-link.active:hover {
  border-bottom: 4px solid #0073aa;
}

/* components/basic/other */
.pager .btn a {
  color: #fff !important;
}

.pagination-center {
  justify-content: center;
}

.pagination-left {
  justify-content: flex-start;
}

.pagination-right {
  justify-content: flex-end;
}

/* components/basic/tabs */
/* below tab bootstrap start */
.below-tabs {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.below-tabs .nav-tabs, .below-tabs .tab-content {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.below-tabs .nav-tabs {
  border-top: 1px solid #ddd;
  border-bottom: transparent;
}
.below-tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.below-tabs .nav-tabs .nav-item.show .nav-link, .below-tabs .nav-tabs .nav-link.active {
  color: #464a4c;
  background-color: #fff;
  border-color: #fff #ddd #ddd;
  margin-top: -1px;
}

/* below tab bootstrap end */
/* tab material start */
.md-tabs .nav-link {
  transition: all 0.4s ease-in-out;
  border-bottom: 4px solid transparent;
}
.md-tabs .nav-link.active {
  border-bottom: 4px solid #0073aa;
  border-bottom: 4px solid #0073aa;
}
.md-tabs .nav-link.active:focus, .md-tabs .nav-link.active:hover {
  border-bottom: 4px solid #0073aa;
}
.md-tabs.md-below-tabs {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.md-tabs.md-below-tabs .nav-tabs, .md-tabs.md-below-tabs .tab-content {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.md-tabs.md-below-tabs .nav-tabs, .md-tabs.md-below-tabs .nav-item:first-child {
  border-top: 1px solid #ddd;
  border-bottom: 0px solid transparent;
  margin-top: -1px;
}
.md-tabs.md-below-tabs .nav-link {
  transition: all 0.4s ease-in-out;
  border-top: 0px solid transparent;
}
.md-tabs.md-below-tabs .nav-link.active {
  border-top: 4px solid #0073aa;
  border-top: 4px solid #0073aa;
  border-bottom: 0px solid transparent;
}
.md-tabs.md-below-tabs .nav-link.active:focus, .md-tabs.md-below-tabs .nav-link.active:hover {
  border-top: 4px solid #0073aa;
  border-bottom: 0px solid transparent;
}
.md-tabs.md-below-tabs .nav-tabs .nav-link:hover {
  border-top: 4px solid #ddd;
  border-bottom: 0px solid transparent;
}
.md-tabs.md-below-tabs .nav-tabs .nav-link.active:hover {
  border-top: 4px solid #0073aa;
}
.md-tabs.md-left-tabs .nav-item {
  width: 100%;
}
.md-tabs.md-left-tabs .nav-item:first-child {
  border-bottom: 0px;
}
.md-tabs.md-left-tabs .nav-item .nav-link {
  border: 0px solid transparent;
  padding: 10px 0 !important;
  margin: 10px 0;
}
.md-tabs.md-left-tabs .nav-link.active {
  border-left: 4px solid #0073aa;
  border-bottom: 0px solid transparent;
}
.md-tabs.md-left-tabs .nav-link.active:focus, .md-tabs.md-left-tabs .nav-link.active:hover {
  border-left: 4px solid #0073aa;
  border-bottom: 0px solid transparent;
}
.md-tabs.md-left-tabs .nav-item .nav-link.active {
  border: 0px solid transparent;
  border-left: 4px solid #0073aa;
}
.md-tabs.md-left-tabs .nav-tabs {
  border-bottom: 0px solid transparent;
  display: table-cell;
  min-width: 120px;
  vertical-align: top;
  width: 150px;
}
.md-tabs.md-left-tabs .nav-tabs .nav-link.active:hover {
  border-left: 4px solid #0073aa;
}
.md-tabs.md-left-tabs .nav-tabs .nav-link:hover {
  border: 0px solid transparent;
  border-left: 4px solid #ddd;
}
.md-tabs.md-left-tabs .tab-content {
  display: table-cell;
  top: 0;
  position: absolute;
  left: 150px;
}
.md-tabs.md-right-tabs .nav-item {
  width: 100%;
}
.md-tabs.md-right-tabs .nav-item:first-child {
  border-bottom: 0px;
}
.md-tabs.md-right-tabs .nav-item .nav-link {
  border: 0px solid transparent;
  padding: 10px 0 !important;
  margin: 10px 0;
}
.md-tabs.md-right-tabs .nav-link.active {
  border-right: 4px solid #0073aa;
  border-bottom: 0px solid transparent;
}
.md-tabs.md-right-tabs .nav-link.active:focus, .md-tabs.md-right-tabs .nav-link.active:hover {
  border-right: 4px solid #0073aa;
  border-bottom: 0px solid transparent;
}
.md-tabs.md-right-tabs .nav-item .nav-link.active {
  border: 0px solid transparent;
  border-right: 4px solid #0073aa;
}
.md-tabs.md-right-tabs .nav-tabs {
  border-bottom: 0px solid transparent;
  display: table-cell;
  min-width: 120px;
  vertical-align: top;
  width: 150px;
  float: right;
}
.md-tabs.md-right-tabs .nav-tabs .nav-link.active:hover {
  border-right: 4px solid #0073aa;
}
.md-tabs.md-right-tabs .nav-tabs .nav-link:hover {
  border: 0px solid transparent;
  border-right: 4px solid #ddd;
}
.md-tabs.md-right-tabs .tab-content {
  display: table-cell;
  top: 0;
  position: absolute;
  right: 150px;
}
.md-tabs.md-image-tab .nav-link {
  position: relative;
}
.md-tabs.md-image-tab .nav-item, .md-tabs.md-image-tab .nav-link, .md-tabs.md-image-tab .nav-tabs {
  border: 0px solid transparent !important;
}
.md-tabs.md-image-tab img {
  width: 100px;
  border: 4px solid #0073aa;
}
.md-tabs.md-image-tab img + .quote {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  bottom: 10px;
  right: 70px;
}
.md-tabs.md-image-tab .quote i {
  border-radius: 50%;
  padding: 3px 5px;
}
.md-tabs.md-image-tab .nav-link {
  opacity: 0.5;
}
.md-tabs.md-image-tab .nav-link.active {
  opacity: 1;
}

/* tab material end */
/* below tab material start */
/* below tab material end */
/* left tab material start */
@media only screen and (max-width: 768px) {
  .md-tabs.md-left-tabs .nav-tabs {
    width: 100%;
    display: block;
  }
  .md-tabs.md-left-tabs .tab-content {
    display: block;
    position: relative;
    left: 0;
  }
}
/* left tab material end */
/* right tab material start */
@media only screen and (max-width: 768px) {
  .md-tabs.md-right-tabs .nav-tabs {
    width: 100%;
    display: block;
  }
  .md-tabs.md-right-tabs .tab-content {
    display: block;
    position: relative;
    left: 0;
  }
}
/* right tab material end */
/* image tab material start */
/* image tab material end */
/* components/forms/advance-elements */
.switch {
  vertical-align: middle !important;
}

.ng2-tag-input {
  border-bottom: 1px solid #0073aa !important;
  border: 1px solid #0073aa;
  padding: 5px !important;
}

.tag__text {
  line-height: 2;
  font-size: 11px;
  font-weight: 600;
}

delete-icon svg {
  height: 13px !important;
  line-height: 1;
  vertical-align: top !important;
  margin-top: 5px;
}

.ng2-tags-container tag {
  padding: 0 5px !important;
  height: 22px !important;
}

delete-icon:hover {
  transform: none !important;
}

.ng2-tag-input__text-input {
  height: auto !important;
}

.bootstrap tag {
  background: #4680ff !important;
}

/* components/forms/advance-validations */
.tooltip-form {
  position: relative;
}

.tooltip-error {
  position: absolute;
  right: 30px;
  top: 7px;
}

/* components/forms/picker */
/* bootstrap date-picker start */
.custom-range {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-range.focused {
  background-color: #e6e6e6;
}
.custom-range.range, .custom-range:hover {
  background-color: #0275d8;
  color: white;
}

.faded {
  opacity: 0.5;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: 2rem;
}
.custom-day:hover, .custom-day.focused {
  background-color: #e6e6e6;
}

.weekend {
  background-color: #999;
  border-radius: 1rem;
  color: white;
}

.hidden {
  display: none;
}

@media (min-width: 576px) {
  .form-inline .form-group {
    display: block;
    flex: 240px;
  }
}
/* bootstrap date-picker end */
/* material date-picker start */
.md-date-pic {
  position: relative;
  width: 20.5em;
  max-width: 100%;
  border-bottom: 2px solid #ddd;
}
.md-date-pic .cal-cont {
  padding: 0.5rem 0;
  width: 100%;
}
.md-date-pic .datepicker {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.datepicker__calendar {
  top: 2.5em !important;
  max-width: 100%;
  height: auto !important;
  z-index: 1025 !important;
}

@media only screen and (max-width: 573px) {
  .datepicker__calendar__label, .datepicker__calendar__month__day {
    width: 1.4rem !important;
    height: 1.4rem !important;
    line-height: 1.4rem !important;
  }
}
.datepicker .datepicker__input {
  border: none !important;
  width: 100%;
  background-color: transparent !important;
  height: 100%;
}

.cal-icon {
  position: absolute;
  right: 15px;
  z-index: 0;
  top: 15px;
}

.datepicker__calendar__cancel {
  display: none;
}

/* material date-picker end */
/* color-picker start */
#changeme {
  font-size: 30px;
  font-weight: bolder;
  cursor: pointer;
}

.cmyk {
  margin-left: 11px;
}
.cmyk div {
  height: 72px;
  width: 72px;
  line-height: 72px;
  text-align: center;
  float: left;
}
.cmyk span {
  font-weight: bolder;
  text-shadow: 1px 1px 2px #bbb;
}

#color-comparator button {
  height: 26px;
  width: 26px;
  display: block;
  float: left;
  border: none;
  margin: 0;
  padding: 0;
  border: 2px solid #888;
}
#color-comparator > input {
  height: 26px;
  width: 182px;
  display: block;
  float: left;
  border: none;
  margin: 0;
  padding: 0;
}

.array-colors-element {
  width: 100px;
  height: 25px;
  margin-bottom: 15px;
}

/* color-picker end */
/* components/forms/select start */
ng-select.ng-select > div {
  border: none;
}
ng-select.ng-select > div > div.single {
  background-color: #0073aa;
  color: #fff;
  padding: 8px 30px 8px 20px;
  border-radius: 4px;
  height: auto;
}
ng-select.ng-select > div.disabled > div.single {
  background-color: #aaa;
  color: #fff;
}
ng-select.ng-select > div > div.single > div.clear:hover, ng-select.ng-select > div > div.single > div.toggle:hover {
  background-color: transparent;
}
ng-select.ng-select > div > div.single > div.clear {
  color: #fff;
}
ng-select.ng-select > div > div.single > div.toggle {
  color: #fff;
  font-size: 9px;
}
ng-select.ng-select > div > div.single > div.clear, ng-select.ng-select > div > div.single > div.toggle {
  width: 0;
}

.ng-select select-dropdown .highlighted {
  background-color: #0073aa;
}

.famfamfam-flags {
  display: inline-block;
  margin-right: 6px;
  width: 16px;
}

ng-select.ng-select > div > div.multiple {
  border: 1px solid #aaa;
  padding: 6px 10px 6px 10px !important;
  border-radius: 4px;
}
ng-select.ng-select > div > div.multiple > div.option {
  background-color: #0073aa !important;
  border: 1px solid #0073aa !important;
  padding: 5px 15px !important;
  color: #fff !important;
  margin: 0 2px 2px !important;
}
ng-select.ng-select > div > div.multiple > div.option span.deselect-option {
  color: #fff !important;
  font-size: 10px !important;
  vertical-align: middle;
  font-weight: bold;
  margin-right: 2px;
}
ng-select.ng-select > div > div.single > div.placeholder {
  color: #fff;
}
ng-select.ng-select > div > div.single > div.clear {
  font-size: 10px;
  margin-right: 14px;
  font-weight: bold;
}

.tag-select .bootstrap tag {
  padding: 5px 10px !important;
  height: auto !important;
  z-index: 0;
}
.tag-select tag-input-form {
  padding: 6px 10px 6px 10px !important;
}
.tag-select .ng2-tag-input {
  border-bottom: 1px solid #aaa !important;
  border: 1px solid #aaa;
  padding: 5px !important;
  border-radius: 4px;
}

/* components/forms/select end */
/* components/tables/data-table start */
ngx-datatable.data-table {
  border: 1px solid #eceeef;
}

.data-table datatable-header {
  border-bottom: 2px solid #ccc;
}
.data-table datatable-footer {
  border-top: 2px solid #ccc;
  padding: 0 0.75rem;
  background-color: #eceeef;
  box-shadow: inset 0 2px 4px -2px rgba(0, 0, 0, 0.15);
}
.data-table .empty-row {
  padding: 0.75rem;
  text-align: center;
}
.data-table .datatable-row-wrapper:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.data-table datatable-body-cell {
  padding: 0.75rem;
}
.data-table datatable-body-cell + datatable-body-cell {
  border-left: 1px solid #eceeef;
}
.data-table datatable-header-cell .datatable-header-cell-label {
  padding: 0.75rem 30px 0.75rem 0.75rem;
}
.data-table datatable-header-cell + datatable-header-cell {
  border-left: 1px solid #eceeef;
}
.data-table datatable-header-cell.sortable:before, .data-table datatable-header-cell.sortable:after {
  font-family: "IcoFont" !important;
  position: absolute;
  top: 10px;
  color: #ccc;
  font-size: 20px;
}

.datatable-header-cell-wrapper, .datatable-header-cell-label {
  display: block;
}

.data-table datatable-header-cell.sortable:after {
  content: "";
  right: 10px;
}
.data-table datatable-header-cell.sortable:before {
  content: "";
  right: 15px;
}
.data-table datatable-header-cell.sortable.sort-active.sort-desc:after, .data-table datatable-header-cell.sortable.sort-active.sort-asc:before {
  color: #666;
}
.data-table.scroll .datatable-body, .data-table.fullscreen .datatable-body {
  height: 500px !important;
}
.data-table datatable-scroller {
  width: auto !important;
}

.datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 16px;
}
.datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.datatable-footer .datatable-pager {
  margin: 0 10px;
}
.datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.datatable-footer .datatable-pager li.active a {
  background-color: #0073AA;
  color: #fff;
}
.datatable-footer .datatable-pager a {
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}
.datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2);
}
.datatable-footer .datatable-pager .datatable-icon-left, .datatable-footer .datatable-pager .datatable-icon-skip, .datatable-footer .datatable-pager .datatable-icon-right, .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}

.datatable-icon-right:before {
  content: "";
  font-family: "icofont";
  font-style: normal;
}

.datatable-icon-skip:before {
  content: "";
  font-family: "icofont";
  font-style: normal;
}

.datatable-icon-left:before {
  content: "";
  font-family: "icofont";
  font-style: normal;
}

.datatable-icon-prev:before {
  content: "";
  font-family: "icofont";
  font-style: normal;
}

.dt-desc {
  padding: 0.45rem 50px;
}
.dt-desc th, .dt-desc td {
  padding: 0.75rem;
}
.dt-desc table {
  min-width: 400px;
}

.datatable-row-detail {
  background-color: #f9f9f9;
  box-shadow: inset 0px 2px 9px -6px rgba(0, 0, 0, 0.68);
}

.expandable datatable-body-cell + datatable-body-cell, .expandable datatable-header-cell + datatable-header-cell {
  width: calc(calc(100% - 50px) / 5) !important;
}
.expandable .datatable-body-row, .expandable .datatable-header-inner {
  width: 100% !important;
}

.datatable-row-center, .datatable-scroll, .data-table.expandable datatable-scroller {
  width: 100% !important;
}

.ngx-datatable.data-table.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.data-table.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.data-table.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.data-table.multi-selection .datatable-body-row.active .datatable-row-group {
  background: #0073aa !important;
  color: #FFF !important;
}

.selection-cell .datatable-body-cell.active {
  background: #0073aa !important;
  color: #FFF !important;
}

.selected-column label {
  margin-bottom: 5px;
}

.dt-chk label {
  margin-bottom: 0;
}
.dt-chk .datatable-body-cell {
  line-height: 1 !important;
}

.full-data-search {
  margin-left: 0.5em;
  display: inline-block;
  width: auto !important;
}

.full-data-show-entry {
  width: 75px;
  display: inline-block;
}

.full-data-table .glyphicon {
  font-family: IcoFont;
  position: absolute;
  right: 14px;
  font-size: 20px;
  top: 9px;
}
.full-data-table .glyphicon.glyphicon-triangle-bottom:before {
  content: "";
}
.full-data-table .glyphicon.glyphicon-triangle-top:before {
  content: "";
}
.full-data-table th {
  position: relative;
}

/* components/tables/data-table end */
/* components/task end */
.card-body .new-task .fade-in-primary .cr {
  margin-top: 0;
}

.task-right .accordion-block p {
  margin-bottom: 1rem;
}
.task-right .media-left {
  padding-right: 20px;
  padding-left: 2px;
}

.filter-bar .navbar .navbar-nav .dropdown-menu {
  position: absolute;
}

/* components/task end */
/* components/editor/quill editor */
.quill-editor {
  min-height: 16em;
  max-height: 20em;
  overflow-y: auto;
}

/* quill editor end*/
.dropdown-toggle.no-icon::after {
  display: none;
}

.card .card-body.assign-user .dropdown-menu {
  top: 100%;
}

/* components/file-upload/ start */
ngb-progressbar .progress-bar {
  height: 1rem !important;
  color: #fff;
}

.ngb-drop-zone {
  min-height: 1.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: dashed 1px;
  border-radius: 0.125rem;
}

/* components/file-upload/ end */
/* map start */
agm-map, vector_map {
  width: 100%;
  height: 350px;
  display: flex;
}

.jqvmap-label, #jqstooltip {
  position: absolute;
  display: none;
  border: solid 1px #CDCDCD;
  border-radius: 3px;
  background: #292929;
  color: white;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px;
}

.jqvmap-zoomin {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: #292929;
  padding: 4px 10px;
  color: white;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  box-sizing: content-box;
  top: 10px;
}

.jqvmap-zoomout {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: #292929;
  padding: 4px 10px;
  color: white;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  box-sizing: content-box;
  top: 50px;
}

/* map end */
/* widget */
.widget.peity-chart .peity {
  width: 100%;
  height: 75px;
}
.widget.card-body .new-task .fade-in-primary .cr {
  margin-top: 12px;
}

.ui.table td.active, .ui.table tr.active {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.87) inset;
  background: #E0E0E0 !important;
  color: #fff !important;
}

.widget .calendar:focus, .widget .calendar:active {
  outline: none !important;
}
.widget .table td.active, .widget .table tr.active {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.87) inset;
  background: #0073aa !important;
  color: #fff !important;
  /*border-radius: 5px;*/
}
.widget td.link.today, .widget td.link.active.today {
  background-color: #4680ff !important;
  color: #fff !important;
  text-align: center;
  /*border-radius: 5px;*/
}
.widget .table th {
  width: calc(100% / 14);
  border: none !important;
  text-align: center;
}
.widget .table thead tr + tr {
  border-top: 1px solid #ededed;
  border-bottom: 3px solid #ededed;
}
.widget .table td {
  border-top: 1px solid transparent;
  text-align: center;
  margin: 10px 5px;
  padding: 6px;
  width: 35px;
  height: 35px;
  display: inline-block;
  border-radius: 50%;
}
.widget .table td.link {
  cursor: pointer;
}
.widget .day {
  color: #757575;
}
.widget .table tr {
  justify-content: space-evenly;
  display: flex;
}
.widget .table.month td {
  padding: 12px;
  width: 50px;
  height: 50px;
}
.widget .table.year td {
  padding: 12px;
  width: 50px;
  height: 50px;
  padding: 13px 10px;
}
.widget .table.day td:first-child, .widget .table.day td:last-child {
  color: #ff4040;
}
.widget .table.day th:first-child, .widget .table.day th:last-child {
  color: #ff4040;
}
.widget .table thead tr:first-child {
  background-color: #fafafa;
}
.widget .table thead tr:first-child th {
  color: #000;
  width: 100%;
  font-size: 25px;
  color: #757575 !important;
}
.widget .table td.adjacent.disabled {
  opacity: 0.3;
}
.widget table tbody tr:last-child td.today {
  width: 20%;
  border-radius: 6px;
  background-color: #0073aa !important;
}

.pcoded[vertical-nav-type=collapsed] .pcoded-navigatio-lavel {
  display: none;
}

/* animation start */
.main-img {
  position: static !important;
}

/* animation end */
/* authentication start */
.auth-fluid {
  margin: 0 auto;
}

body .pcoded[vertical-placement=right] .showChat_inner, body .pcoded[vertical-placement=right] .users {
  right: 100%;
  left: auto;
}

.to-do-list .delete_todolist {
  opacity: 0;
  color: #ccc;
  font-size: 24px;
  transition: all 0.4s ease-in-out;
}
.to-do-list:hover .delete_todolist {
  opacity: 1;
}

#realtimeupdate {
  height: 190px;
}

.dribble-card .card-header, .fb-card .card-header, .twitter-card .card-header {
  padding-top: 15px;
  padding-bottom: 15px;
}

.dribble-card .card-header i, .fb-card .card-header i, .twitter-card .card-header i {
  border-radius: 50%;
  color: #fff;
  padding: 7px 10px;
  font-size: 25px;
  margin-right: 20px;
  top: -10px;
  position: relative;
}

.dribble-card .card-header h5, .fb-card .card-header h5, .twitter-card .card-header h5 {
  color: #fff;
}

.dribble-card .card-header span, .fb-card .card-header span, .twitter-card .card-header span {
  font-weight: 100;
  color: #fff;
}

.dribble-card .card-header + .card-block, .fb-card .card-header + .card-block, .twitter-card .card-header + .card-block {
  padding-top: 20px;
}

.dribble-card .card-header + .card-block h2, .fb-card .card-header + .card-block h2, .twitter-card .card-header + .card-block h2 {
  font-weight: 600;
}

.dribble-card .card-header {
  background-color: #f178a7;
}
.dribble-card .card-header i {
  background-color: #EC4A89;
}

.fb-card .card-header {
  background-color: #4c70b9;
}
.fb-card .card-header i {
  background-color: #3B5997;
}

.twitter-card .card-header {
  background-color: #74d1fc;
}
.twitter-card .card-header i {
  background-color: #42C0FB;
}

.dribble-card .card-block p, .fb-card .card-block p, .twitter-card .card-block p {
  margin-bottom: 0;
}

.table-hover tbody tr:hover {
  cursor: pointer;
}

.done-task span, .done-task .captions {
  color: #919aa3;
  text-decoration: line-through;
}

.header-navbar .navbar-wrapper .navbar-container .lng-dropdown img {
  width: 25px !important;
  height: 15px;
}

.pcoded #styleSelector a > i {
  padding: 0;
  margin: 0;
  margin-right: 5px;
}
.pcoded #styleSelector .btn {
  padding: 10px 18px;
}
.pcoded #styleSelector .nav-link {
  padding: 0.5rem 1rem !important;
}

.card-header-right {
  z-index: 100 !important;
}

.card-contact-box .f-btn:hover {
  background-color: #f1f1f1;
}
.card-contact-box img {
  border-radius: 5px;
}

.client-map .client-detail img, .widget-card-user .card-user img {
  border-radius: 5px;
}

.p-chat-user .slimScrollDiv {
  overflow: visible !important;
}

.model-btn-card ul li {
  display: inline-block;
}

.img-radius {
  border-radius: 5px;
}

.slimScrollDiv, .sidebar, .main-friend-list {
  height: calc(100vh - 155px) !important;
}

#styleSelector .slimScrollDiv, #styleSelector .sidebar, #styleSelector .main-friend-list {
  height: calc(80vh - 335px) !important;
}

/* dark layout css start*/
.pcoded[layout-type=dark], body.dark, .pcoded[layout-type=dark] a, body.dark a {
  color: #fff;
}

.pcoded[layout-type=dark] text, body.dark text {
  fill: #fff;
}

.pcoded[layout-type=dark] .card, body.dark .card {
  background-color: #3a4057;
}

.pcoded[layout-type=dark] .card code, body.dark .card code {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .card .card-header h5, body.dark .card .card-header h5, .pcoded[layout-type=dark] .card .card-header .card-header-right i, body.dark .card .card-header .card-header-right i {
  color: #fff;
}

.pcoded[layout-type=dark] .card .card-block code, body.dark .card .card-block code {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .card .card-block .dropdown-menu, body.dark .card .card-block .dropdown-menu {
  background-color: #3e455d;
}

.pcoded[layout-type=dark] .card .card-footer, body.dark .card .card-footer {
  background-color: #363c51;
}

.pcoded[layout-type=dark] .dropdown-menu, body.dark .dropdown-menu {
  background-color: #3e455d;
}

.pcoded[layout-type=dark] .dropdown-menu .dropdown-item .dropdown-divider, body.dark .dropdown-menu .dropdown-item .dropdown-divider {
  border-top: 1px solid #303548;
}

.pcoded[layout-type=dark] .dropdown-menu .dropdown-item:focus, .pcoded[layout-type=dark] .dropdown-menu .dropdown-item:hover {
  background-color: #303548;
}

body.dark .dropdown-menu .dropdown-item:focus, body.dark .dropdown-menu .dropdown-item:hover {
  background-color: #303548;
}

.pcoded[layout-type=dark] .sub-title, body.dark .sub-title {
  color: #596285;
}

.pcoded[layout-type=dark] .table td, .pcoded[layout-type=dark] .table th {
  border-top: 1px solid #444c67;
}

body.dark .table td, body.dark .table th {
  border-top: 1px solid #444c67;
}

.pcoded[layout-type=dark] .table thead th, body.dark .table thead th {
  border-bottom: 2px solid #444c67;
}

.pcoded[layout-type=dark] .thumb-block, body.dark .thumb-block {
  border: 1px solid #444c67;
}

.pcoded[layout-type=dark] .b-t-default, .pcoded[layout-type=dark] .b-t-theme, .pcoded[layout-type=dark] .post-new-footer, .pcoded[layout-type=dark] .version .nav li:first-child {
  border-top: 1px solid #444c67;
}

body.dark .b-t-default, body.dark .b-t-theme, body.dark .post-new-footer, body.dark .version .nav li:first-child {
  border-top: 1px solid #444c67;
}

.pcoded[layout-type=dark] .b-b-default, .pcoded[layout-type=dark] .b-b-theme, .pcoded[layout-type=dark] .job-cards .media, .pcoded[layout-type=dark] .search-content {
  border-bottom: 1px solid #444c67;
}

body.dark .b-b-default, body.dark .b-b-theme, body.dark .job-cards .media, body.dark .search-content {
  border-bottom: 1px solid #444c67;
}

.pcoded[layout-type=dark] .b-l-default, .pcoded[layout-type=dark] .b-l-theme {
  border-left: 1px solid #444c67;
}

body.dark .b-l-default, body.dark .b-l-theme {
  border-left: 1px solid #444c67;
}

.pcoded[layout-type=dark] .b-r-default, .pcoded[layout-type=dark] .b-r-theme, .pcoded[layout-type=dark] .messages-content {
  border-right: 1px solid #444c67;
}

body.dark .b-r-default, body.dark .b-r-theme, body.dark .messages-content {
  border-right: 1px solid #444c67;
}

.pcoded[layout-type=dark] .progress, body.dark .progress {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .btn-default, body.dark .btn-default {
  background-color: #444c67;
  color: #fff;
}

.pcoded[layout-type=dark] .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, body.dark .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #444c67 !important;
}

.pcoded[layout-type=dark] .pcoded-main-container, body.dark .pcoded-main-container {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(2) .show-notification li:first-child:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .nav-right > .mega-menu-top:nth-child(2) .show-notification li:first-child:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(2) .profile-notification li:first-child:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .nav-right > .mega-menu-top:nth-child(2) .profile-notification li:first-child:hover {
  background-color: #303548 !important;
}

body.dark .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(2) .show-notification li:first-child:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .nav-right > .mega-menu-top:nth-child(2) .show-notification li:first-child:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .nav-right > .header-notification:nth-child(2) .profile-notification li:first-child:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .nav-right > .mega-menu-top:nth-child(2) .profile-notification li:first-child:hover {
  background-color: #303548 !important;
}

.pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
  background-color: #303548;
}
.pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
  background-color: #303548;
}

body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification, body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification, body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification {
  background-color: #303548;
}
body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
  background-color: #303548;
}

.pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a {
  color: #fff;
}
.pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a {
  color: #fff;
}

body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a, body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification a, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification a, body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a {
  color: #fff;
}
body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification a, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a {
  color: #fff;
}

.pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.row li:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover {
  background-color: transparent;
}
.pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover {
  background-color: transparent;
}

body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.row li:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover {
  background-color: transparent;
}
body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover {
  background-color: transparent;
}

.pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover .mega-menu-links > li:hover a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover .mega-menu-links > li:hover a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.row li:hover .mega-menu-links > li:hover a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover .mega-menu-links > li:hover a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover .mega-menu-links > li:hover a {
  color: #4680ff;
}
.pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover .mega-menu-links > li:hover a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover .mega-menu-links > li:hover a, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover .mega-menu-links > li:hover a {
  color: #4680ff;
}

body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover .mega-menu-links > li:hover a, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover .mega-menu-links > li:hover a, body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification.row li:hover .mega-menu-links > li:hover a, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover .mega-menu-links > li:hover a, body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .row.profile-notification li:hover .mega-menu-links > li:hover a {
  color: #4680ff;
}
body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover .mega-menu-links > li:hover a, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification.row li:hover .mega-menu-links > li:hover a, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .row.profile-notification li:hover .mega-menu-links > li:hover a {
  color: #4680ff;
}

.pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover, .pcoded[layout-type=dark] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:hover {
  background-color: #444c67;
}

body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:hover, body.dark .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:hover {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .header-navbar .morphsearch.open, body.dark .header-navbar .morphsearch.open {
  background-color: #303548;
}

.pcoded[layout-type=dark] .header-navbar .morphsearch.open .morphsearch-form, body.dark .header-navbar .morphsearch.open .morphsearch-form {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .header-navbar .morphsearch.open .morphsearch-content, body.dark .header-navbar .morphsearch.open .morphsearch-content {
  background-color: #303548;
}

.pcoded[layout-type=dark] .header-navbar .morphsearch.open .morphsearch-content .dummy-media-object, body.dark .header-navbar .morphsearch.open .morphsearch-content .dummy-media-object {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .p-chat-user, body.dark .p-chat-user {
  border-left: 1px solid #1c1e29;
}

.pcoded[layout-type=dark] .p-chat-user .userlist-box, body.dark .p-chat-user .userlist-box {
  border-bottom: 1px solid #1c1e29;
}

.pcoded[layout-type=dark] .p-chat-user .userlist-box .chat-header, body.dark .p-chat-user .userlist-box .chat-header {
  color: #fff;
}

.pcoded[layout-type=dark] .showChat_inner, body.dark .showChat_inner {
  background-color: #444c67;
  border-left: 1px solid #1c1e29;
}

.pcoded[layout-type=dark] .showChat_inner .chat-menu-content, body.dark .showChat_inner .chat-menu-content {
  background-color: #303548;
}

.pcoded[layout-type=dark] .showChat_inner .chat-menu-content:before, body.dark .showChat_inner .chat-menu-content:before {
  border-right-color: #303548;
}

.pcoded[layout-type=dark] .showChat_inner .chat-inner-header, body.dark .showChat_inner .chat-inner-header {
  border-bottom: 1px solid #303548;
}

.pcoded[layout-type=dark] .showChat_inner .chat-menu-reply, body.dark .showChat_inner .chat-menu-reply {
  background-color: #1c1e29;
}

.pcoded[layout-type=dark] .showChat_inner .chat-menu-reply:before, body.dark .showChat_inner .chat-menu-reply:before {
  border-left-color: #1c1e29;
}

.pcoded[layout-type=dark] .showChat_inner .chat-reply-box, body.dark .showChat_inner .chat-reply-box {
  background-color: #444c67;
  border-top: 1px solid #303548;
}

.pcoded[layout-type=dark] .form-control, body.dark .form-control {
  background-color: #444c67;
  color: #fff;
}

.pcoded[layout-type=dark] .main-body .page-wrapper .page-header-title h4, body.dark .main-body .page-wrapper .page-header-title h4 {
  color: #fff;
}

.pcoded[layout-type=dark] .profile-comp-block input.dial, .pcoded[layout-type=dark] .view-card input.dial {
  color: #fff !important;
}

body.dark .profile-comp-block input.dial, body.dark .view-card input.dial {
  color: #fff !important;
}

.pcoded[layout-type=dark] .line-example, body.dark .line-example {
  border-right: 1px solid #444c67;
}

.pcoded[layout-type=dark] .marketing-card .table tbody .marketing-header, body.dark .marketing-card .table tbody .marketing-header {
  background: #1c1e29;
}

.pcoded[layout-type=dark] .unique-visitor-card .footer-card, body.dark .unique-visitor-card .footer-card {
  background-color: #1c1e29;
}

.pcoded[layout-type=dark] .user-detail-card .user-detail > div, body.dark .user-detail-card .user-detail > div {
  border-bottom: 1px solid #444c67;
}

.pcoded[layout-type=dark] .user-detail-card .user-detail > div:last-child, body.dark .user-detail-card .user-detail > div:last-child {
  border-bottom: none;
}

.pcoded[layout-type=dark] .client-map .client-contain h5, body.dark .client-map .client-contain h5 {
  color: #fff;
}

.pcoded[layout-type=dark] .client-map .client-card-box, body.dark .client-map .client-card-box {
  border: 1px solid #444c67;
}

.pcoded[layout-type=dark] .client-map .client-card-box .client-border, body.dark .client-map .client-card-box .client-border {
  border-right: 1px solid #444c67;
}

.pcoded[layout-type=dark] .client-map .client-card-box .client-border-card, body.dark .client-map .client-card-box .client-border-card {
  border-top: 1px solid #444c67;
}

.pcoded[layout-type=dark] .widget-card-user .card-footer, body.dark .widget-card-user .card-footer {
  background-color: #1c1e29;
}

.pcoded[layout-type=dark] .widget-card-user .card-footer .footer-menu + .footer-menu, body.dark .widget-card-user .card-footer .footer-menu + .footer-menu {
  border-left: 2px solid #444c67;
}

.pcoded[layout-type=dark] .card-contact-box .f-btn:hover, body.dark .card-contact-box .f-btn:hover {
  background-color: #3a4057;
}

.pcoded[layout-type=dark] .to-do-list, body.dark .to-do-list {
  border-bottom: 1px solid #444c67;
}

.pcoded[layout-type=dark] .widget-chat-box .media .send-chat, .pcoded[layout-type=dark] .widget-chat-box .media .receive-chat {
  background-color: #444c67;
}

body.dark .widget-chat-box .media .send-chat, body.dark .widget-chat-box .media .receive-chat {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .widget-chat-box .media .send-chat:before, .pcoded[layout-type=dark] .widget-chat-box .media .receive-chat:before {
  border-right: 15px solid #444c67;
}

body.dark .widget-chat-box .media .send-chat:before, body.dark .widget-chat-box .media .receive-chat:before {
  border-right: 15px solid #444c67;
}

.pcoded[layout-type=dark] .widget-chat-box .media .receive-chat, body.dark .widget-chat-box .media .receive-chat {
  background-color: #1c1e29;
}

.pcoded[layout-type=dark] .widget-chat-box .media .receive-chat:before, body.dark .widget-chat-box .media .receive-chat:before {
  border-left: 15px solid #1c1e29;
}

.pcoded[layout-type=dark] .card-comment .card-block-small, body.dark .card-comment .card-block-small {
  border-bottom: 1px solid #3a4057;
}

.pcoded[layout-type=dark] .card-comment .card-block-small:hover, body.dark .card-comment .card-block-small:hover {
  background-color: #3a4057;
}

.pcoded[layout-type=dark] .alert.alert-danger, .pcoded[layout-type=dark] .alert.alert-default, .pcoded[layout-type=dark] .alert.alert-info, .pcoded[layout-type=dark] .alert.alert-primary, .pcoded[layout-type=dark] .alert.alert-success, .pcoded[layout-type=dark] .alert.alert-warning {
  background-color: #303548;
}

body.dark .alert.alert-danger, body.dark .alert.alert-default, body.dark .alert.alert-info, body.dark .alert.alert-primary, body.dark .alert.alert-success, body.dark .alert.alert-warning {
  background-color: #303548;
}

.pcoded[layout-type=dark] .alert.background-default, body.dark .alert.background-default {
  background-color: #e0e0e0;
  color: #fff;
}

.pcoded[layout-type=dark] .alert.background-primary, body.dark .alert.background-primary {
  background-color: #4680ff;
  color: #fff;
}

.pcoded[layout-type=dark] .alert.background-success, body.dark .alert.background-success {
  background-color: #93BE52;
  color: #fff;
}

.pcoded[layout-type=dark] .alert.background-info, body.dark .alert.background-info {
  background-color: #62d1f3;
  color: #fff;
}

.pcoded[layout-type=dark] .alert.background-warning, body.dark .alert.background-warning {
  background-color: #FFB64D;
  color: #fff;
}

.pcoded[layout-type=dark] .alert.background-danger, body.dark .alert.background-danger {
  background-color: #FC6180;
  color: #fff;
}

.pcoded[layout-type=dark] .accordion-msg, body.dark .accordion-msg {
  color: #fff;
  border-top: 1px solid #444c67;
}

.pcoded[layout-type=dark] .accordion-desc, body.dark .accordion-desc, .pcoded[layout-type=dark] .md-tabs .nav-item a, body.dark .md-tabs .nav-item a {
  color: #fff;
}

.pcoded[layout-type=dark] .md-tabs .nav-item a.active, .pcoded[layout-type=dark] .md-tabs .nav-item a:focus, .pcoded[layout-type=dark] .md-tabs .nav-item a:hover {
  color: #4680ff;
}

body.dark .md-tabs .nav-item a.active, body.dark .md-tabs .nav-item a:focus, body.dark .md-tabs .nav-item a:hover {
  color: #4680ff;
}

.pcoded[layout-type=dark] .md-tabs .nav-item a.active:focus, .pcoded[layout-type=dark] .md-tabs .nav-item a.active:hover {
  color: #4680ff;
}

body.dark .md-tabs .nav-item a.active:focus, body.dark .md-tabs .nav-item a.active:hover {
  color: #4680ff;
}

.pcoded[layout-type=dark] .nav-tabs, body.dark .nav-tabs {
  border-bottom: 1px solid #444c67;
}

.pcoded[layout-type=dark] .nav-tabs .nav-link:focus, .pcoded[layout-type=dark] .nav-tabs .nav-link:hover {
  border-color: #444c67;
}

body.dark .nav-tabs .nav-link:focus, body.dark .nav-tabs .nav-link:hover {
  border-color: #444c67;
}

.pcoded[layout-type=dark] .tab-below, body.dark .tab-below {
  border-top: 1px solid #444c67;
  border-bottom: none;
}

.pcoded[layout-type=dark] .tab-below .nav-link, body.dark .tab-below .nav-link {
  margin-top: 1px;
}

.pcoded[layout-type=dark] .scroll-list li, body.dark .scroll-list li {
  background-color: #3e455d;
  color: #fff;
}

.pcoded[layout-type=dark] .scroll-list li:nth-child(even), body.dark .scroll-list li:nth-child(even) {
  background-color: #48506d;
}

.pcoded[layout-type=dark] .panel, body.dark .panel {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .panel .panel-footer, body.dark .panel .panel-footer {
  background-color: #3e455d;
  border-top: 1px solid #222533;
}

.pcoded[layout-type=dark] .well, body.dark .well {
  background-color: #222533;
  border: 1px solid #444c67;
}

.pcoded[layout-type=dark] .page-link, body.dark .page-link {
  background-color: #444c67;
  border-color: #222533;
}

.pcoded[layout-type=dark] .jqpagination input, body.dark .jqpagination input {
  margin: 0;
  height: 30px;
}

.pcoded[layout-type=dark] .modal-content, body.dark .modal-content {
  background-color: #3e455d;
}

.pcoded[layout-type=dark] .modal-content .modal-header, body.dark .modal-content .modal-header {
  border-bottom: 1px solid #303548;
}

.pcoded[layout-type=dark] .modal-content .modal-footer, body.dark .modal-content .modal-footer {
  border-top: 1px solid #303548;
}

.pcoded[layout-type=dark] .br-theme-bars-square .br-widget a, body.dark .br-theme-bars-square .br-widget a {
  background-color: #3e455d;
}

.pcoded[layout-type=dark] .jstree-anchor.jstree-hovered, body.dark .jstree-anchor.jstree-hovered {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .dd-handle, body.dark .dd-handle {
  color: #fff;
}

.pcoded[layout-type=dark] .dd3-content, body.dark .dd3-content {
  color: #fff;
  border: 1px solid #303548;
  background-color: #444c67;
}

.pcoded[layout-type=dark] .img-thumbnail, body.dark .img-thumbnail {
  background-color: #303548;
  border: 1px solid #222533;
}

.pcoded[layout-type=dark] .dotted-line-theme .no_edit:hover, body.dark .dotted-line-theme .no_edit:hover {
  background-color: #444c67;
  border-bottom: 1px solid #303548;
}

.pcoded[layout-type=dark] .icon-list-demo i, body.dark .icon-list-demo i, .pcoded[layout-type=dark] .form-radio label, body.dark .form-radio label {
  color: #fff;
}

.pcoded[layout-type=dark] .bootstrap-tagsinput, body.dark .bootstrap-tagsinput {
  background-color: #444c67;
}

.pcoded[layout-type=dark] #reportrange, body.dark #reportrange {
  background-color: #444c67 !important;
}

.pcoded[layout-type=dark] .select2-container--default .select2-selection--multiple, .pcoded[layout-type=dark] .select2-dropdown {
  background-color: #3e455d;
}

body.dark .select2-container--default .select2-selection--multiple, body.dark .select2-dropdown {
  background-color: #3e455d;
}

.pcoded[layout-type=dark] .ms-container .ms-selectable, .pcoded[layout-type=dark] .ms-container .ms-selection {
  background-color: #3e455d;
}

body.dark .ms-container .ms-selectable, body.dark .ms-container .ms-selection {
  background-color: #3e455d;
}

.pcoded[layout-type=dark] .ms-container .ms-selectable li.ms-elem-selectable, .pcoded[layout-type=dark] .ms-container .ms-selection li.ms-elem-selectable {
  color: #fff;
}

body.dark .ms-container .ms-selectable li.ms-elem-selectable, body.dark .ms-container .ms-selection li.ms-elem-selectable {
  color: #fff;
}

.pcoded[layout-type=dark] .ms-container .ms-selectable li.disabled, .pcoded[layout-type=dark] .ms-container .ms-selection li.disabled {
  background-color: #3e455d;
  opacity: 0.5;
}

body.dark .ms-container .ms-selectable li.disabled, body.dark .ms-container .ms-selection li.disabled {
  background-color: #3e455d;
  opacity: 0.5;
}

.pcoded[layout-type=dark] .wizard > .content, body.dark .wizard > .content {
  background-color: #3a4057;
}

.pcoded[layout-type=dark] .wizard > .steps .disabled a {
  background-color: #3e455d;
}
.pcoded[layout-type=dark] .wizard > .steps .disabled a:active, .pcoded[layout-type=dark] .wizard > .steps .disabled a:hover {
  background-color: #3e455d;
}

body.dark .wizard > .steps .disabled a {
  background-color: #3e455d;
}
body.dark .wizard > .steps .disabled a:active, body.dark .wizard > .steps .disabled a:hover {
  background-color: #3e455d;
}

.pcoded[layout-type=dark] .wizard > .steps .done a, body.dark .wizard > .steps .done a {
  background-color: #303548;
}

.pcoded[layout-type=dark] .table-bordered, body.dark .table-bordered {
  border: 1px solid #444c67;
}

.pcoded[layout-type=dark] .table-bordered td, .pcoded[layout-type=dark] .table-bordered th {
  border: 1px solid #444c67;
}

body.dark .table-bordered td, body.dark .table-bordered th {
  border: 1px solid #444c67;
}

.pcoded[layout-type=dark] a.dt-button.disabled, .pcoded[layout-type=dark] button.dt-button.disabled, .pcoded[layout-type=dark] div.dt-button.disabled {
  background-image: none;
  opacity: 0.5;
}

body.dark a.dt-button.disabled, body.dark button.dt-button.disabled, body.dark div.dt-button.disabled {
  background-image: none;
  opacity: 0.5;
}

.pcoded[layout-type=dark] .fixedHeader-floating, body.dark .fixedHeader-floating {
  background-color: #444c67;
}

.pcoded[layout-type=dark] table.DTFC_Cloned tfoot, .pcoded[layout-type=dark] table.DTFC_Cloned thead {
  background-color: #3e455d;
}

body.dark table.DTFC_Cloned tfoot, body.dark table.DTFC_Cloned thead {
  background-color: #3e455d;
}

.pcoded[layout-type=dark] .dt-responsive input, body.dark .dt-responsive input {
  background-color: #444c67;
  border: 1px solid #3a4057;
}

.pcoded[layout-type=dark] .footable-details.table-striped > tbody > tr:nth-child(odd), .pcoded[layout-type=dark] .footable.table-striped > tbody > tr:nth-child(odd) {
  background: #3a4057;
}

body.dark .footable-details.table-striped > tbody > tr:nth-child(odd), body.dark .footable.table-striped > tbody > tr:nth-child(odd) {
  background: #3a4057;
}

.pcoded[layout-type=dark] .footable-details.table > thead > tr > td, .pcoded[layout-type=dark] .footable-details.table > thead > tr > th {
  border-bottom: 2px solid #303548;
}
.pcoded[layout-type=dark] .footable.table > thead > tr > td, .pcoded[layout-type=dark] .footable.table > thead > tr > th {
  border-bottom: 2px solid #303548;
}

body.dark .footable-details.table > thead > tr > td, body.dark .footable-details.table > thead > tr > th {
  border-bottom: 2px solid #303548;
}
body.dark .footable.table > thead > tr > td, body.dark .footable.table > thead > tr > th {
  border-bottom: 2px solid #303548;
}

.pcoded[layout-type=dark] .c3-tooltip td, .pcoded[layout-type=dark] .morris-hover {
  background-color: #303548;
}

body.dark .c3-tooltip td, body.dark .morris-hover {
  background-color: #303548;
}

.pcoded[layout-type=dark] #chart_Candlestick rect, .pcoded[layout-type=dark] #chart_Combo rect, .pcoded[layout-type=dark] #chart_Donut rect, .pcoded[layout-type=dark] #chart_Exploading rect, .pcoded[layout-type=dark] #chart_Threshold rect, .pcoded[layout-type=dark] #chart_Waterfall rect, .pcoded[layout-type=dark] #chart_area rect, .pcoded[layout-type=dark] #chart_bar rect, .pcoded[layout-type=dark] #chart_bubble rect, .pcoded[layout-type=dark] #chart_stacking rect {
  fill: #3a4057 !important;
}

body.dark #chart_Candlestick rect, body.dark #chart_Combo rect, body.dark #chart_Donut rect, body.dark #chart_Exploading rect, body.dark #chart_Threshold rect, body.dark #chart_Waterfall rect, body.dark #chart_area rect, body.dark #chart_bar rect, body.dark #chart_bubble rect, body.dark #chart_stacking rect {
  fill: #3a4057 !important;
}

.pcoded[layout-type=dark] .auth-box .form-control, body.dark .auth-box .form-control {
  background-color: #fff;
}

.pcoded[layout-type=dark] .chat-header, body.dark .chat-header {
  color: #fff;
}

.pcoded[layout-type=dark] .social-msg a span, body.dark .social-msg a span {
  color: #f2f2f2;
}

.pcoded[layout-type=dark] .bg-white, body.dark .bg-white {
  background: #3a4057 !important;
}

.pcoded[layout-type=dark] .list-group-item, body.dark .list-group-item {
  background: #3a4057;
}

.pcoded[layout-type=dark] ul.list-contacts a, body.dark ul.list-contacts a {
  color: #fff;
}

.pcoded[layout-type=dark] .card-header-img h5, .pcoded[layout-type=dark] .card-header-img h6 {
  color: #fff;
}

body.dark .card-header-img h5, body.dark .card-header-img h6 {
  color: #fff;
}

.pcoded[layout-type=dark] .prod-item .prod-info, body.dark .prod-item .prod-info, .pcoded[layout-type=dark] .email-card .user-body, body.dark .email-card .user-body {
  background: #3a4057;
}

.pcoded[layout-type=dark] .email-card .user-body .page-list li.mail-section a, body.dark .email-card .user-body .page-list li.mail-section a {
  color: #fff;
}

.pcoded[layout-type=dark] .email-card .user-body .page-list li.mail-section a.active, .pcoded[layout-type=dark] .email-card .user-body .page-list li.mail-section a:hover {
  background: rgba(255, 255, 255, 0.2);
}

body.dark .email-card .user-body .page-list li.mail-section a.active, body.dark .email-card .user-body .page-list li.mail-section a:hover {
  background: rgba(255, 255, 255, 0.2);
}

.pcoded[layout-type=dark] .email-card .user-body .label-list a, body.dark .email-card .user-body .label-list a {
  color: #fff;
}

.pcoded[layout-type=dark] .email-card .mail-body-content tr:hover, body.dark .email-card .mail-body-content tr:hover {
  background: rgba(255, 255, 255, 0.2);
}

.pcoded[layout-type=dark] .email-card .mail-body-content .unread a, body.dark .email-card .mail-body-content .unread a {
  color: #fff;
}

.pcoded[layout-type=dark] .email-card .mail-body-content .read a, body.dark .email-card .mail-body-content .read a {
  color: #e6e6e6;
}

.pcoded[layout-type=dark] .email-card .user-mail h6, body.dark .email-card .user-mail h6 {
  color: #fff;
}

.pcoded[layout-type=dark] .wall-img-preview .wall-item, body.dark .wall-img-preview .wall-item {
  border: 2px solid #3a4057;
}

.pcoded[layout-type=dark] .msg-send, body.dark .msg-send {
  background: #404760;
}

.pcoded[layout-type=dark] .filter-bar > .navbar, body.dark .filter-bar > .navbar {
  background: #3a4057;
}

.pcoded[layout-type=dark] .navbar-light .navbar-nav .active > .nav-link {
  color: #fff;
}
.pcoded[layout-type=dark] .navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.pcoded[layout-type=dark] .navbar-light .navbar-nav .nav-link.active, .pcoded[layout-type=dark] .navbar-light .navbar-nav .nav-link.show {
  color: #fff;
}
.pcoded[layout-type=dark] .navbar-light .navbar-nav .show > .nav-link {
  color: #fff;
}

body.dark .navbar-light .navbar-nav .active > .nav-link {
  color: #fff;
}
body.dark .navbar-light .navbar-nav .nav-link {
  color: #fff;
}
body.dark .navbar-light .navbar-nav .nav-link.active, body.dark .navbar-light .navbar-nav .nav-link.show {
  color: #fff;
}
body.dark .navbar-light .navbar-nav .show > .nav-link {
  color: #fff;
}

.pcoded[layout-type=dark] #task-container li, body.dark #task-container li {
  background: #444c67;
  color: #fff;
}

.pcoded[layout-type=dark] .text-inverse, body.dark .text-inverse {
  color: #fff !important;
}

.pcoded[layout-type=dark] .note-card .note-write, body.dark .note-card .note-write {
  background: -webkit-linear-gradient(top, #596285 0%, #717ba0 5%) 0 0;
  background-size: 100% 35px;
}

.pcoded[layout-type=dark] .note-card .note-box-aside, body.dark .note-card .note-box-aside {
  border-right: 1px solid #444c67;
}

.pcoded[layout-type=dark] .invoice-table, body.dark .invoice-table {
  border-left: none;
}

.pcoded[layout-type=dark] .invoice-total, .pcoded[layout-type=dark] .thead-default th {
  background: #303548;
}

body.dark .invoice-total, body.dark .thead-default th {
  background: #303548;
}

.pcoded[layout-type=dark] #external-events .fc-event, .pcoded[layout-type=dark] .danger-nav, .pcoded[layout-type=dark] .fc-state-default, .pcoded[layout-type=dark] .info-nav, .pcoded[layout-type=dark] .light-nav-border, .pcoded[layout-type=dark] .primary-nav, .pcoded[layout-type=dark] .warning-nav, .pcoded[layout-type=dark] .success-nav, .pcoded[layout-type=dark] .info-nav, .pcoded[layout-type=dark] .danger-nav, .pcoded[layout-type=dark] .primary-nav, .pcoded[layout-type=dark] .success-nav, .pcoded[layout-type=dark] .warning-nav {
  background-color: #444c67;
  color: #fff;
}

body.dark #external-events .fc-event, body.dark .danger-nav, body.dark .fc-state-default, body.dark .info-nav, body.dark .light-nav-border, body.dark .primary-nav, body.dark .warning-nav, body.dark .success-nav, body.dark .info-nav, body.dark .danger-nav, body.dark .primary-nav, body.dark .success-nav, body.dark .warning-nav {
  background-color: #444c67;
  color: #fff;
}

.pcoded[layout-type=dark] .jFiler-input-dragDrop, .pcoded[layout-type=dark] .jFiler-theme-default .jFiler-input {
  background-color: #363c51;
}

body.dark .jFiler-input-dragDrop, body.dark .jFiler-theme-default .jFiler-input {
  background-color: #363c51;
}

.pcoded[layout-type=dark] .danger-nav .navbar-varient-submenu, .pcoded[layout-type=dark] .info-nav .navbar-varient-submenu, .pcoded[layout-type=dark] .light-nav-border .navbar-varient-submenu, .pcoded[layout-type=dark] .primary-nav .navbar-varient-submenu, .pcoded[layout-type=dark] .warning-nav .navbar-varient-submenu, .pcoded[layout-type=dark] .success-nav .navbar-varient-submenu, .pcoded[layout-type=dark] .info-nav .navbar-varient-submenu, .pcoded[layout-type=dark] .danger-nav .navbar-varient-submenu, .pcoded[layout-type=dark] .primary-nav .navbar-varient-submenu, .pcoded[layout-type=dark] .success-nav .navbar-varient-submenu, .pcoded[layout-type=dark] .warning-nav .navbar-varient-submenu {
  background-color: #3e455d;
  color: #fff;
}

body.dark .danger-nav .navbar-varient-submenu, body.dark .info-nav .navbar-varient-submenu, body.dark .light-nav-border .navbar-varient-submenu, body.dark .primary-nav .navbar-varient-submenu, body.dark .warning-nav .navbar-varient-submenu, body.dark .success-nav .navbar-varient-submenu, body.dark .info-nav .navbar-varient-submenu, body.dark .danger-nav .navbar-varient-submenu, body.dark .primary-nav .navbar-varient-submenu, body.dark .success-nav .navbar-varient-submenu, body.dark .warning-nav .navbar-varient-submenu {
  background-color: #3e455d;
  color: #fff;
}

.pcoded[layout-type=dark] .danger-nav .navbar-varient-submenu a:hover, .pcoded[layout-type=dark] .info-nav .navbar-varient-submenu a:hover, .pcoded[layout-type=dark] .light-nav-border .navbar-varient-submenu a:hover, .pcoded[layout-type=dark] .primary-nav .navbar-varient-submenu a:hover, .pcoded[layout-type=dark] .warning-nav .navbar-varient-submenu a:hover, .pcoded[layout-type=dark] .success-nav .navbar-varient-submenu a:hover, .pcoded[layout-type=dark] .info-nav .navbar-varient-submenu a:hover, .pcoded[layout-type=dark] .danger-nav .navbar-varient-submenu a:hover, .pcoded[layout-type=dark] .primary-nav .navbar-varient-submenu a:hover, .pcoded[layout-type=dark] .success-nav .navbar-varient-submenu a:hover, .pcoded[layout-type=dark] .warning-nav .navbar-varient-submenu a:hover {
  background-color: #444c67;
}

body.dark .danger-nav .navbar-varient-submenu a:hover, body.dark .info-nav .navbar-varient-submenu a:hover, body.dark .light-nav-border .navbar-varient-submenu a:hover, body.dark .primary-nav .navbar-varient-submenu a:hover, body.dark .warning-nav .navbar-varient-submenu a:hover, body.dark .success-nav .navbar-varient-submenu a:hover, body.dark .info-nav .navbar-varient-submenu a:hover, body.dark .danger-nav .navbar-varient-submenu a:hover, body.dark .primary-nav .navbar-varient-submenu a:hover, body.dark .success-nav .navbar-varient-submenu a:hover, body.dark .warning-nav .navbar-varient-submenu a:hover {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .card.version .card-header .card-header-right, body.dark .card.version .card-header .card-header-right {
  background-color: #3a4057;
  color: #fff;
}

.pcoded[layout-type=dark] .version .nav li:first-child, body.dark .version .nav li:first-child {
  color: #fff;
}

.pcoded[layout-type=dark] .version .nav li:hover, body.dark .version .nav li:hover {
  background-color: #444c67;
}

.pcoded[layout-type=dark] .version .nav li a, body.dark .version .nav li a {
  color: #fff;
}

.pcoded[layout-type=dark] .cd-timeline-content .bg-white, body.dark .cd-timeline-content .bg-white {
  background-color: #fff;
}

.pcoded[layout-type=dark] .cd-timeline-content p, body.dark .cd-timeline-content p, .pcoded[layout-type=dark] #styleSelector, body.dark #styleSelector, .pcoded[layout-type=dark] #styleSelector .form-control, body.dark #styleSelector .form-control {
  color: #303548;
}

/* dark layout css end*/
.theme-loader {
  height: 100%;
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 999999;
  top: 0;
}

.ball-scale {
  left: 50%;
  top: 50%;
  position: absolute;
  height: 50px;
  width: 75px;
  margin: -25px 0 0 -37.5px;
}
.ball-scale .contain > div {
  width: 3px;
  height: 50px;
  border-radius: 5px;
  margin: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  -webkit-animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  width: 3px;
  height: 50px;
  border-radius: 5px;
  margin: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  vertical-align: middle;
  -webkit-animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);
  animation: line-scale-pulse-out-rapid 0.9s -0.5s infinite cubic-bezier(0.11, 0.49, 0.38, 0.78);
}
.ball-scale .contain > div:nth-child(4), .ball-scale .contain > div:nth-child(6) {
  -webkit-animation-delay: -0.4s !important;
  animation-delay: -0.4s !important;
}
.ball-scale .contain > div:nth-child(3), .ball-scale .contain > div:nth-child(7) {
  -webkit-animation-delay: -0.3s !important;
  animation-delay: -0.3s !important;
}
.ball-scale .contain > div:nth-child(2), .ball-scale .contain > div:nth-child(8) {
  -webkit-animation-delay: -0.2s !important;
  animation-delay: -0.2s !important;
}
.ball-scale .contain > div:nth-child(1), .ball-scale .contain > div:nth-child(9) {
  -webkit-animation-delay: -0.1s !important;
  animation-delay: -0.1s !important;
}
.ball-scale .contain > div:nth-child(4), .ball-scale .contain > div:nth-child(6) {
  -webkit-animation-delay: -0.3s !important;
  animation-delay: -0.3s !important;
}
.ball-scale .contain > div:nth-child(3), .ball-scale .contain > div:nth-child(7) {
  -webkit-animation-delay: -0.2s !important;
  animation-delay: -0.2s !important;
}
.ball-scale .contain > div:nth-child(2), .ball-scale .contain > div:nth-child(8) {
  -webkit-animation-delay: -0.1s !important;
  animation-delay: -0.1s !important;
}
.ball-scale .contain > div:nth-child(1), .ball-scale .contain > div:nth-child(9) {
  -webkit-animation-delay: 0s !important;
  animation-delay: 0s !important;
}
.ball-scale .contain > div:nth-child(1) {
  background-color: #93BE52;
}
.ball-scale .contain > div:nth-child(2) {
  background-color: #FE8A7D;
}
.ball-scale .contain > div:nth-child(3) {
  background-color: #FFB64D;
}
.ball-scale .contain > div:nth-child(4) {
  background-color: #FC6180;
}
.ball-scale .contain > div:nth-child(5) {
  background-color: #4680ff;
}
.ball-scale .contain > div:nth-child(6) {
  background-color: #FC6180;
}
.ball-scale .contain > div:nth-child(7) {
  background-color: #FFB64D;
}
.ball-scale .contain > div:nth-child(8) {
  background-color: #FE8A7D;
}
.ball-scale .contain > div:nth-child(9) {
  background-color: #93BE52;
}

@-webkit-keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}
@keyframes line-scale-pulse-out {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}
@-webkit-keyframes line-scale-pulse-out-rapid {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  80% {
    -webkit-transform: scaley(0.3);
    transform: scaley(0.3);
  }
  90% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}
@keyframes line-scale-pulse-out-rapid {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  80% {
    -webkit-transform: scaley(0.3);
    transform: scaley(0.3);
  }
  90% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}
/* st5 icon */
.pcoded[nav-type=st5] .pcoded-item.pcoded-left-item > li > a > .pcoded-micon b {
  display: none;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(1) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(1) > li > a > .pcoded-micon i {
  color: #4680ff;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(2) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(2) > li > a > .pcoded-micon i {
  color: #FC6180;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(3) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(3) > li > a > .pcoded-micon i {
  color: #93BE52;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(4) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(4) > li > a > .pcoded-micon i {
  color: #FFB64D;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(5) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(5) > li > a > .pcoded-micon i {
  color: #ab7967;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(6) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(6) > li > a > .pcoded-micon i {
  color: #39ADB5;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(7) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(7) > li > a > .pcoded-micon i {
  color: #7C4DFF;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(8) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(8) > li > a > .pcoded-micon i {
  color: #FF5370;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(9) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(9) > li > a > .pcoded-micon i {
  color: #4680ff;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(10) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(10) > li > a > .pcoded-micon i {
  color: #FC6180;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(11) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(11) > li > a > .pcoded-micon i {
  color: #93BE52;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(12) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(12) > li > a > .pcoded-micon i {
  color: #FFB64D;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(13) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(13) > li > a > .pcoded-micon i {
  color: #ab7967;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(14) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(14) > li > a > .pcoded-micon i {
  color: #39ADB5;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(15) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(15) > li > a > .pcoded-micon i {
  color: #7C4DFF;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(16) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(16) > li > a > .pcoded-micon i {
  color: #FF5370;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(17) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(17) > li > a > .pcoded-micon i {
  color: #4680ff;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(18) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(18) > li > a > .pcoded-micon i {
  color: #FC6180;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(19) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(19) > li > a > .pcoded-micon i {
  color: #93BE52;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(20) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(20) > li > a > .pcoded-micon i {
  color: #FFB64D;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(21) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(21) > li > a > .pcoded-micon i {
  color: #ab7967;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(22) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(22) > li > a > .pcoded-micon i {
  color: #39ADB5;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(23) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(23) > li > a > .pcoded-micon i {
  color: #7C4DFF;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(24) > li > a > .pcoded-micon {
  background-color: transparent;
}
.pcoded[nav-type=st5] .d-color > ul:nth-child(24) > li > a > .pcoded-micon i {
  color: #FF5370;
}

@media only screen and (max-width: 575px) {
  #monthly-profit-1 + svg, #monthly-profit-2 + svg, #monthly-profit-3 + svg {
    display: none;
  }

  .social-network .card-body, .online-form {
    text-align: center;
  }

  .breadcrumb-title {
    margin-bottom: 1rem;
  }

  .datatable-footer-inner {
    height: auto !important;
    display: block !important;
  }
}
/* by amit */
#real-time-update {
  width: 100% !important;
}
#real-time-update canvas {
  width: 100% !important;
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-control:lang(en)::before {
  content: "Browse";
}

.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-file-control:lang(en):empty::after {
  content: "Choose file...";
}

/* Modal */
.basic.modal {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.basic.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.basic.fade.in {
  opacity: 1;
}

.basic.modal.fade.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.basic.modal.fade .modal-dialog {
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.basic .basic-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  color: #292b2c;
}

.basic .basic-close-light-box {
  position: absolute;
  top: -31px;
  right: -20px;
  font-size: 30px;
  color: white;
  text-shadow: none;
}

.basic h5 {
  margin-bottom: 0;
  color: #757575;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}

/* Spinner */
.http-loader {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
}

.loader-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  filter: alpha(opacity=70);
  opacity: 0.7;
  background-color: #f1f1f1;
}

.colored-parent, .colored > div {
  background-color: #333;
}

/* Importing Bootstrap SCSS file. */
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/* Importing DataTable. */
.ngx-datable-columns-center {
  text-align: center;
}

/*
  This stylesheet uses scss valiables for most of the colors / background-colors of the table
  to enable the customization of the displayed table without cloning the stylesheet into the
  own application.

  To modify table colors, add the following lines to the scss file of your application
  (this example modifies the color of the selected row - selectionType = single, multi or multiClick):

  $ngx-datatable-selected-active-background: yellow;
  $ngx-datatable-selected-active-background-hover: rgba(yellow, 0.2);

  @import '~@swimlane/ngx-datatable/index.css';
  @import '~@swimlane/ngx-datatable/themes/material.scss';
  @import '~@swimlane/ngx-datatable/assets/icons.css';

That's all.
*/
.ngx-datatable.material {
  background: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  /**
  * Shared Styles
  */
  /**
  * Global Row Styles
  */
  /**
  * Header Styles
  */
  /**
  * Body Styles
  */
  /**
  * Footer Styles
  */
}
.ngx-datatable.material.striped .datatable-row-odd {
  background: #eee;
}
.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover, .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:focus, .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:hover, .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:focus, .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active, .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #fff;
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
  background-position: 0 0;
  background-color: #fff;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  font-weight: 400;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.54);
  vertical-align: bottom;
  font-size: 12px;
  font-weight: 500;
}
.ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.ngx-datatable.material .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px #eee;
}
.ngx-datatable.material .datatable-body {
  position: relative;
}
.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.material .datatable-body .datatable-group-header {
  background: #f5f5f5;
  border-bottom: solid 1px #d9d8d9;
  border-top: solid 1px #d9d8d9;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .progress-linear {
  display: block;
  position: sticky;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  top: 0;
}
.ngx-datatable.material .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}
.ngx-datatable.material .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}
.ngx-datatable.material .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}
.ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}
.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2);
}
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}
.datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.datatable-checkbox input[type=checkbox]:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.datatable-checkbox input[type=checkbox]:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.datatable-checkbox input[type=checkbox]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: #fff;
  cursor: pointer;
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
.ngx-datatable.dark {
  box-shadow: none;
  background: #1b1e27;
  border: 1px solid #2f3646;
  color: #fff;
  font-size: 13px;
}
.ngx-datatable.dark .datatable-header {
  background: #181b24;
  color: #72809b;
}
.ngx-datatable.dark .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.5rem 1.2rem;
  font-weight: bold;
}
.ngx-datatable.dark .datatable-header .datatable-header-cell .datatable-header-cell-label {
  line-height: 24px;
}
.ngx-datatable.dark .datatable-body {
  background: #1a1e28;
}
.ngx-datatable.dark .datatable-body .datatable-body-row {
  border-top: 1px solid #2f3646;
}
.ngx-datatable.dark .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
}
.ngx-datatable.dark .datatable-body .datatable-body-row:hover {
  background: #171b24;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.dark .datatable-body .datatable-body-row:focus {
  background-color: #232837;
}
.ngx-datatable.dark .datatable-body .datatable-body-row.active {
  background-color: #1483ff;
  color: #fff;
}
.ngx-datatable.dark .datatable-footer {
  background: #232837;
  color: #72809b;
  margin-top: -1px;
}
.ngx-datatable.dark .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.dark .datatable-footer .datatable-pager {
  margin: 0 10px;
  vertical-align: top;
}
.ngx-datatable.dark .datatable-footer .datatable-pager ul li {
  margin: 10px 0px;
}
.ngx-datatable.dark .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.dark .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #455066;
  font-weight: bold;
}
.ngx-datatable.dark .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0;
  border-radius: 3px;
  margin: 0 3px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  vertical-align: bottom;
  color: #72809b;
}
.ngx-datatable.dark .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.dark .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.dark .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.dark .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 18px;
  line-height: 27px;
  padding: 0 3px;
}
.ngx-datatable.dark .datatable-summary-row .datatable-body-row {
  background-color: #14171f;
}
.ngx-datatable.dark .datatable-summary-row .datatable-body-row:hover {
  background-color: #14171f;
}
.ngx-datatable.dark .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

/*
bootstrap table theme
*/
.ngx-datatable.bootstrap {
  box-shadow: none;
  font-size: 13px;
}
.ngx-datatable.bootstrap .datatable-header {
  height: unset !important;
}
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  vertical-align: bottom;
  padding: 0.75rem;
  border-bottom: 1px solid #d1d4d7;
}
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
  line-height: 24px;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  vertical-align: top;
  border-top: 1px solid #d1d4d7;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: rgba(0, 0, 0, 0.05);
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: #1483ff;
  color: #fff;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.75rem;
  text-align: left;
  vertical-align: top;
}
.ngx-datatable.bootstrap .datatable-body .empty-row {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
}
.ngx-datatable.bootstrap .datatable-footer {
  background: #424242;
  color: #ededed;
  margin-top: -1px;
}
.ngx-datatable.bootstrap .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
  margin: 0 10px;
  vertical-align: top;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
  margin: 10px 0px;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #545454;
  font-weight: bold;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0;
  border-radius: 3px;
  margin: 0 3px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  vertical-align: bottom;
  color: #ededed;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 18px;
  line-height: 27px;
  padding: 0 3px;
}
.ngx-datatable.bootstrap .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}